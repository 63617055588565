import React from 'react';

type BitButtonProps = {
	text: string;
	textColor: string;
	onClick?: any;
	type?: string;
	buttonColor?: string;
	classesBlockOption?: string;
};

const BitButton: React.FC<BitButtonProps> = ({
	text,
	textColor,
	onClick,
	type,
	buttonColor,
	classesBlockOption,
}) => {
	const defaultText = 'Texto Aqui!';
	const defaultTextColor = 'rgba(255, 255, 255, var(--text-opacity))';
	const defaultButtonColor = '#91b820';
	const defaultClassesBlockOption = 'field';

	return (
		<div className={classesBlockOption ?? defaultClassesBlockOption}>
			{onClick ? (
				<button
					type={'button'}
					className={type ? `button button--${type}` : 'button'}
					style={{
						color: textColor ?? defaultTextColor,
						backgroundColor: buttonColor ?? defaultButtonColor,
					}}
					onClick={onClick}
				>
					{text ?? defaultText}
				</button>
			) : (
				<button
					type={'button'}
					className={type ? `button button--${type}` : 'button'}
					style={{
						color: textColor ?? defaultTextColor,
						backgroundColor: buttonColor ?? defaultButtonColor,
					}}
				>
					{text ?? defaultText}
				</button>
			)}
		</div>
	);
};

export default BitButton;
