import { randomBytes } from 'crypto';
import React from 'react';

type BitTableProps = {
	tableData: {
		headers: string[];
		data: any[];
	};
	noneMessage: string;
};

const BitTable: React.FC<BitTableProps> = ({ tableData, noneMessage }) => {
	return (
		<div className="w-full table-container tabela-customizada-container">
			<table className="table tabela-customizada">
				<thead>
					<tr>
						{tableData?.headers?.map(header => {
							return (
								<th key={header + randomBytes(2)}>{header}</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{tableData && tableData?.data.length > 0 ? (
						tableData?.data?.map(row => {
							const actualRow: any[] = [];

							Object.keys(row).forEach(rowItem => {
								actualRow.push(row[rowItem]);
							});

							return (
								<tr key={row + randomBytes(2)}>
									{actualRow?.map(item => {
										return (
											<td key={item + randomBytes(2)}>
												{item}
											</td>
										);
									})}
								</tr>
							);
						})
					) : (
						<tr>
							<td
								colSpan={tableData?.headers.length}
								className="text-center"
							>
								{noneMessage}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default BitTable;
