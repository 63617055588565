import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';
// eslint-disable-next-line import/extensions
import cogoToast from '../NewRegisters/components/cogo-toast';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const UpdatePublicacao: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	interface interfaceCheckbox {
		value: {
			[key: string]: {
				value: string | number;
				label: string;
				name: string;
			};
		};
		required: boolean;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdatePublicacao = `${urlEndpoint}/publications/update/${idRegister}`;
	const urlEndpointGetPublicacao = `${urlEndpoint}/publications/${idRegister}`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;
	const urlEndpointGetCategories = `${urlEndpoint}/categories`;

	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');

	const [carrossel, setCarrossel] = useState([]);
	const [carousselIncludeImages, setCarrosselIncludeImage] = useState([]);
	const [carrousselRemoveImages, setCarousselRemoveImage] = useState<any>([]);

	const [titulo, setTitulo] = useState({
		value: '',
		required: true,
	});
	const [descricao, setDescricao] = useState({
		value: '',
		required: true,
	});
	const [linkYoutube, setLinkYoutube] = useState({
		value: '',
		required: false,
	});
	const [dataPublicacao, setDataPublicacao] = useState({
		value: '',
		required: false,
	});
	const [horario, setHorario] = useState({
		value: '',
		required: false,
	});

	const [active, setActive] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});
	const [arquivos1, setArquivos1] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});
	const [arquivos2, setArquivos2] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});
	const [arquivos3, setArquivos3] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});
	const [arquivos4, setArquivos4] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});
	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});
	const [categorias, setCategorias] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});
	const [funcionalidades, setFuncionalidades] = useState<interfaceCheckbox>({
		value: {
			home: {
				label: 'FIXAR NA HOME.',
				value: 0,
				name: 'home',
			},
			push: {
				label: 'PUSH',
				value: 0,
				name: 'push',
			},
			top: {
				label: 'FIXAR NO TOPO',
				value: 0,
				name: 'fixed_on_top',
			},
		},
		required: false,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetPublicacao, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (responsePublicacao: typeResponseAxios) {
				setCarrossel(responsePublicacao.data.data.medias);
				setTitulo({
					value: responsePublicacao.data.data.title,
					required: titulo.required,
				});
				setDescricao({
					value: responsePublicacao.data.data.body,
					required: descricao.required,
				});
				setLinkYoutube({
					value: responsePublicacao.data.data.youtube_link,
					required: linkYoutube.required,
				});
				setDataPublicacao({
					value: responsePublicacao.data.data.publication_date,
					required: dataPublicacao.required,
				});
				setHorario({
					value: responsePublicacao.data.data.publication_hour,
					required: horario.required,
				});
				setArquivos1({
					value: responsePublicacao.data.data.photo_1,
					required: arquivos1.required,
				});
				setArquivos2({
					value: responsePublicacao.data.data.photo_2,
					required: arquivos2.required,
				});
				setArquivos3({
					value: responsePublicacao.data.data.photo_3,
					required: arquivos3.required,
				});
				setArquivos4({
					value: responsePublicacao.data.data.pdf,
					required: arquivos4.required,
				});
				setFuncionalidades({
					value: {
						home: {
							label: 'FIXAR NA HOME.',
							value: responsePublicacao.data.data.home,
							name: 'home',
						},
						push: {
							label: 'PUSH',
							value: responsePublicacao.data.data.push,
							name: 'push',
						},
						top: {
							label: 'FIXAR NO TOPO',
							value: responsePublicacao.data.data.fixed_on_top,
							name: 'top',
						},
					},
					required: funcionalidades.required,
				});

				if (responsePublicacao.data.data.active === 1) {
					const activeOptions = [];

					activeOptions.push({
						value: 1,
						label: 'Ativo',
						selected: true,
					});
					activeOptions.push({
						value: 0,
						label: 'Inativo',
						selected: false,
					});

					setActive({
						value: activeOptions,
						required: true,
					});
				}

				if (responsePublicacao.data.data.active === 0) {
					const activeOptions = [];

					activeOptions.push({
						value: 1,
						label: 'Ativo',
						selected: false,
					});
					activeOptions.push({
						value: 0,
						label: 'Inativo',
						selected: true,
					});

					setActive({
						value: activeOptions,
						required: true,
					});
				}

				const selectedCategories =
					responsePublicacao.data.data.categories;
				const selectedEmpreendimentos =
					responsePublicacao.data.data.developments;
				console.log(selectedEmpreendimentos);
				axios
					.get(urlEndpointGetEmpreendimentos, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then(function (response: typeResponseAxios) {
						const allEmpreendimentos = Array.isArray(
							response.data.data.data,
						)
							? response.data.data.data
							: [response.data.data.data];

						const newValueEmpreendimentos: {
							value: string | number;
							label: string;
							selected: boolean;
						}[] = [];

						if (typeUser === 'Porteiro') {
							newValueEmpreendimentos.push({
								value: selectedEmpreendimentos[0].id,
								label: allEmpreendimentos[0].name,
								selected: true,
							});
						} else {
							allEmpreendimentos.map((empreendimento: any) => {
								let isSelected = false;

								selectedEmpreendimentos.map(
									(selectedEmpreendimento: any) => {
										if (!isSelected) {
											isSelected =
												selectedEmpreendimento.id ===
												empreendimento.id;
										}
										return null;
									},
								);

								newValueEmpreendimentos.push({
									value: empreendimento.id,
									label: empreendimento.name,
									selected: isSelected,
								});
								return null;
							});
						}

						setEmpreendimentos({
							value: newValueEmpreendimentos,
							required: empreendimentos.required,
						});
					});

				axios
					.get(urlEndpointGetCategories, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then(function (response: typeResponseAxios) {
						const allCategorias = response.data.data.data;

						const newValueCategorias: {
							value: string | number;
							label: string;
							selected: boolean;
						}[] = [];

						allCategorias.map((empreendimento: any) => {
							let isSelected = false;

							selectedCategories.map(
								(selectedEmpreendimento: any) => {
									if (!isSelected) {
										isSelected =
											selectedEmpreendimento.id ===
											empreendimento.id;
									}
									return null;
								},
							);

							newValueCategorias.push({
								value: empreendimento.id,
								label: empreendimento.title,
								selected: isSelected,
							});
							return null;
						});

						setCategorias({
							value: newValueCategorias,
							required: categorias.required,
						});
					});
			});
	}, []);

	let cogo: any = null;

	const uploadNewImagesCarroussel = async (): Promise<any[]> => {
		const imageUploaded = [];
		try {
			// eslint-disable-next-line
			for (const [idx, image] of carousselIncludeImages.entries()) {
				if (cogo) {
					(cogo as any).hide();
				}
				cogo = cogoToast.loading(
					`Enviando imagem ${idx + 1} de ${
						carousselIncludeImages.length
					}`,
				);
				const formData = new FormData();

				formData.append('image', image);
				formData.append('order', `${idx}`);

				// eslint-disable-next-line no-await-in-loop
				const { data } = await axios.post(
					`${urlEndpoint}/publications/media/store`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${token}`,
							// 'Content-Type': 'application/json',
							'Content-type': 'multipart/form-data',
						},
					},
				);

				imageUploaded.push({ id: data.data.id });
			}

			return imageUploaded;
		} catch (error) {
			toast.error(
				`Tivemos um erro ao tentar enviar as imagens. \n Error: ${error}`,
				{
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				},
			);

			unlockFields(currentFields);
			throw error;
		}
	};

	const deleteImagesCarroussel = async () => {
		try {
			await Promise.all(
				carrousselRemoveImages
					.filter((img: any) => !!img?.id)
					.map((image: any) =>
						axios.delete(
							`${urlEndpoint}/publications/media/delete/${image.id}`,
							{
								headers: {
									Authorization: `Bearer ${token}`,
									// 'Content-Type': 'application/json',
									'Content-type': 'multipart/form-data',
								},
							},
						),
					),
			);
		} catch (error) {
			toast.error(
				`Tivemos um erro ao tentar remover as imagens. \n Error: ${error}`,
				{
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				},
			);
		}
	};

	const handleCadastrar = async (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		lockFields(currentFields);

		try {
			const entriesDevelopments = empreendimentos.value;
			const valueDevelopments: {
				[key: string]: string | number;
			}[] = [];

			entriesDevelopments.map(empreendimento => {
				if (empreendimento.selected) {
					valueDevelopments.push({
						id: empreendimento.value,
					});
				}

				return null;
			});

			const entriesCategorias = categorias.value;
			const valueCategorias: {
				[key: string]: string | number;
			}[] = [];

			entriesCategorias.map(categoria => {
				if (categoria.selected) {
					valueCategorias.push({
						id: categoria.value,
					});
				}

				return null;
			});

			const entriesActive = active.value;

			let activeValue: any = 0;

			entriesActive.map(act => {
				if (act.selected) {
					activeValue = act.value;
				}
				return null;
			});
			let youtubeLinkHandle = linkYoutube.value || '';

			if (linkYoutube.value) {
				if (youtubeLinkHandle?.includes('shorts')) {
					// eslint-disable-next-line prefer-destructuring
					youtubeLinkHandle = youtubeLinkHandle.split('?')[0];

					const splitedShortLink = youtubeLinkHandle.split('shorts/');

					youtubeLinkHandle =
						splitedShortLink[splitedShortLink.length - 1];

					youtubeLinkHandle = `https://youtu.be/${youtubeLinkHandle}`;
				}
			}

			const upImages = carrossel
				.filter(filterNonRemovedImages)
				.map((item: any) => ({
					id: item.id,
				}));

			const uploadedImages = await uploadNewImagesCarroussel();

			upImages.push(...uploadedImages);

			await deleteImagesCarroussel();

			if (!upImages.length) {
				unlockFields(currentFields);
				toast.error(
					`A publicação precisa ter ao menos uma imagem.`,
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);
				return;
			}

			cogo = cogoToast.loading('Criando sua publicação, aguarde...');

			await axios.put(
				urlEndpointUpdatePublicacao,
				{
					title: titulo.value,
					active: activeValue,
					body: descricao.value,
					youtube_link: youtubeLinkHandle,
					home: funcionalidades.value.home.value === 1,
					push: funcionalidades.value.push.value === 1,
					fixed_on_top: funcionalidades.value.top.value === 1,
					publication_date: dataPublicacao.value,
					publication_hour: horario.value,
					categories: valueCategorias,
					developments: valueDevelopments,
					upImages,
					// photo_1: arquivos1.value,
					// photo_2: arquivos2.value,
					// photo_3: arquivos3.value,
					pdf: arquivos4.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			);

			toast.success('Cadastro atualizado com sucesso!', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});

			unlockFields(currentFields);
		} catch (error: any) {
			toast.error(
				`Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.  Error: ${
					error?.response?.data?.message || error
				}`,
				{
					position: 'bottom-right',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				},
			);

			unlockFields(currentFields);
		} finally {
			(cogo as any).hide();
		}
	};

	const filterNonRemovedImages = (imgItem: any) =>
		!carrousselRemoveImages.some(
			(imgItemRemove: any) =>
				imgItem === imgItemRemove || imgItemRemove.id === imgItem.id,
		);

	const handlerRemoveImage = (image: any) => () => {
		const array = [...carrousselRemoveImages, image];
		setCarousselRemoveImage(array);

		setCarrosselIncludeImage(
			carousselIncludeImages.filter(
				(imageItem: any) => imageItem !== image,
			),
		);
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Publicação`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR PUBLICAÇÃO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DA PUBLICAÇÃO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-1 gap-4 mb-4">
						<BitInput
							label="Título"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTitulo({
									value: e.currentTarget.value,
									required: titulo.required,
								})
							}
							value={titulo.value}
							required={titulo.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Categoria"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={categorias.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueCategorias: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueCategorias.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setCategorias({
									value: newValueCategorias,
									required: categorias.required,
								});
							}}
						/>
					</div>
					<div className="mb-10">
						<BitInput
							label="Post Ativo?"
							type="select"
							multipleSelect={false}
							placeholder=""
							valuesSelect={active.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueActive: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueActive.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setActive({
									value: newValueActive,
									required: active.required,
								});
							}}
						/>
					</div>
					<div className="mb-10">
						<BitInput
							label="Conteúdo"
							type="textarea"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDescricao({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={descricao.value}
							required={descricao.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					EMPREENDIMENTOS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label="Nome do empreendimento"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={empreendimentos.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueEmpreendimentos: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueEmpreendimentos.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setEmpreendimentos({
									value: newValueEmpreendimentos,
									required: empreendimentos.required,
								});
							}}
						/>
					</div>
				</div>

				<div className="mb-10" />
				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">3</span>
					Carrossel
				</h4>
				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							multipleSelect
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivo1 = document.getElementById(
									'arquivo1',
								);
								if (inputArquivo1 !== null) {
									inputArquivo1.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget
									.files as any;
								if (currentFile !== null) {
									setCarrosselIncludeImage([
										...[...currentFile].reverse(),
										...carousselIncludeImages,
									] as any);
								}
							}}
							imgPreview={''}
							required={arquivos1.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivo1'}
						/>
						{[...carousselIncludeImages, ...carrossel]
							?.filter(filterNonRemovedImages)
							.map((imgItem: any) => (
								<div
									key={imgItem?.id || Math.random()}
									style={{ position: 'relative' }}
								>
									<img
										className="img-preview-thumbnail img-preview-300px"
										src={
											imgItem.media ||
											URL.createObjectURL(imgItem)
										}
										alt=""
										style={{
											maxWidth: '500px',
											padding: '5px',
										}}
									/>
									<div
										style={{
											zIndex: 10,
											position: 'absolute',
											top: '10px',
											left: '10px',
										}}
									>
										<BitButton
											text={'Remover'}
											buttonColor={'#FF0000'}
											textColor={'#FFFFFF'}
											type={'fit px-10'}
											classesBlockOption={'inline-block'}
											onClick={handlerRemoveImage(
												imgItem,
											)}
										/>
									</div>
								</div>
							))}
					</div>
				</div>
				{/* <h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">3</span>
					IMAGEM 1
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivo1 = document.getElementById(
									'arquivo1',
								);
								if (inputArquivo1 !== null) {
									inputArquivo1.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivo1`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos1({
												value: e.target.result,
												required: arquivos1.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos1.value}
							required={arquivos1.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivo1'}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">4</span>
					IMAGEM 2
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivo2 = document.getElementById(
									'arquivo2',
								);
								if (inputArquivo2 !== null) {
									inputArquivo2.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivo2`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos2({
												value: e.target.result,
												required: arquivos2.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos2.value}
							required={arquivos2.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivo2'}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">5</span>
					IMAGEM 3
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivo3 = document.getElementById(
									'arquivo3',
								);
								if (inputArquivo3 !== null) {
									inputArquivo3.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivo3`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos3({
												value: e.target.result,
												required: arquivos3.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos3.value}
							required={arquivos3.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivo3'}
						/>
					</div>
				</div> */}

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">6</span>
					ARQUIVO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label=""
							type="file"
							isPdf={true}
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivo4 = document.getElementById(
									'arquivo4',
								);
								if (inputArquivo4 !== null) {
									inputArquivo4.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivo4`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos4({
												value: e.target.result,
												required: arquivos4.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos4.value}
							required={arquivos4.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivo4'}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">7</span>
					INFORMAÇÕES EXTRAS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label="Link do youtube"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setLinkYoutube({
									value: e.currentTarget.value,
									required: linkYoutube.required,
								})
							}
							value={linkYoutube.value}
							required={linkYoutube.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="mb-4">
						<BitInput
							label=""
							type="checkbox"
							placeholder="Digite..."
							classField=""
							valuesCheckbox={funcionalidades.value}
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const newValueCheckbox = e.currentTarget.checked
									? 1
									: 0;
								const newValueFuncionalidades = funcionalidades;

								newValueFuncionalidades.value[
									e.currentTarget.name
								].value = newValueCheckbox;

								setFuncionalidades(newValueFuncionalidades);
								setTitulo({
									value: titulo.value,
									required: titulo.required,
								});
							}}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">8</span>
					PROGRAMAR PUBLICAÇÃO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Data da Publicação"
							type="date"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDataPublicacao({
									value: e.currentTarget.value,
									required: dataPublicacao.required,
								})
							}
							value={dataPublicacao.value}
							required={dataPublicacao.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Horário"
							type="maskedinput"
							mask={[
								/\d/,
								/\d/,
								':',
								/\d/,
								/\d/,
								':',
								/\d/,
								/\d/,
							]}
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setHorario({
									value: e.currentTarget.value,
									required: horario.required,
								})
							}
							value={horario.value}
							required={horario.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/publicacoes'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>

				<div className="mb-10" />
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdatePublicacao;
