import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	accessPagesState,
	endpointState,
	routesAccessPagesState,
} from '../../recoil/atoms';

type BitProtectedRouteProps = {
	path: string;
	component: any;
};

const BitProtectedRoute: React.FC<BitProtectedRouteProps> = ({
	path,
	component,
}) => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	const [waitAxios, setWaitAxios] = useState(true);

	const pathName = useLocation().pathname;

	const token = localStorage.getItem('token');
	const userId = localStorage.getItem('user_id');
	const typeUser = localStorage.getItem('type');

	const { permissions } = useRecoilValue(accessPagesState);

	const { permissionsRoutes } = useRecoilValue(routesAccessPagesState);

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointGetProfile = `${urlEndpoint}/profile`;

	const [accessPages, setAccessPages] = useRecoilState(accessPagesState);

	let youCanPass = false;

	if (pathName.indexOf('/dashboard') >= 0 && (typeUser === 'Administrador' || typeUser === 'Gestor')) {
		youCanPass = true;
	}

	if (pathName.indexOf('/dashboard-lojista') >= 0 && typeUser === 'Lojista') {
		youCanPass = true;
	}

	if (pathName.indexOf('/chat') >= 0 && typeUser === 'Lojista') {
		youCanPass = true;
	}

	if (pathName.indexOf('/import') >= 0 && (typeUser === 'Administrador'|| typeUser === 'Gestor')) {
		youCanPass = true;
	}

	if (
		pathName.indexOf('/export') >= 0 &&
		(typeUser === 'Administrador' || typeUser === 'Gestor')
	) {
		youCanPass = true;
	}

	if (pathName.indexOf('/fcg') >= 0 && typeUser === 'Gestor') {
		youCanPass = true;
	}

	if (pathName.indexOf('/packages') >= 0 && typeUser === 'Porteiro') {
		youCanPass = true;
	}

	if (pathName.indexOf('/account/profile') >= 0) {
		youCanPass = true;
	}

	if (token !== null && token !== undefined && token !== '') {
		Object.entries(permissions).forEach(([key, value]) => {
			if (key === 'users') {
				Object.entries(value).forEach(([keyUsers, valueUsers]) => {
					if (Array.isArray(valueUsers) && valueUsers.length > 0) {
						if (permissionsRoutes[key][keyUsers] !== undefined) {
							valueUsers.forEach(valueUsersPerm => {
								if (
									permissionsRoutes[key][keyUsers][
										valueUsersPerm
									] !== undefined
								) {
									permissionsRoutes[key][keyUsers][
										valueUsersPerm
									].forEach((valueRoute: string) => {
										if (pathName.indexOf(valueRoute) >= 0) {
											youCanPass = true;
										}
									});
								}
							});
						}
					}
				});
			} else if (
				Object.keys(value).length > 0 ||
				(Array.isArray(value) && value.length > 0)
			) {
				if (permissionsRoutes[key] !== undefined) {
					value.forEach((valuePerm: string) => {
						if (permissionsRoutes[key][valuePerm] !== undefined) {
							permissionsRoutes[key][valuePerm].forEach(
								(valueRoute: string) => {
									if (pathName.indexOf(valueRoute) >= 0) {
										youCanPass = true;
									}
								},
							);
						}
					});
				}
			}
		});
	} else {
		youCanPass = false;
	}

	useEffect(() => {
		if (token !== null && token !== undefined && token !== '') {
			axios
				.get(urlEndpointGetProfile, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(function (response: typeResponseAxios) {
					if (
						response.data.data.permissions !== '' &&
						response.data.data.permissions !== undefined
					) {
						setAccessPages({
							permissions: response.data.data.permissions,
						});
					}
					setWaitAxios(false);
				})
				.catch(function (error) {
					console.log(error);
					setWaitAxios(false);
				});
		} else {
			setWaitAxios(false);
		}
	}, []);

	if (waitAxios) {
		return null;
	}

	return (
		<Route
			path={path}
			component={youCanPass ? component : undefined}
			render={props =>
				youCanPass ? undefined : <Redirect to="/login" />
			}
		/>
	);
};

export default BitProtectedRoute;
