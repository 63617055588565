import React from 'react';

type BitHeaderFullProps = {
	label: string;
	icon: string;
	hasIcon?: boolean;
};

const BitHeaderFull: React.FC<BitHeaderFullProps> = ({
	label,
	icon,
	hasIcon = true,
	children,
}) => {
	type typeIconEl = JSX.Element | null;

	let iconEl: typeIconEl = null;

	const defaultIcon = 'font-semibold uppercase text-sm fas fa-home';

	if (hasIcon) {
		iconEl = (
			<i
				className={
					icon
						? `font-semibold uppercase text-sm pr-2 ${icon}`
						: defaultIcon
				}
			/>
		);
	}

	return (
		<header className="p-5 mb-8 bg-white rounded flex flex-row items-center">
			<div className="flex-1">
				{iconEl}
				<span className="font-semibold text-lg">
					{label ?? 'Sua Label'}
				</span>
			</div>
			<div className="flex-1">{children}</div>
		</header>
	);
};

export default BitHeaderFull;
