import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

const UpdateConcierge: React.FC = () => {
	Moment.locale('pt-br');

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdateUser = `${urlEndpoint}/users/update/${idRegister}`;
	const urlEndpointGetUser = `${urlEndpoint}/users/${idRegister}`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;

	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');

	const [maskTelephone, setmaskTelephone] = useState([
		'(',
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	]);

	const [nome, setNome] = useState({
		value: '',
		required: true,
	});
	const [cpf, setCpf] = useState({
		value: '',
		required: false,
	});
	const [email, setEmail] = useState({
		value: '',
		required: true,
	});
	const [telefone, setTelefone] = useState({
		value: '',
		required: true,
	});
	const [birthDate, setBirthDate] = useState({
		value: '',
		required: false,
	});
	const [workTime, setWorkTime] = useState({
		value: '',
		required: true,
	});
	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetUser, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				setNome({
					value: response.data.data.name,
					required: nome.required,
				});
				setCpf({
					value: response.data.data.support_user.cpf,
					required: cpf.required,
				});
				setEmail({
					value: response.data.data.email,
					required: email.required,
				});
				setTelefone({
					value: response.data.data.support_user.phone,
					required: telefone.required,
				});
				setBirthDate({
					value: response.data.data.support_user.birth_date,
					required: birthDate.required,
				});
				setWorkTime({
					value: response.data.data.support_user.work_schedule,
					required: birthDate.required,
				});

				axios
					.get(urlEndpointGetEmpreendimentos, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then(function (
						responseGetEmpreendimentos: typeResponseAxios,
					) {
						const allEmpreendimentos =
							responseGetEmpreendimentos.data.data.data;

						const newValueEmpreendimentos: {
							value: string | number;
							label: string;
							selected: boolean;
						}[] = [];

						if (typeUser === 'Porteiro') {
							newValueEmpreendimentos.push({
								value: allEmpreendimentos.id,
								label: allEmpreendimentos.name,
								selected: true,
							});
						} else {
							allEmpreendimentos.map((empreendimento: any) => {
								const isSelected = false;

								newValueEmpreendimentos.push({
									value: empreendimento.id,
									label: empreendimento.name,
									selected: isSelected,
								});
								return null;
							});
						}

						setEmpreendimentos({
							value: newValueEmpreendimentos,
							required: empreendimentos.required,
						});
					});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);
		lockFields(currentFields);

		const entriesDevelopments = empreendimentos.value;
		const valueDevelopments: {
			[key: string]: string | number;
		}[] = [];

		entriesDevelopments.map(empreendimento => {
			if (empreendimento.selected) {
				valueDevelopments.push({
					id: empreendimento.value,
				});
			}

			return null;
		});

		axios
			.put(
				urlEndpointUpdateUser,
				{
					type: 5,
					name: nome.value,
					cpf: cpf.value,
					email: email.value,
					phone: telefone.value,
					birth_date: birthDate.value,
					work_schedule: workTime.value,
					development: valueDevelopments,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);

				console.log(errosServidor);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Atualizar  / Porteiros`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ATUALIZAR PORTEIRO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS PORTEIRO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Nome completo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNome({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nome.value}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CPF"
							mask={[
								/\d/,
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCpf({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cpf.value}
							required={cpf.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="E-mail"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setEmail({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={email.value}
							required={email.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Telefone"
							mask={maskTelephone}
							onKeyDown={(e: any) => {
								if (e.which !== 9) {
									if (isTelefoneSP(e.currentTarget.value)) {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									} else {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									}
								}
							}}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTelefone({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={telefone.value}
							required={telefone.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Data de Nascimento"
							// mask={[
							// 	/\d/,
							// 	/\d/,
							// 	'/',
							// 	/\d/,
							// 	/\d/,
							// 	'/',
							// 	/\d/,
							// 	/\d/,
							// 	/\d/,
							// 	/\d/,
							// ]}
							type="date"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setBirthDate({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={birthDate.value}
							required={birthDate.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Horário de Trabalho"
							mask={[
								/\d/,
								/\d/,
								':',
								/\d/,
								/\d/,
								' ',
								'-',
								' ',
								/\d/,
								/\d/,
								':',
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setWorkTime({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={workTime.value}
							required={workTime.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					EMPREENDIMENTOS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-10">
						<BitInput
							label="Nome do empreendimento"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={empreendimentos.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueEmpreendimentos: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueEmpreendimentos.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setEmpreendimentos({
									value: newValueEmpreendimentos,
									required: empreendimentos.required,
								});
							}}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/registrations/porters'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateConcierge;
