import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { creditModalState } from '../../recoil/atoms';

type BitModalProps = {
	label: string;
	open: boolean;
	thumbnailHeader?: string;
	description?: string;
	maxWSize?:
		| 'max-w-screen-sm'
		| 'max-w-screen-md'
		| 'max-w-screen-lg'
		| 'max-w-screen-xl'
		| 'max-w-screen-2xl';
};

const BitModal: React.FC<BitModalProps> = ({
	label,
	open,
	thumbnailHeader,
	description,
	maxWSize,
	children,
}) => {
	const [modalShow, setModalShow] = useRecoilState(creditModalState);

	useEffect(() => {
		setModalShow({
			open,
		});
	}, []);

	const openModal = (e: any) => {
		e.preventDefault();
		setModalShow({
			open: !modalShow.open,
		});
	};

	const thumbnailHeaderModal = thumbnailHeader ? (
		<div className="max-w-xs pr-10">
			<img src={thumbnailHeader} alt="" />
		</div>
	) : null;

	return (
		<div className={`${modalShow.open ? 'block' : 'hidden'}`}>
			<div className="modal-container">
				<div className={` modal-overlay`} />
				<div
					className={`modal-content w-full ${
						maxWSize ?? 'max-w-screen-lg'
					} bg-white p-6 rounded`}
				>
					<div className="flex justify-between border-b pb-4">
						{thumbnailHeaderModal}

						<div className="flex-1">
							<h2 className="text-lg font-bold">
								{label ?? 'Sua Label'}
							</h2>
							<p>{description ?? 'Sua descrição!'}</p>
						</div>

						<div className=" text-right">
							<button
								type="button"
								onClick={(e: any) => openModal(e)}
							>
								<i className="fas fa-times text-xl cursor-pointer opacity-75 hover:opacity-100" />
							</button>
						</div>
					</div>

					{children}
				</div>
			</div>
		</div>
	);
};

export default BitModal;
