import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { accessPagesState, routesAccessPagesState } from '../../recoil/atoms';

import BitLogo from '../BitLogo/BitLogo';
import BitAccount from './BitAccount';
import BitLogout from './BitLogout';
import BitMenuIcon from './BitMenuIcon';

import iconCadastro from '../../assets/images/icon-cadastro.svg';
import iconDescontos from '../../assets/images/icon-descontos.svg';
import iconNotificacao from '../../assets/images/icon-notificacao.svg';
import iconPublicacoes from '../../assets/images/icon-publicacoes.svg';
import iconReservas from '../../assets/images/icon-reservas.svg';

const BitSideMenu: React.FC = () => {
	type typeMenuIcons = {
		[key: string]: {
			label: string;
			link: string;
			icon: string;
			isSVG: boolean;
		};
	};

	const pathName = useLocation().pathname;

	const typeUser = localStorage.getItem('type');

	const { permissions } = useRecoilValue(accessPagesState);

	const { permissionsRoutes } = useRecoilValue(routesAccessPagesState);

	const allMenuIcons: typeMenuIcons = {
		dashboard: {
			label: 'Dashboard',
			link: '/dashboard',
			icon: 'fas fa-home',
			isSVG: false,
		},
		dashboardLojista: {
			label: 'Dashboard',
			link: '/dashboard-lojista',
			icon: 'fas fa-home',
			isSVG: false,
		},
		users: {
			label: 'Cadastros',
			link: '/registrations',
			icon: iconCadastro,
			isSVG: true,
		},
		reservation: {
			label: 'Reservas',
			link: '/reservas',
			icon: iconReservas,
			isSVG: true,
		},
		publication: {
			label: 'Publicações',
			link: '/publicacoes',
			icon: iconPublicacoes,
			isSVG: true,
		},
		denunciations: {
			label: 'Denúncias',
			link: '/denuncias',
			icon: 'fas fa-bullhorn',
			isSVG: false,
		},
		voucher: {
			label: 'Descontos',
			link: '/descontos/vouchers',
			icon: iconDescontos,
			isSVG: true,
		},
		talk_with_gestor: {
			label: 'Fale Com Gestor',
			link: '/fcg/solicitations',
			icon: iconDescontos,
			isSVG: true,
		},
		package: {
			label: 'Encomendas',
			link: '/packages',
			icon: 'fas fa-box',
			isSVG: false,
		},
		push: {
			label: 'Notificação',
			link: '/notificacao/lista-de-push',
			icon: iconNotificacao,
			isSVG: true,
		},
	};

	const [menuIcons, setMenuIcons] = useState<typeMenuIcons>({});

	const menuIconsEffect: typeMenuIcons = {};
	if (typeUser === 'Administrador') {
		delete allMenuIcons.package;
		menuIconsEffect.dashboard = allMenuIcons.dashboard;
	}
	if (typeUser === 'Lojista') {
		delete allMenuIcons.package;
		menuIconsEffect.dashboardLojista = allMenuIcons.dashboardLojista;
	}
	Object.entries(permissions).forEach(([key, value]) => {
		if (key === 'users') {
			let firstUser = true;
			Object.entries(value).forEach(([keyUsers, valueUsers]) => {
				if (Array.isArray(valueUsers) && valueUsers.length > 0) {
					if (
						permissionsRoutes[key] !== undefined &&
						permissionsRoutes[key][keyUsers] !== undefined
					) {
						if (firstUser) {
							firstUser = false;

							valueUsers.forEach(valueUsersPerm => {
								if (
									permissionsRoutes[key][keyUsers][
										valueUsersPerm
									] !== undefined &&
									valueUsersPerm === 'user-list'
								) {
									permissionsRoutes[key][keyUsers][
										valueUsersPerm
									].forEach((valueRoute: string) => {
										allMenuIcons.users.link = valueRoute;

										menuIconsEffect[key] =
											allMenuIcons[key];
									});
								}
							});
						}
					}
				}
			});
		} else if (
			allMenuIcons[key] !== undefined &&
			(Object.keys(value).length > 0 ||
				(Array.isArray(value) && value.length > 0))
		) {
			menuIconsEffect[key] = allMenuIcons[key];

			if (key === 'publication') {
				menuIconsEffect.denunciations = allMenuIcons.denunciations;
			}
		}
	});

	if (typeUser === 'Lojista') {
		menuIconsEffect.talk_with_gestor = {
			label: 'Fale Com Gestor',
			link: '/chat',
			icon: iconDescontos,
			isSVG: true,
		};
	}

	useEffect(() => {
		setMenuIcons(menuIconsEffect);
	}, []);

	return (
		<aside className="w-20 h-full bg-greenMenu flex flex-col justify-between">
			<a className="mx-auto mt-4" href="/">
				<BitLogo width={'w-16'} styleImage={'white'} />
			</a>

			<nav className="menu menu-lateral-principal">
				{Object.entries(menuIcons).map(([key, icon]) => {
					return (
						<BitMenuIcon
							key={icon.link}
							label={icon.label}
							link={icon.link}
							icon={icon.icon}
							isSVG={icon.isSVG}
							pathName={pathName}
						/>
					);
				})}
			</nav>

			<div className="mb-4 flex flex-col items-center">
				<BitAccount />
				<BitLogout />
			</div>
		</aside>
	);
};

export default BitSideMenu;
