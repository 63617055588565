import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const UpdateDenuncia: React.FC = () => {
	Moment.locale('pt-br');

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdate = `${urlEndpoint}/denunciations/update/${idRegister}`;
	const urlRemovePublication = `${urlEndpoint}/publications/delete`;
	const urlEndpointGet = `${urlEndpoint}/denunciations/${idRegister}`;

	const token = localStorage.getItem('token');

	interface interfaceStatus {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const [nome, setNome] = useState({
		value: '',
		id: 0,
		required: true,
	});
	const [status, setStatus] = useState<interfaceStatus>({
		value: [
			{
				value: 'Pendente',
				label: 'Pendente',
				selected: false,
			},
			{
				value: 'Em Andamento',
				label: 'Em Andamento',
				selected: false,
			},
			{
				value: 'Finalizada',
				label: 'Finalizada',
				selected: false,
			},
		],
		required: false,
	});
	const [reason, setReason] = useState({
		value: '',
		required: true,
	});
	const [denunciationId, setDenunciationId] = useState({
		value: '',
		required: true,
	});
	const [type, setType] = useState({
		value: '',
		required: true,
	});
	const [denunciation, setDenunciation] = useState({
		value: '',
		required: true,
	});
	const [contentDenunciated, setContentDenunciated] = useState({
		value: '',
		required: true,
	});
	const [createdAt, setCreatedAt] = useState({
		value: '',
		required: false,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			if (elField.querySelector('input[name="Status')) {
				console.log('.');
			} else if (
				elField.querySelector(
					'input[name="Redija uma Resposta para o Proprietário"]',
				)
			) {
				console.log('.');
			} else {
				elField.removeAttribute('disabled');
			}
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGet, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				if (response.data.data?.publication?.id) {
					setDenunciationId({
						value: response.data.data.publication.id,
						required: denunciationId.required,
					});
				}
				setNome({
					value: response.data.data.user.name,
					id: response.data.data.user.id,
					required: nome.required,
				});

				setStatus({
					value: [
						{
							value: 'Pendente',
							label: 'Pendente',
							selected: response.data.data.status === 'Pendente',
						},
						{
							value: 'Em Andamento',
							label: 'Em Andamento',
							selected:
								response.data.data.status === 'Em Andamento',
						},
						{
							value: 'Finalizada',
							label: 'Finalizada',
							selected:
								response.data.data.status === 'Finalizada',
						},
					],
					required: true,
				});
				setType({
					value: response.data.data.type === 'chat'
					? 'Chat Simples'
					: (response.data.data.type === 'publication'
					? 'Publicação'
					: (response.data.data.type === 'group_chat'
					? 'Chat em Grupo'
					: '')),
					required: type.required
				});
				setReason({
					value: response.data.data.reason.reason,
					required: reason.required,
				});
				setDenunciation({
					value: response.data.data.denunciation,
					required: denunciation.required,
				});
				setContentDenunciated({
					value: response.data.data.denunciation,
					required: denunciation.required,
				});
				setCreatedAt({
					value: Moment(
						response.data.data.created_at,
						'YYYY-MM-DD HH:mm:ss',
					).format('DD/MM/YYYY - HH:mm'),
					required: createdAt.required,
				});

				if (response.data.data.type === 'publication') {
					setContentDenunciated({
						value: response.data.data.publication?.body,
						required: denunciation.required,
					});
				} else if (response.data.data.type === 'chat') {
					setContentDenunciated({
						value: response.data.data.chat_message.message,
						required: denunciation.required,
					});
				} else if (response.data.data.type === 'group_chat') {
					setContentDenunciated({
						value: response.data.data.group_chat_message.message,
						required: denunciation.required,
					});
				}
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>, otherStatus?: string) => {
		e.preventDefault();

		setShowRequiredBallon(true);
		// lockFields(currentFields);

		axios
			.put(
				urlEndpointUpdate,
				{
					status: otherStatus ?? status.value.filter(st => st.selected)[0].value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					// unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				// unlockFields(currentFields);
			});
	};

	const handleRemovePublication = (e: React.FormEvent<HTMLInputElement>, id: string) => {
		axios
			.delete(
			`${urlRemovePublication}/${id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 204) {

					handleCadastrar(e, 'Finalizada');

					toast.success('Publicação removida com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao remover publicação.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Atualizar  / Solicitação`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ATUALIZAR SOLICITAÇÃO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS SOLICITAÇÃO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Solicitante"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							value={nome.value}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
						<BitInput
							label="Data e Hora"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							value={createdAt.value}
							required={createdAt.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Motivo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							value={reason.value}
							required={reason.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
						<BitInput
							label="Status"
							type="select"
							multipleSelect={false}
							placeholder=""
							valuesSelect={status.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueStatus: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueStatus.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setStatus({
									value: newValueStatus,
									required: status.required,
								});
							}}
						/>
					</div>
					<div className="grid grid-cols-1 gap-4 mb-12">
						<BitInput
							label="Tipo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							value={type.value}
							required={type.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
					</div>
					<div className="grid grid-cols-1 gap-4 mb-12">
						<BitInput
							label="Descrição da Denúncia"
							type="textarea"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDenunciation({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={denunciation.value}
							required={denunciation.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
					</div>
					<div className="grid grid-cols-1 gap-4 mb-12">
						<BitInput
							label="Conteúdo Denunciado"
							type="textarea"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setContentDenunciated({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={contentDenunciated.value}
							required={contentDenunciated.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/denuncias'}
							/>
						</div>
						<BitButton
							text={'SALVAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
						{
							type.value === 'Publicação'
								?
									<BitButton
										text='REMOVER PUBLICAÇÃO'
										buttonColor={'#3AABA2'}
										textColor={'#FFFFFF'}
										type={'fit ml-4'}
										classesBlockOption={'inline-block'}
										onClick={(e:  React.FormEvent<HTMLInputElement>) => handleRemovePublication(e, denunciationId.value)}
									/>
								:
									<></>
							}
						{/* <BitButton
							text={'BLOQUEAR DENUNCIADO'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit ml-4'}
							classesBlockOption={'inline-block'}
							// onClick={() => {}}
						/> */}
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateDenuncia;
