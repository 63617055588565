import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const NewDesconto: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceAnunciantes {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointCreateDesconto = `${urlEndpoint}/vouchers/store`;
	const urlEndpointGetUsuarios = `${urlEndpoint}/users`;
	const urlEndpointGetCategories = `${urlEndpoint}/shopkeepers/categories`;
	const userType = localStorage.getItem('type');
	const developmentId = localStorage.getItem('development_id');
	const userName = localStorage.getItem('name') ?? '';
	const userId = localStorage.getItem('user_id') ?? '';

	const token = localStorage.getItem('token');

	const [titulo, setTitulo] = useState({
		value: '',
		required: true,
	});
	const [categorias, setCategorias] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});
	const [cupomDesconto, setCupomDesconto] = useState({
		value: '',
		required: true,
	});
	const [anunciantes, setAnunciantes] = useState<interfaceAnunciantes>({
		value: [],
		required: true,
	});
	const [arquivos, setArquivos] = useState<interfaceArquivos>({
		value: '',
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const limpaCampos = () => {
		setTitulo({
			value: '',
			required: titulo.required,
		});
		setCupomDesconto({
			value: '',
			required: true,
		});
		setArquivos({
			value: '',
			required: arquivos.required,
		});

		setShowRequiredBallon(false);
	};

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		if (userType && userType === 'Lojista') {
			setAnunciantes({
				value: [
					{
						value: userId,
						label: userName,
						selected: true,
					},
				],
				required: true,
			});
		}

		axios
			.get(urlEndpointGetUsuarios, {
				params: {
					type: 2,
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allAnunciantes = response.data.data.data;

				const newValueAnunciantes: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allAnunciantes.map((empreendimento: any) => {
					newValueAnunciantes.push({
						value: empreendimento.id,
						label: empreendimento.name,
						selected: false,
					});
					return null;
				});

				setAnunciantes({
					value: newValueAnunciantes,
					required: anunciantes.required,
				});
			});

		axios
			.get(urlEndpointGetCategories, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allCategorias = response.data.data.data;

				const newValueCategorias: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allCategorias.map((empreendimento: any) => {
					newValueCategorias.push({
						value: empreendimento.id,
						label: empreendimento.title,
						selected: false,
					});
					return null;
				});

				setCategorias({
					value: newValueCategorias,
					required: categorias.required,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		lockFields(currentFields);

		const entriesDevelopments = anunciantes.value;
		const valueDevelopments: {
			[key: string]: string | number;
		}[] = [];

		entriesDevelopments.map(empreendimento => {
			if (empreendimento.selected) {
				valueDevelopments.push({
					id: empreendimento.value,
				});
			}

			return null;
		});

		const entriesCategorias = categorias.value;
		const valueCategorias: {
			[key: string]: string | number;
		}[] = [];

		entriesCategorias.map(categoria => {
			if (categoria.selected) {
				valueCategorias.push({
					id: categoria.value,
				});
			}

			return null;
		});

		axios
			.post(
				urlEndpointCreateDesconto,
				{
					title: titulo.value,
					voucher: cupomDesconto.value,
					development_id: developmentId,
					shopkeeper_id:
						valueDevelopments[0] !== undefined
							? valueDevelopments[0].id
							: null,
					archive: arquivos.value,
					categories: valueCategorias,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 201) {
					toast.success('Cadastro realizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					limpaCampos();

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao cadastrar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};
	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Desconto`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR DESCONTO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DO DESCONTO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						{userType && userType !== 'Lojista' ? (
							<BitInput
								label="Anunciante"
								type="select"
								multipleSelect={false}
								placeholder=""
								valuesSelect={anunciantes.value}
								classField="grupo-campo-novos-registros"
								onChange={(
									e: React.FormEvent<HTMLSelectElement>,
								) => {
									const allOptions = Object.entries(
										e.currentTarget.options,
									);
									const newValueAnunciantes: {
										value: string | number;
										label: string;
										selected: boolean;
									}[] = [];

									allOptions.map(option => {
										if (option[1].value !== '') {
											newValueAnunciantes.push({
												value: option[1].value,
												label: option[1].text,
												selected: option[1].selected,
											});
										}
										return null;
									});

									setAnunciantes({
										value: newValueAnunciantes,
										required: anunciantes.required,
									});
								}}
							/>
						) : (
							<></>
						)}
					</div>
					<div className="grid grid-cols-2 gap-4 mb-4">
						<BitInput
							label="Título"
							type="text"
							maxLength={50}
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTitulo({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={titulo.value}
							required={titulo.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Cupom de desconto"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCupomDesconto({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cupomDesconto.value}
							required={cupomDesconto.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					INFORMAÇÕES EXTRAS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-10">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivoDesconto = document.getElementById(
									'arquivoDesconto',
								);
								if (inputArquivoDesconto !== null) {
									inputArquivoDesconto.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivoDesconto`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos({
												value: e.target.result,
												required: arquivos.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos.value}
							required={arquivos.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivoDesconto'}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/descontos/categorias'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default NewDesconto;
