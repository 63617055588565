import React from 'react';
import BitHeaderSubMenu from './BitHeaderSubMenu';

type BitHeaderSecondMenuProps = {
	label: string;
	icon: string;
	subMenu: {
		[key: string]: {
			label: string;
			link: string;
		};
	};
	pathName: string;
};

const BitHeaderSecondMenu: React.FC<BitHeaderSecondMenuProps> = ({
	label,
	icon,
	subMenu,
	pathName,
}) => {
	const defaultClassIcon = 'font-semibold uppercase text-sm opacity-25 ';

	return (
		<div
			className="max-w-xs flex-shrink-0 shadow-inner bg-gray-100 p-6"
			id="sub-menu"
		>
			<h2 className="pb-5 mb-5 border-b border-gray-300">
				<i
					className={
						icon
							? defaultClassIcon + icon
							: `${defaultClassIcon} fas fa-home`
					}
				/>
				<span className="font-semibold uppercase text-sm opacity-25 pl-2">
					{label ?? 'Sua Label'}
				</span>
			</h2>

			<nav className="sub-menu">
				{Object.entries(subMenu).map(([key, sub]) => {
					return (
						<BitHeaderSubMenu
							key={sub.link}
							label={sub.label}
							link={sub.link}
							pathName={pathName}
						/>
					);
				})}
			</nav>
		</div>
	);
};

export default BitHeaderSecondMenu;
