import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

const NewMorador: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointCreateUser = `${urlEndpoint}/users/store`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;

	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');

	const [maskTelephone, setmaskTelephone] = useState([
		'(',
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	]);

	const [nome, setNome] = useState({
		value: '',
		required: true,
	});
	const [cpf, setCpf] = useState({
		value: '',
		required: true,
	});
	const [dataNascimento, setDataNascimento] = useState({
		value: '',
		required: false,
	});
	const [email, setEmail] = useState({
		value: '',
		required: true,
	});
	const [telefone, setTelefone] = useState({
		value: '',
		required: true,
	});
	const [senha, setSenha] = useState({
		value: '',
		required: true,
	});
	const [senhaconfirmacao, setSenhaConfirmacao] = useState({
		value: '',
		required: true,
	});
	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});
	const [setor, setSetor] = useState({
		value: '',
		required: false,
	});
	const [loja, setLoja] = useState({
		value: '',
		required: false,
	});
	const [tipoMoradia, setTipoMoradia] = useState({
		value: '',
		required: false,
	});
	const [lote, setLote] = useState({
		value: '',
		required: false,
	});
	const [quadra, setQuadra] = useState({
		value: '',
		required: false,
	});
	const [unidade, setUnidade] = useState({
		value: '',
		required: false,
	});
	const [nomeContrato, setNomeContrato] = useState({
		value: '',
		required: false,
	});
	const [statusEmpreendimento, setStatusEmpreendimento] = useState({
		value: '',
		required: false,
	});

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const limpaCampos = () => {
		setNome({
			value: '',
			required: nome.required,
		});
		setCpf({
			value: '',
			required: cpf.required,
		});
		setEmail({
			value: '',
			required: email.required,
		});
		setTelefone({
			value: '',
			required: telefone.required,
		});
		setSenha({
			value: '',
			required: senha.required,
		});
		setSenhaConfirmacao({
			value: '',
			required: senhaconfirmacao.required,
		});
		setDataNascimento({
			value: '',
			required: dataNascimento.required,
		});
		setSetor({
			value: '',
			required: setor.required,
		});
		setLoja({
			value: '',
			required: loja.required,
		});
		setTipoMoradia({
			value: '',
			required: tipoMoradia.required,
		});
		setLote({
			value: '',
			required: lote.required,
		});
		setQuadra({
			value: '',
			required: quadra.required,
		});
		setUnidade({
			value: '',
			required: unidade.required,
		});
		setNomeContrato({
			value: '',
			required: nomeContrato.required,
		});
		setStatusEmpreendimento({
			value: '',
			required: statusEmpreendimento.required,
		});

		setShowRequiredBallon(false);
	};

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;

				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				if (typeUser === 'Porteiro') {
					newValueEmpreendimentos.push({
						value: allEmpreendimentos.id,
						label: allEmpreendimentos.name,
						selected: true,
					});
				} else {
					allEmpreendimentos.map((empreendimento: any) => {
						const isSelected = false;

						newValueEmpreendimentos.push({
							value: empreendimento.id,
							label: empreendimento.name,
							selected: isSelected,
						});
						return null;
					});
				}

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});
			});
	}, []);

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		// if (senha.value !== senhaconfirmacao.value) {
		// 	toast.error('Erro ao cadastrar. As senhas devem ser iguais.', {
		// 		position: 'bottom-right',
		// 		autoClose: 3000,
		// 		hideProgressBar: true,
		// 		closeOnClick: false,
		// 		pauseOnHover: true,
		// 		draggable: false,
		// 		progress: undefined,
		// 	});
		// } else {
		lockFields(currentFields);

		const entriesDevelopments = empreendimentos.value;
		const valueDevelopments: {
			[key: string]: string | number;
		}[] = [];

		entriesDevelopments.map(empreendimento => {
			if (empreendimento.selected) {
				valueDevelopments.push({
					id: empreendimento.value,
					sector: setor.value,
					store: loja.value,
					type: tipoMoradia.value,
					lot: lote.value,
					block: quadra.value,
					unit: unidade.value,
					plan: nomeContrato.value,
				});
			}

			return null;
		});

		axios
			.post(
				urlEndpointCreateUser,
				{
					type: 3,
					name: nome.value,
					cpf: cpf.value,
					birth_date: dataNascimento.value,
					email: email.value,
					phone: telefone.value,
					development: valueDevelopments,
					password: senha.value,
					password_confirmation: senhaconfirmacao.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 201) {
					toast.success('Cadastro realizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					limpaCampos();

					unlockFields(currentFields);
				}
			})
			.catch(function (error: any) {
				if (error.response.data.message.includes('Duplicate entry')) {
					toast.error(
						`O usuário já existe! Error: ${
							error?.response?.data?.message || error
						}`,
						{
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						},
					);
				} else {
					toast.error(
						`Erro ao cadastrar. Verifique os campos obrigatórios e tente novamente. Error: ${
							error?.response?.data?.message || error
						}`,
						{
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						},
					);

					if (
						error.response.data.error.email !== undefined &&
						error.response.data.error.email[0] ===
							'The email has already been taken.'
					) {
						toast.error(
							`O e-mail escolhido já está sendo utilizado. Error: ${
								error?.response?.data?.message || error
							}`,
							{
								position: 'bottom-right',
								autoClose: 3000,
								hideProgressBar: true,
								closeOnClick: false,
								pauseOnHover: true,
								draggable: false,
								progress: undefined,
							},
						);
					}
					if (
						error.response.data.error.password !== undefined &&
						error.response.data.error.password[0] ===
							'The password must be an integer.'
					) {
						toast.error(
							`Senha deve conter somente números. Error: ${
								error?.response?.data?.message || error
							}`,
							{
								position: 'bottom-right',
								autoClose: 3000,
								hideProgressBar: true,
								closeOnClick: false,
								pauseOnHover: true,
								draggable: false,
								progress: undefined,
							},
						);
					}
				}

				unlockFields(currentFields);
			});
		// }
	};
	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Proprietário`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR PROPRIETÁRIO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DO PROPRIETÁRIO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Nome completo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNome({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nome.value}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CPF"
							mask={[
								/\d/,
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCpf({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cpf.value}
							required={cpf.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Data de Nascimento"
							// mask={[
							// 	/\d/,
							// 	/\d/,
							// 	'/',
							// 	/\d/,
							// 	/\d/,
							// 	'/',
							// 	/\d/,
							// 	/\d/,
							// 	/\d/,
							// 	/\d/,
							// ]}
							type="date"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDataNascimento({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={dataNascimento.value}
							required={dataNascimento.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="E-mail"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setEmail({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={email.value}
							required={email.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Telefone"
							mask={maskTelephone}
							onKeyDown={(e: any) => {
								if (e.which !== 9) {
									if (isTelefoneSP(e.currentTarget.value)) {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									} else {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									}
								}
							}}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTelefone({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={telefone.value}
							required={telefone.required}
							showRequiredBallon={showRequiredBallon}
						/>
						{/* <BitInput
							label="Senha"
							type="password"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setSenha({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={senha.value}
							required={senha.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Confirmar Senha"
							type="password"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setSenhaConfirmacao({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={senhaconfirmacao.value}
							required={senhaconfirmacao.required}
							showRequiredBallon={showRequiredBallon}
						/> */}
					</div>
				</div>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					EMPREENDIMENTOS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						<BitInput
							label="Nome do empreendimento"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={empreendimentos.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueEmpreendimentos: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueEmpreendimentos.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setEmpreendimentos({
									value: newValueEmpreendimentos,
									required: empreendimentos.required,
								});
							}}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Loja"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setLoja({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={loja.value}
							required={loja.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Tipo moradia"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTipoMoradia({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={tipoMoradia.value}
							required={tipoMoradia.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Lote"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setLote({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={lote.value}
							required={lote.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Quadra"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setQuadra({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={quadra.value}
							required={quadra.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Unidade"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setUnidade({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={unidade.value}
							required={unidade.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Nome do contrato"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNomeContrato({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nomeContrato.value}
							required={nomeContrato.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Status do empreendimento"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setStatusEmpreendimento({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={statusEmpreendimento.value}
							required={statusEmpreendimento.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/registrations/residents'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default NewMorador;
