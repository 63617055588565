import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const NewPackage: React.FC = () => {
	Moment.locale('pt-br');

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const token = localStorage.getItem('token');
	const developmentName = localStorage.getItem('development_name');
	const idDevelopment = localStorage.getItem('development_id');

	const urlEndpointCreatePackage = `${urlEndpoint}/packages/store`;
	const urlEndpointGetUsers = `${urlEndpoint}/users?type=3&development=${developmentName}`;

	const [users, setUsers] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});
	const [developmentId, setDevelopmentId] = useState(idDevelopment);
	const [selectedUser, setSelectedUser] = useState(0);
	const [description, setDescription] = useState({
		value: '',
		required: false,
	});
	const [user, setUser] = useState({
		value: '',
		required: false,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	const limpaCampos = () => {
		setSelectedUser(0);
		setDescription({
			value: '',
			required: false,
		});
		setUser({
			value: '',
			required: false,
		});

		setShowRequiredBallon(false);
	};

	const handleGetUsers = async (name: string) => {
		axios
			.get(`${urlEndpointGetUsers}&name=${name}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;

				const newValueUsers: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allEmpreendimentos.map((empreendimento: any) => {
					const isSelected = false;

					newValueUsers.push({
						value: empreendimento.id,
						label: empreendimento.name,
						selected: isSelected,
					});
					return null;
				});

				setUsers({
					value: newValueUsers,
					required: users.required,
				});
			});
	};

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);
		lockFields(currentFields);

		axios
			.post(
				urlEndpointCreatePackage,
				{
					user_id: selectedUser,
					development_id: developmentId,
					...(description.value
						? { description: description.value }
						: {}),
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 201) {
					toast.success('Cadastro realizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					unlockFields(currentFields);
					limpaCampos();
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Atualizar  / Encomenda`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR ENCOMENDA</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DA ENCOMENDA
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-1 gap-4 mb-10">
						<BitInput
							label="Descrição"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDescription({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={description.value}
							required={description.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<p>
						<b>Obs:</b>
						{
							' Para realizar a busca basta digitar o nome abaixo, não é preciso apertar nenhum botão de confirmação:'
						}
					</p>
					<br />
					<div className="grid grid-cols-1 gap-4 mb-10">
						<BitInput
							label="Busque pelo proprietário"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								setUser({
									value: e.currentTarget.value,
									required: false,
								});
								handleGetUsers(e.currentTarget.value);
							}}
							value={user.value}
							required={user.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<p>
						<b>Obs: </b>
						{
							'Assim que digitar o nome do proprietário no campo acima, o sistema fará uma busca e retornará nomes relacionados. Selecione o mesmo na lista abaixo:'
						}
					</p>
					<br />
					<div className="mb-8">
						<BitInput
							label="Selecione o proprietário"
							type="select"
							multipleSelect={false}
							placeholder=""
							valuesSelect={users.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								setSelectedUser(
									Number(
										e.currentTarget.selectedOptions[0]
											.value,
									),
								);
							}}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/packages'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default NewPackage;
