type typeHeadersTable = {
	[key: string]: string[];
};

export const headersTable: typeHeadersTable = {
	'/registrations': [
		'ID',
		'CPF',
		'Nome',
		'E-mail',
		'Telefone',
		'Data de cadastro',
		'',
	],
	'/registrations/administrators': [
		'ID',
		'CPF',
		'Nome',
		'E-mail',
		'Telefone',
		'Data de cadastro',
		'',
	],
	'/registrations/enterprises': [
		'ID',
		'Nome',
		'Estado',
		'Cidade',
		'Telefone',
		'Data de cadastro',
		'',
	],
	'/registrations/shopkeepers': [
		'ID',
		'CNPJ',
		'Nome',
		'Cidade',
		'Telefone',
		'Data de cadastro',
		'',
	],
	'/registrations/residents': [
		'ID',
		'CPF',
		'Nome',
		'Empreendimento',
		'Data de cadastro',
		'',
	],
	'/registrations/managers': [
		'ID',
		'CPF',
		'Nome',
		'Telefone',
		'Empreendimento',
		'Data de cadastro',
		'',
	],
	'/registrations/porters': [
		'ID',
		'CPF',
		'Nome',
		'Telefone',
		'Empreendimento',
		'Data de cadastro',
		'',
	],
	'/reservas': [
		'ID',
		'Nome do proprietário',
		'Contato Proprietário',
		'Área comum',
		'Data de agendamento',
		'Horário agendamento',
	],
	'/fcg/solicitations': ['ID', 'Solicitante', 'Status', 'Motivo', ''],
	'/denuncias/razoes': ['ID', 'Razão', 'Data de cadastro', ''],
	'/denuncias': [
		'ID',
		'Solicitante',
		'Tipo',
		'Ref',
		'Denúncia',
		'Status',
		'Data de cadastro',
		'',
	],
	'/denuncias/filtrados': [
		'ID',
		'Título',
		'Empreendimentos',
		'Categoria',
		'Data de cadastro',
		'',
	],
	'/publicacoes': [
		'ID',
		'Título',
		'Empreendimentos',
		'Categoria',
		'Ativada',
		'Data/Hora Disparo',
		// 'Data de cadastro',
		'',
	],
	'/publicacoes/categorias': ['ID', 'Título', 'Tags', 'Data de cadastro', ''],
	'/enquetes': ['ID', 'Título', 'Empreendimento', 'Data de cadastro', ''],
	'/descontos/vouchers': [
		'ID',
		'Anunciante',
		'Título',
		'Descrição',
		'Cupom de desconto',
		'Data de cadastro',
		'',
	],
	'/descontos/categorias': ['ID', 'Título', 'Data de cadastro', ''],
	'/notificacao/lista-de-push': [
		'ID',
		'Empreendimento',
		'Título',
		'Descrição',
		'Recebido',
		'Visualizou',
		'Data de cadastro',
		'',
	],
	'/packages': [
		'ID',
		'Nome do proprietário',
		'Empreendimento',
		'CPF',
		'E-mail',
		'Data de cadastro',
		'',
	],
	'/shortcuts': ['ID', 'Nome', 'Link', 'Telefone', ''],
};
