import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

const UpdateShopKeeper: React.FC = () => {
	Moment.locale('pt-br');

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdateUser = `${urlEndpoint}/users/update/${idRegister}`;
	const urlEndpointGetUser = `${urlEndpoint}/users/${idRegister}`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;
	const urlEndpointGetCategories = `${urlEndpoint}/shopkeepers/categories`;

	const token = localStorage.getItem('token');

	const [maskTelephone, setmaskTelephone] = useState([
		'(',
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	]);

	const [nome, setNome] = useState({
		value: '',
		required: true,
	});
	const [horarioTrabalho, setHorarioTrabalho] = useState({
		value: '',
		required: true,
	});
	const [categorias, setCategorias] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});
	const [selectCategorias, setSelectedCategorias] = useState([]);
	const [cnpj, setCnpj] = useState({
		value: '',
		required: true,
	});
	const [email, setEmail] = useState({
		value: '',
		required: true,
	});
	const [telefone, setTelefone] = useState({
		value: '',
		required: true,
	});
	const [sector, setSector] = useState({
		value: '',
		required: true,
	});
	const [description, setDescription] = useState({
		value: '',
		required: true,
	});
	const [line1, setLine1] = useState({
		value: '',
		required: true,
	});
	const [line2, setLine2] = useState({
		value: '',
		required: true,
	});
	const [number, setNumber] = useState({
		value: '',
		required: true,
	});
	const [complement, setComplement] = useState({
		value: '',
		required: true,
	});
	const [neighborhood, setNeighborhood] = useState({
		value: '',
		required: true,
	});
	const [city, setCity] = useState({
		value: '',
		required: true,
	});
	const [state, setState] = useState({
		value: '',
		required: true,
	});
	const [zipCode, setZipCode] = useState({
		value: '',
		required: true,
	});
	const [plano, setPlano] = useState({
		value: '',
		required: false,
	});
	const [quantidadeVouchers, setQuantidadeVouchers] = useState({
		value: '',
		required: false,
	});
	const [desconto, setDesconto] = useState({
		value: '',
		required: false,
	});
	const [arquivos, setArquivos] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});
	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetUser, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				setNome({
					value: response.data.data.name,
					required: nome.required,
				});
				setHorarioTrabalho({
					value: response.data.data.support_user.work_schedule,
					required: horarioTrabalho.required,
				});
				setCnpj({
					value: response.data.data.support_user.cnpj,
					required: cnpj.required,
				});
				setEmail({
					value: response.data.data.email,
					required: email.required,
				});
				setTelefone({
					value: response.data.data.support_user.phone,
					required: telefone.required,
				});
				setLine1({
					value: response.data.data.support_user.address_line_1,
					required: line1.required,
				});
				setLine2({
					value: response.data.data.support_user.address_line_2,
					required: line2.required,
				});
				setNumber({
					value: response.data.data.support_user.number,
					required: number.required,
				});
				setNeighborhood({
					value: response.data.data.support_user.neighborhood,
					required: neighborhood.required,
				});
				setCity({
					value: response.data.data.support_user.city,
					required: city.required,
				});
				setState({
					value: response.data.data.support_user.state,
					required: state.required,
				});
				setZipCode({
					value: response.data.data.support_user.zip_code,
					required: zipCode.required,
				});
				setComplement({
					value: response.data.data.support_user.complement,
					required: complement.required,
				});
				setDescription({
					value: response.data.data.support_user.description,
					required: description.required,
				});
				setSector({
					value: response.data.data.support_user.sector,
					required: sector.required,
				});
				setArquivos({
					value: response.data.data.support_user.photo,
					required: arquivos.required,
				});

				const allCategs: any = [];
				console.log(response.data.data.categories);
				response.data.data.categories.forEach((catg: any) => {
					allCategs.push({ value: catg.id });
				});

				setSelectedCategorias(allCategs);

				if (response.data.data.user_development !== null) {
					setPlano({
						value: response.data.data.user_development.plan,
						required: plano.required,
					});
					setQuantidadeVouchers({
						value:
							response.data.data.user_development.number_vouchers,
						required: quantidadeVouchers.required,
					});
					setDesconto({
						value:
							response.data.data.user_development
								.discount_percentage,
						required: desconto.required,
					});
				}

				axios
					.get(urlEndpointGetEmpreendimentos, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then(function (
						responseGetEmpreendimentos: typeResponseAxios,
					) {
						const allEmpreendimentos =
							responseGetEmpreendimentos.data.data.data;

						const newValueEmpreendimentos: {
							value: string | number;
							label: string;
							selected: boolean;
						}[] = [];

						allEmpreendimentos.map((empreendimento: any) => {
							newValueEmpreendimentos.push({
								value: empreendimento.id,
								label: empreendimento.name,
								selected:
									response.data.data.user_development !==
										null &&
									response.data.data.user_development
										.development_id === empreendimento.id,
							});
							return null;
						});

						setEmpreendimentos({
							value: newValueEmpreendimentos,
							required: empreendimentos.required,
						});
					});
			});

		axios
			.get(urlEndpointGetCategories, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allCategorias = response.data.data.data;

				const newValueCategorias: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				selectCategorias.forEach((cat: any) => {
					allCategorias.forEach((empreendimento: any) => {
						newValueCategorias.push({
							value: empreendimento.id,
							label: empreendimento.title,
							selected: cat.value === empreendimento.id,
						});
					});
				});

				setCategorias({
					value: newValueCategorias,
					required: categorias.required,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);
		lockFields(currentFields);

		const entriesDevelopments = empreendimentos.value;
		const valueDevelopments: {
			[key: string]: string | number;
		}[] = [];

		entriesDevelopments.map(empreendimento => {
			if (empreendimento.selected) {
				valueDevelopments.push({
					id: empreendimento.value,
					plan: plano.value,
					number_vouchers: quantidadeVouchers.value,
					discount_percentage: desconto.value,
				});
			}

			return null;
		});

		const entriesCategorias = categorias.value;
		const valueCategorias: {
			[key: string]: string | number;
		}[] = [];

		entriesCategorias.map(categoria => {
			if (categoria.selected) {
				valueCategorias.push({
					id: categoria.value,
				});
			}

			return null;
		});

		axios
			.put(
				urlEndpointUpdateUser,
				{
					type: 2,
					name: nome.value,
					cnpj: cnpj.value,
					email: email.value,
					phone: telefone.value,
					sector: sector.value,
					categories: valueCategorias,
					description: description.value,
					address: {
						line_1: line1.value,
						line_2: line2.value,
						number: number.value,
						complement: complement.value,
						neighborhood: neighborhood.value,
						city: city.value,
						state: state.value,
						zip_code: zipCode.value,
					},
					development: valueDevelopments,
					archive: arquivos.value,
					work_schedule: horarioTrabalho.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Atualizar  / Lojista`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ATUALIZAR LOJISTA</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS LOJISTA
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Nome completo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNome({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nome.value}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CNPJ"
							mask={[
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'/',
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCnpj({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cnpj.value}
							required={cnpj.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="E-mail"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setEmail({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={email.value}
							required={email.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Telefone"
							mask={maskTelephone}
							onKeyDown={(e: any) => {
								if (e.which !== 9) {
									if (isTelefoneSP(e.currentTarget.value)) {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									} else {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									}
								}
							}}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTelefone({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={telefone.value}
							required={telefone.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Setor"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setSector({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={sector.value}
							required={sector.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>

					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Endereço"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setLine1({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={line1.value}
							required={line1.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Número"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNumber({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={number.value}
							required={number.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Complemento"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setLine2({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={line2.value}
							required={line2.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Ponto de Referência"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setComplement({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={complement.value}
							required={complement.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Bairro"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNeighborhood({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={neighborhood.value}
							required={neighborhood.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Cidade"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCity({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={city.value}
							required={city.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Estado"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setState({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={state.value}
							required={state.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CEP"
							mask={[
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setZipCode({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={zipCode.value}
							required={zipCode.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Horário de trabalho"
							mask={[
								/\d/,
								/\d/,
								':',
								/\d/,
								/\d/,
								' ',
								'-',
								' ',
								/\d/,
								/\d/,
								':',
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setHorarioTrabalho({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={horarioTrabalho.value}
							required={horarioTrabalho.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>

					<div className="mb-4">
						<BitInput
							label="Categoria"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={categorias.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueCategorias: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueCategorias.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setCategorias({
									value: newValueCategorias,
									required: categorias.required,
								});
							}}
						/>
					</div>

					<div className="grid grid-cols-1 gap-4">
						<BitInput
							label="Descrição"
							type="textarea"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDescription({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={description.value}
							required={description.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					EMPREENDIMENTOS CADASTRADOS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						<BitInput
							label="Nome do empreendimento"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={empreendimentos.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueEmpreendimentos: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueEmpreendimentos.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setEmpreendimentos({
									value: newValueEmpreendimentos,
									required: empreendimentos.required,
								});
							}}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">3</span>
					PLANO DE PROMOÇÕES
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						<BitInput
							label="Plano"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setPlano({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={plano.value}
							required={plano.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="mb-4">
						<BitInput
							label="Quantidade de vouchers"
							type="number"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setQuantidadeVouchers({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={quantidadeVouchers.value}
							required={quantidadeVouchers.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="mb-4">
						<BitInput
							label="Desconto"
							type="number"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDesconto({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={desconto.value}
							required={desconto.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">4</span>
					IMAGENS DE PROMOÇÕES
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-10">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivoLojista = document.getElementById(
									'arquivoLojista',
								);
								if (inputArquivoLojista !== null) {
									inputArquivoLojista.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivoLojista`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos({
												value: e.target.result,
												required: arquivos.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos.value}
							required={arquivos.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivoLojista'}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/registrations/shopkeepers'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateShopKeeper;
