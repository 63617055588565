import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

const UpdateAdministrador: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdateUser = `${urlEndpoint}/users/update/${idRegister}`;
	const urlEndpointGetUser = `${urlEndpoint}/users/${idRegister}`;

	const token = localStorage.getItem('token');

	const [maskTelephone, setmaskTelephone] = useState([
		'(',
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	]);
	const [nome, setNome] = useState({
		value: '',
		required: true,
	});
	const [cpf, setCpf] = useState({
		value: '',
		required: false,
	});
	const [email, setEmail] = useState({
		value: '',
		required: true,
	});
	const [telefone, setTelefone] = useState({
		value: '',
		required: true,
	});
	const [cargo, setCargo] = useState({
		value: '',
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetUser, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				setNome({
					value: response.data.data.name,
					required: nome.required,
				});
				setCpf({
					value: response.data.data.support_user.cpf,
					required: cpf.required,
				});
				setEmail({
					value: response.data.data.email,
					required: email.required,
				});
				setTelefone({
					value: response.data.data.support_user.phone,
					required: telefone.required,
				});
				setCargo({
					value: response.data.data.support_user.office,
					required: cargo.required,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);
		lockFields(currentFields);

		axios
			.put(
				urlEndpointUpdateUser,
				{
					type: 1,
					name: nome.value,
					cpf: cpf.value,
					email: email.value,
					phone: telefone.value,
					office: cargo.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);

				console.log(errosServidor);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Atualizar  / Administradores`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ATUALIZAR ADMINISTRADOR</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS ADMINISTRADOR
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Nome completo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNome({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nome.value}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CPF"
							mask={[
								/\d/,
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCpf({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cpf.value}
							required={cpf.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="E-mail"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setEmail({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={email.value}
							required={email.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Telefone"
							mask={maskTelephone}
							onKeyDown={(e: any) => {
								if (e.which !== 9) {
									if (isTelefoneSP(e.currentTarget.value)) {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									} else {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									}
								}
							}}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTelefone({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={telefone.value}
							required={telefone.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Cargo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCargo({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cargo.value}
							required={cargo.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/registrations/administrators'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateAdministrador;
