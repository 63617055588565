import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const NewEnquete: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	type typeOpcoes = {
		id: number;
		option: string;
		action: 'keep' | 'alter' | 'remove' | 'add';
	}[];

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointCreateEnquete = `${urlEndpoint}/pools/store`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;

	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');

	const [titulo, setTitulo] = useState({
		value: '',
		required: true,
	});
	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});

	const [opcoes, setOpcoes] = useState<typeOpcoes>([]);
	const [controllerOpcoes, setControllerOpcoes] = useState(false);

	const [adicionarOpcao, setAdicionarOpcao] = useState('');

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const limpaCampos = () => {
		setTitulo({
			value: '',
			required: titulo.required,
		});

		setShowRequiredBallon(false);
	};

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	const deleteOpcao = (idxOpcao: number) => {
		const newOpcoes = opcoes;

		const oldAdicionarOpcao = adicionarOpcao;

		newOpcoes.splice(idxOpcao, 1);

		setOpcoes(newOpcoes);

		setControllerOpcoes(!controllerOpcoes); // Se não coloca isso a opção não renderiza, então deixa ai
	};

	const addOpcao = () => {
		const newOpcoes = opcoes;

		if (adicionarOpcao !== '') {
			newOpcoes.push({
				id: 0,
				option: adicionarOpcao,
				action: 'add',
			});

			setOpcoes(newOpcoes);
			setAdicionarOpcao('');
		}
	};

	const retonaOpcoes = opcoes.map((opcao, idxOpcao) => {
		return (
			<div className="w-1/4">
				<div className="w-5/6 inline-block">{opcao.option}</div>
				<div className="w-1/6 inline-block">
					<button
						type="button"
						className="btn-adiciona-remove"
						onClick={() => {
							deleteOpcao(idxOpcao);
						}}
					>
						<i className="fas fa-minus" />
					</button>
				</div>
			</div>
		);
	});

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;

				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				// if (typeUser === 'Porteiro') {
				// 	newValueEmpreendimentos.push({
				// 		value: allEmpreendimentos.id,
				// 		label: allEmpreendimentos.name,
				// 		selected: true,
				// 	});
				// } else {
				allEmpreendimentos.map((empreendimento: any) => {
					newValueEmpreendimentos.push({
						value: empreendimento.id,
						label: empreendimento.name,
						selected: false,
					});
					return null;
				});
				// }

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		lockFields(currentFields);

		const entriesDevelopments = empreendimentos.value;
		const valueDevelopments: {
			[key: string]: string | number;
		}[] = [];

		let valueDevelopmentId: string | number = 0;

		entriesDevelopments.map(empreendimento => {
			if (empreendimento.selected) {
				valueDevelopments.push({
					id: empreendimento.value,
				});
				valueDevelopmentId = empreendimento.value;
			}

			return null;
		});

		const opcoesAdd = opcoes.map(opcao => {
			return opcao.option;
		});

		axios
			.post(
				urlEndpointCreateEnquete,
				{
					title: titulo.value,
					options: opcoesAdd,
					development_id: valueDevelopmentId,
					fixed_on_top: false,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 201) {
					toast.success('Cadastro realizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					limpaCampos();

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				toast.error(
					'Erro ao cadastrar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Enquete`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR ENQUETE</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DA ENQUETE
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-1 gap-4 mb-4">
						<BitInput
							label="Título"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTitulo({
									value: e.currentTarget.value,
									required: titulo.required,
								})
							}
							value={titulo.value}
							required={titulo.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<div className="options-enquete">
							<p>Opções da enquete</p>
							{retonaOpcoes}
							<div className="w-1/4">
								<div className="w-5/6 inline-block">
									<BitInput
										label=""
										type="text"
										placeholder="Adicionar opção"
										classField="grupo-campo-novos-registros"
										onChange={(
											e: React.FormEvent<HTMLInputElement>,
										) => {
											setAdicionarOpcao(
												e.currentTarget.value,
											);
										}}
										onKeyUp={(e: any) => {
											if (e.keyCode === 13) {
												addOpcao();
											}
										}}
										value={adicionarOpcao}
									/>
								</div>
								<div className="w-1/6 inline-block">
									<button
										type="button"
										className="btn-adiciona-remove"
										onClick={() => {
											addOpcao();
										}}
									>
										<i className="fas fa-plus" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					EMPREENDIMENTOS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label="Nome do empreendimento"
							type="select"
							multipleSelect={false}
							required={empreendimentos.required}
							placeholder=""
							valuesSelect={empreendimentos.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueEmpreendimentos: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueEmpreendimentos.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setEmpreendimentos({
									value: newValueEmpreendimentos,
									required: empreendimentos.required,
								});
							}}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<div className="text-right">
					<div className="inline-block mr-4">
						<BitLinkButton
							text={'VOLTAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							link={'/enquetes'}
						/>
					</div>
					<BitButton
						text={'FINALIZAR'}
						buttonColor={'#3AABA2'}
						textColor={'#FFFFFF'}
						type={'fit px-10'}
						classesBlockOption={'inline-block'}
						onClick={handleCadastrar}
					/>
				</div>

				<div className="mb-10" />
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default NewEnquete;
