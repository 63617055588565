import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import Pusher from 'pusher-js';
import LogoColor from '../../assets/images/logoColor.png';
import BitChatSearchTalk from '../../components/BitChat/BitChatSearchTalk';
import BitChatTalk from '../../components/BitChat/BitChatTalk';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import { endpointState } from '../../recoil/atoms';

const Chat: React.FC = () => {
	Moment.locale('pt-br');

	type typeMessagesList = {
		nomeContato: string;
		mensagemContato: string;
		tipoContato: 'minha_mensagem' | 'contato_mensagem';
		horarioEnvio: string;
	}[];

	type typeSendMessageInput = {
		name: string;
		placeholder: string;
		value: string;
		onChange: any;
		onKeyUp: any;
	};

	type typeSendSearchInput = {
		name: string;
		placeholder: string;
		value: string;
		onChange: any;
		onKeyUp: any;
	};

	type typeContatosProcura = {
		id: number;
		nomeContato: string;
		thumbContato: string;
		ultimoMensagemContato: string;
		ultimoHorarioEnvio: string;
	}[];

	type typeResponseAxios = {
		[key: string]: any;
	};

	const typeUser = localStorage.getItem('type');

	const [mensagemDigitada, setMensagemDigitada] = useState('');
	const [msgProcuraContato, setMsgProcuraContato] = useState('');

	const defaultThumbAvatar =
		'https://www.gravatar.com/avatar/1376dc0d9c58716c52dc2d685e0a643a.jpg?s=80&d=mp&r=g';

	const [
		contatosProcura,
		setContatosProcura,
	] = useState<typeContatosProcura | null>();

	const sendSearchInput: typeSendSearchInput = {
		name: 'procura-mensagem-input',
		placeholder: 'Buscar conversa',
		value: msgProcuraContato,
		onChange: (e: React.FormEvent<HTMLInputElement>) =>
			setMsgProcuraContato(e.currentTarget.value),
		onKeyUp: (e: any) => {
			if (e.which === 13) {
				console.log('onKeyUpSendSearchInputCall');
			}
		},
	};
	const sendMessageInput: typeSendMessageInput = {
		name: 'envia-mensagem-chat',
		placeholder: 'Envie uma mensagem...',
		value: mensagemDigitada,
		onChange: (e: React.FormEvent<HTMLInputElement>) =>
			setMensagemDigitada(e.currentTarget.value),
		onKeyUp: (e: any) => {
			if (e.which === 13) {
				onSendMessage();
			}
		},
	};

	const [messagesChat, setMessagesChat] = useState<typeMessagesList | null>();
	const [nomeContato, setNomeContato] = useState('Felipe Barros');
	const [chatId, setChatId] = useState(0);
	const [channelId, setChannelId] = useState('');
	const [fotoContato, setFotoContato] = useState(defaultThumbAvatar);

	const token = localStorage.getItem('token');
	const name = localStorage.getItem('name');
	const userId = localStorage.getItem('user_id');
	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointChat = `${urlEndpoint}/chats`;

	const onSendMessage = () => {
		if (mensagemDigitada !== '') {
			const newMessagesChat = messagesChat;

			if (newMessagesChat) {
				sendMessageForContact(mensagemDigitada);

				newMessagesChat.push({
					nomeContato: name ?? '',
					mensagemContato: mensagemDigitada,
					tipoContato: 'minha_mensagem',
					horarioEnvio: new Date(Date.now()).toISOString().toString(),
				});
			}

			setMessagesChat(newMessagesChat);
			setMensagemDigitada('');

			setTimeout(() => {
				const divTodasMensagensChat = document.getElementById(
					'todas-mensagens-chat',
				);
				if (divTodasMensagensChat !== null) {
					divTodasMensagensChat.scrollTop =
						divTodasMensagensChat.scrollHeight + 100;
				}
			}, 1000);
		}
	};

	const onSendSearch = () => {
		loadChats(sendSearchInput.value);
	};

	const onClickContato = (idxContatosProcura: number) => {
		setMessagesChat([]);

		if (contatosProcura) {
			loadMessageFromContact(contatosProcura[idxContatosProcura].id);
			setNomeContato(contatosProcura[idxContatosProcura].nomeContato);
			setFotoContato(contatosProcura[idxContatosProcura].thumbContato);

			setTimeout(() => {
				const divTodasMensagensChat = document.getElementById(
					'todas-mensagens-chat',
				);
				if (divTodasMensagensChat !== null) {
					divTodasMensagensChat.scrollTop =
						divTodasMensagensChat.scrollHeight + 100;
				}
			}, 1000);
		}
	};

	const loadMessageFromContact = async (id: number) => {
		await axios
			.get(`${urlEndpointChat}/${id}/messages`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res: typeResponseAxios) => {
				const responseData = res.data?.data;
				const chatMessages: any[] = [];

				responseData.forEach((element: any) => {
					chatMessages.push({
						nomeContato: element.user?.name,
						mensagemContato: element.message,
						tipoContato:
							Number(userId) === element.user?.id
								? 'minha_mensagem'
								: 'contato_mensagem',
						horarioEnvio: element.created_at,
					});
				});

				setMessagesChat(chatMessages.reverse());
			})
			.catch(err => {
				console.log(err);
			});
	};

	const sendMessageForContact = async (message: string) => {
		await axios
			.post(
				`${urlEndpointChat}/store/message`,
				{
					chat_id: chatId,
					message,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((res: typeResponseAxios) => {
				loadChats();
			});
	};

	const loadChats = async (search?: string) => {
		const urlGetChats = `${urlEndpointChat}${
			search ? `/search?name=${search}` : ''
		}`;
		axios
			.get(urlGetChats, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res: typeResponseAxios) => {
				const responseData = res.data?.data?.data;
				const chatContacts: any[] = [];

				responseData.forEach((element: any) => {
					setChatId(element.id);
					setChannelId(element.channel_name);
					chatContacts.push({
						id: element.id,
						nomeContato: element.creator?.name,
						thumbContato: element.creator?.support_user?.photo,
						ultimoMensagemContato: element.last_message,
						ultimoHorarioEnvio: Moment(element.updated_at).format(
							'HH:mm',
						),
					});
				});

				setContatosProcura(chatContacts);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleReloading = () => {
		setMsgProcuraContato('');
		onSendSearch();
	};

	useEffect(() => {
		loadChats();
		Notification.requestPermission();
	}, []);

	const listenMessages = (data: any) => {
		if (data.chatMessage?.user_id !== Number(userId)) {

			setMessagesChat((messages: any) => [
				...messages,
				{
					nomeContato: data.chatMessage?.user?.name ?? '',
					mensagemContato: data.chatMessage?.message ?? '',
					tipoContato: 'contato_mensagem',
					horarioEnvio: data.chatMessage?.created_at ?? '',
				},
			]);

			if (Notification.permission === 'granted') {
				const notificationESLINTCHATO = new Notification(
					`Chegou uma nova mensagem de ${data.chatMessage?.user?.name}!`,
					{
						body: data.chatMessage?.message,
					},
				);
			}

			setTimeout(() => {
				const divTodasMensagensChat = document.getElementById(
					'todas-mensagens-chat',
				);
				if (divTodasMensagensChat !== null) {
					divTodasMensagensChat.scrollTop =
						divTodasMensagensChat.scrollHeight + 100;
				}
				loadChats();
			}, 1000);
		}
	};

	const [allChannelsIds, setAllChannelsIds] = useState<string[]>([]);

	useEffect(() => {
		if (!allChannelsIds.includes(channelId)) {
			console.log(allChannelsIds);
			const pusher = new Pusher('38b4b26c0d98b903faea', {
				cluster: 'us2',
			});

			const channel = pusher.subscribe(channelId);

			channel.bind('App\\Events\\ChatMessageEvent', listenMessages);

			const newArrayAllChannelsIds = allChannelsIds;
			newArrayAllChannelsIds.push(channelId);

			setAllChannelsIds(newArrayAllChannelsIds);
		}
	}, [channelId]);

	return (
		<BitPage>
			<BitHeaderFull
				label={`Fale com o ${typeUser} / Chat`}
				icon={''}
				hasIcon={false}
			/>
			<div className="flex">
				<div className="w-3/12 pr-5">
					{contatosProcura ? (
						<>
							<div
								className="buttonReloading"
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<button
									type="button"
									className="reloading py-2 w-10/12"
									style={{
										color: '#FFFFFF',
										backgroundColor: '#89B30F',
										borderRadius: '100px',
									}}
									onClick={handleReloading}
								>
									Recarregar
								</button>
							</div>
							<BitChatSearchTalk
								contatosProcura={contatosProcura}
								sendSearchInput={sendSearchInput}
								onSendSearch={onSendSearch}
								onClickContato={onClickContato}
							/>
						</>
					) : (
						<></>
					)}
				</div>
				<div className="w-9/12 pl-5">
					{messagesChat ? (
						<BitChatTalk
							fotoContato={fotoContato}
							nomeContato={nomeContato}
							messagesList={messagesChat}
							sendMessageInput={sendMessageInput}
							onSendMessage={onSendMessage}
						/>
					) : (
						<img
							className={`w-full opacity-20 block`}
							src={LogoColor}
							alt="BitPlate"
						/>
					)}
				</div>
			</div>
		</BitPage>
	);
};

export default Chat;
