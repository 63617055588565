import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitHeaderSecondMenu from '../../components/BitHeader/BitHeaderSecondMenu';
import BitInput from '../../components/BitInput/BitInput';
import BitInputDescription from '../../components/BitInput/BitInputDescription';
import BitPage from '../../components/BitPage/BitPage';

const Profile: React.FC = () => {
	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}

	const pathName = useLocation().pathname;

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [arquivos, setArquivos] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});

	const subMenus = {
		profile: {
			label: 'Meus Dados',
			link: '/account/profile',
		},
	};

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointGetProfile = `${urlEndpoint}/profile`;
	const [profile, setProfile] = useState({});
	const [showRequiredBallon, setShowRequiredBallon] = useState(false);
	const token = localStorage.getItem('token');

	type typeResponseAxios = {
		[key: string]: any;
	};

	const handlePutProfile = async (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		if (password !== passwordConfirmation) {
			toast.error('Erro ao cadastrar. As senhas devem ser iguais.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		} else {
			await axios
				.put(
					urlEndpointGetProfile,
					{
						email,
						name,
						phone,
						password,
						archive: arquivos.value,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
							'Content-Type': 'application/json',
						},
					},
				)
				.then(function (response: typeResponseAxios) {
					if (response.status === 202) {
						toast.success('Perfil atualizado com sucesso!', {
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						});

						localStorage.setItem(
							'user_photo',
							response.data.data.photo,
						);

						setShowRequiredBallon(false);
					}
				})
				.catch((error: any) => {
					toast.error(
						'Erro ao atualizar. Verifique e tente novamente.',
						{
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						},
					);
				});
		}
	};

	useEffect(() => {
		const handleGetProfile = async () => {
			await axios
				.get(urlEndpointGetProfile, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response: typeResponseAxios) {
					setProfile(response.data.data);
					setEmail(response.data.data.email);
					setName(response.data.data.name);
					setPhone(response.data.data.phone);
					setArquivos({
						value: response.data.data.photo,
						required: arquivos.required,
					});
				})
				.catch((error: any) => {
					console.log(error);
				});
		};

		handleGetProfile();
	}, []);

	return (
		<BitPage
			secondMenu={
				<BitHeaderSecondMenu
					label={'Minha Conta'}
					icon={'fas fa-cog'}
					subMenu={subMenus}
					pathName={pathName}
				/>
			}
		>
			<BitHeaderFull label={'Meus Dados'} icon={'fas fa-cog'} />

			<div className="w-full p-5 bg-white rounded">
				<BitInput
					label={'Nome'}
					type={'text'}
					placeholder={'Seu Nome'}
					onChange={(e: React.FormEvent<HTMLInputElement>) =>
						setName(e.currentTarget.value)
					}
					value={name}
					required={true}
					showRequiredBallon={showRequiredBallon}
				/>

				<BitInput
					label={'E-mail'}
					type={'email'}
					placeholder={'Seu melhor e-mail'}
					onChange={(e: React.FormEvent<HTMLInputElement>) =>
						setEmail(e.currentTarget.value)
					}
					value={email}
					required={true}
					showRequiredBallon={showRequiredBallon}
				/>

				<BitInput
					label={'WhatsApp'}
					type={'tel'}
					placeholder={'Seu contato do WhatsApp'}
					onChange={(e: React.FormEvent<HTMLInputElement>) =>
						setPhone(e.currentTarget.value)
					}
					value={phone}
					required={true}
					showRequiredBallon={showRequiredBallon}
				/>
				<BitInput
					label=""
					type="file"
					placeholder="Digite..."
					classField=""
					onChange={(e: React.FormEvent<HTMLInputElement>) => {
						const inputArquivoLojista = document.getElementById(
							'arquivoLojista',
						);
						if (inputArquivoLojista !== null) {
							inputArquivoLojista.click();
						}
					}}
					onChangeFile={(
						eInput: React.FormEvent<HTMLInputElement>,
					) => {
						const currentFile = eInput.currentTarget.files;
						const currentElemImg: any = document.getElementById(
							`img-arquivoLojista`,
						);
						const FR = new FileReader();

						if (currentFile !== null) {
							FR.addEventListener('load', function (e) {
								if (e.target !== null) {
									setArquivos({
										value: e.target.result,
										required: arquivos.required,
									});

									if (currentElemImg !== null) {
										currentElemImg.src = e.target.result;
									}
								}
							});

							FR.readAsDataURL(currentFile[0]);
						}
					}}
					imgPreview={arquivos.value}
					required={arquivos.required}
					showRequiredBallon={showRequiredBallon}
					name={'arquivoLojista'}
				/>

				<BitInputDescription
					label={'Nova senha'}
					type={'password'}
					placeholder={'Sua nova senha'}
					description={
						'Preencha apenas se você quiser alterar sua senha.'
					}
					onChange={(e: React.FormEvent<HTMLInputElement>) =>
						setPassword(e.currentTarget.value)
					}
					value={password}
					required={false}
					autoComplete={'off'}
				/>

				<BitInput
					label={'Confirmar nova senha'}
					type={'password'}
					placeholder={'Confirme sua nova senha'}
					onChange={(e: React.FormEvent<HTMLInputElement>) =>
						setPasswordConfirmation(e.currentTarget.value)
					}
					value={passwordConfirmation}
					required={false}
					autoComplete={'off'}
				/>

				<BitButton
					text={'Salvar Alterações'}
					textColor={'#FFFFFF'}
					onClick={(e: React.FormEvent<HTMLInputElement>) =>
						handlePutProfile(e)
					}
				/>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default Profile;
