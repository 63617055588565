import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const UpdateSolicitations: React.FC = () => {
	Moment.locale('pt-br');

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdate = `${urlEndpoint}/solicitations/update/${idRegister}`;
	const urlEndpointGet = `${urlEndpoint}/solicitations/${idRegister}/list`;

	const token = localStorage.getItem('token');

	interface interfaceStatus {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const [nome, setNome] = useState({
		value: '',
		id: 0,
		required: true,
	});
	const [status, setStatus] = useState<interfaceStatus>({
		value: [
			{
				value: 'Pendente',
				label: 'Pendente',
				selected: false,
			},
			{
				value: 'Em Andamento',
				label: 'Em Andamento',
				selected: false,
			},
			{
				value: 'Finalizada',
				label: 'Finalizada',
				selected: false,
			},
		],
		required: false,
	});
	const [reason, setReason] = useState({
		value: '',
		required: true,
	});
	const [solicitation, setSolicitation] = useState({
		value: '',
		required: true,
	});
	const [comment, setComment] = useState({
		value: '',
		required: false,
	});
	const [createdAt, setCreatedAt] = useState({
		value: '',
		required: false,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			if (elField.querySelector('input[name="Status')) {
				console.log('.');
			} else if (
				elField.querySelector(
					'input[name="Redija uma Resposta para o Proprietário"]',
				)
			) {
				console.log('.');
			} else {
				elField.removeAttribute('disabled');
			}
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGet, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				setNome({
					value: response.data.data.user.name,
					id: response.data.data.user.id,
					required: nome.required,
				});

				setStatus({
					value: [
						{
							value: 'Pendente',
							label: 'Pendente',
							selected: response.data.data.status === 'Pendente',
						},
						{
							value: 'Em Andamento',
							label: 'Em Andamento',
							selected:
								response.data.data.status === 'Em Andamento',
						},
						{
							value: 'Finalizada',
							label: 'Finalizada',
							selected:
								response.data.data.status === 'Finalizada',
						},
					],
					required: true,
				});
				setReason({
					value: response.data.data.reason,
					required: reason.required,
				});
				setSolicitation({
					value: response.data.data.solicitation,
					required: solicitation.required,
				});
				setComment({
					value: response.data.data.comment,
					required: comment.required,
				});
				setCreatedAt({
					value: Moment(
						response.data.data.created_at,
						'YYYY-MM-DD HH:mm:ss',
					).format('DD/MM/YYYY - HH:mm'),
					required: createdAt.required,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);
		// lockFields(currentFields);

		axios
			.put(
				urlEndpointUpdate,
				{
					status: status.value.filter(st => st.selected)[0].value,
					comment: comment.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					// unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				// unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Atualizar  / Solicitação`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ATUALIZAR SOLICITAÇÃO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS SOLICITAÇÃO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Solicitante"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							value={nome.value}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
						<BitInput
							label="Data e Hora"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							value={createdAt.value}
							required={createdAt.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Motivo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							value={reason.value}
							required={reason.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
						<BitInput
							label="Status"
							type="select"
							multipleSelect={false}
							placeholder=""
							valuesSelect={status.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueStatus: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueStatus.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setStatus({
									value: newValueStatus,
									required: status.required,
								});
							}}
						/>
					</div>
					<div className="grid grid-cols-1 gap-4 mb-12">
						<BitInput
							label="Descrição da Solicitação"
							type="textarea"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setSolicitation({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={solicitation.value}
							required={solicitation.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
						<BitInput
							label="Redija uma Resposta para o Proprietário"
							type="textarea"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setComment({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={comment.value}
							required={comment.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/fcg/solicitations'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateSolicitations;
