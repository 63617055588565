import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BitProtectedRoute from './components/BitProtectedRoute/BitProtectedRoute';

import SignIn from './pages/SignIn/SignIn';
import ForgotPass from './pages/ForgotPass/ForgotPass';
import SignUp from './pages/SignUp/SignUp';
import Dashboard from './pages/Dashboard/Dashboard';
import Registers from './pages/Registers/Registers';
import ResidentsImport from './pages/Registers/ResidentsImport';
import ResidentsExport from './pages/Registers/ResidentsExport';
import Clients from './pages/Clients/Clients';
import Settings from './pages/Settings/Settings';
import Profile from './pages/Account/Profile';
import Credits from './pages/Account/Credits';

import NewAdministrador from './pages/NewRegisters/Administrator';
import NewEmpreendimento from './pages/NewRegisters/Empreendimento';
import NewLojista from './pages/NewRegisters/Lojista';
import NewMorador from './pages/NewRegisters/Morador';
import NewGestor from './pages/NewRegisters/Gestor';
import NewPorteiro from './pages/NewRegisters/Porteiro';
import NewReserva from './pages/NewRegisters/Reserva';
import NewPublicacao from './pages/NewRegisters/Publicacao';
import NewCategoria from './pages/NewRegisters/Categoria';
import NewDesconto from './pages/NewRegisters/Desconto';
import NewNotificacao from './pages/NewRegisters/Notificacao';

import UpdateAdministrador from './pages/UpdateRegisters/Administrator';

import './assets/main.css';
import NewAreaComum from './pages/NewRegisters/AreaComum';
import UpdateReservations from './pages/UpdateRegisters/Reservations';
import UpdateSolicitations from './pages/UpdateRegisters/Solicitations';
import UpdateConcierge from './pages/UpdateRegisters/Concierge';
import UpdateManager from './pages/UpdateRegisters/Manager';
import UpdateResident from './pages/UpdateRegisters/Resident';
import UpdateShopKeeper from './pages/UpdateRegisters/Shopkeeper';
import UpdateDevelopment from './pages/UpdateRegisters/Development';
import UpdateDesconto from './pages/UpdateRegisters/Voucher';
import UpdateCategoria from './pages/UpdateRegisters/Categoria';
import UpdateNotificacao from './pages/UpdateRegisters/Notificacao';
import UpdatePublicacao from './pages/UpdateRegisters/Publicacao';
import UpdateFiltrado from './pages/UpdateRegisters/Filtrado';
import NewDescontoCategoria from './pages/NewRegisters/DescontoCategoria';
import UpdateDescontoCategoria from './pages/UpdateRegisters/DescontoCategoria';
import UpdatePackage from './pages/UpdateRegisters/Package';
import NewPackage from './pages/NewRegisters/Package';
import Chat from './pages/Chat/Chat';
import DashboardLojista from './pages/Dashboard/DashboardLojista';
import NewEnquete from './pages/NewRegisters/Enquete';
import UpdateEnquete from './pages/UpdateRegisters/Enquete';
import NewRazaoDenuncia from './pages/NewRegisters/RazaoDenuncia';
import UpdateRazaoDenuncia from './pages/UpdateRegisters/RazaoDenuncia';
import UpdateDenuncia from './pages/UpdateRegisters/Denuncia';
import NewShortcut from './pages/NewRegisters/Shortcut';

toast.configure();

const typeUser = localStorage.getItem('type');
const redirectUserWhenEnter =
	(typeUser === 'Administrador' || typeUser === 'Gestor') ? '/dashboard' : '/account/profile';

ReactDOM.render(
	<React.StrictMode>
		<RecoilRoot>
			<BrowserRouter>
				<Switch>
					{/* <PrivateRoute path="/admin" component={AdminLayout} /> */}
					{/* <Route path="/auth" render={(props) => <AuthLayout {...props} />} /> */}
					<Route path="/login" component={SignIn} />
					<Route path="/esqueci-senha" component={ForgotPass} />
					{/* <Route path="/register" component={SignUp} /> */}
					<BitProtectedRoute
						path="/dashboard-lojista"
						component={DashboardLojista}
					/>
					<BitProtectedRoute
						path="/dashboard"
						component={Dashboard}
					/>
					<BitProtectedRoute
						path="/registrations"
						component={Registers}
					/>
					<BitProtectedRoute path="/reservas" component={Registers} />
					<BitProtectedRoute
						path="/fcg/solicitations"
						component={Registers}
					/>
					<BitProtectedRoute
						path="/denuncias"
						component={Registers}
					/>
					<BitProtectedRoute
						path="/publicacoes"
						component={Registers}
					/>
					<BitProtectedRoute path="/packages" component={Registers} />
					<BitProtectedRoute
						path="/publicacoes/categorias"
						component={Registers}
					/>
					<BitProtectedRoute
						path="/shortcuts"
						component={Registers}
					/>
					<BitProtectedRoute path="/enquetes" component={Registers} />
					<BitProtectedRoute
						path="/descontos/vouchers"
						component={Registers}
					/>
					<BitProtectedRoute
						path="/descontos/categorias"
						component={Registers}
					/>
					<BitProtectedRoute
						path="/notificacao/lista-de-push"
						component={Registers}
					/>
					{/* <BitProtectedRoute path="/settings" component={Settings} /> */}
					<BitProtectedRoute
						path="/account/profile"
						component={Profile}
					/>
					{/* <Route path="/account/credits" component={Credits} /> */}

					<BitProtectedRoute path="/chat" component={Chat} />

					<BitProtectedRoute
						path="/new-register/administrador"
						component={NewAdministrador}
					/>
					<BitProtectedRoute
						path="/new-register/empreendimento"
						component={NewEmpreendimento}
					/>
					<BitProtectedRoute
						path="/new-register/lojista"
						component={NewLojista}
					/>
					<BitProtectedRoute
						path="/new-register/morador"
						component={NewMorador}
					/>
					<BitProtectedRoute
						path="/new-register/gestor"
						component={NewGestor}
					/>
					<BitProtectedRoute
						path="/new-register/reserva"
						component={NewReserva}
					/>
					<BitProtectedRoute
						path="/new-register/publicacao"
						component={NewPublicacao}
					/>
					<BitProtectedRoute
						path="/new-register/enquete"
						component={NewEnquete}
					/>
					<BitProtectedRoute
						path="/new-register/categoria"
						component={NewCategoria}
					/>
					<BitProtectedRoute
						path="/new-register/shortcuts"
						component={NewShortcut}
					/>
					<BitProtectedRoute
						path="/new-register/desconto/categoria"
						component={NewDescontoCategoria}
					/>
					<BitProtectedRoute
						path="/new-register/desconto"
						component={NewDesconto}
					/>
					<BitProtectedRoute
						path="/new-register/porteiro"
						component={NewPorteiro}
					/>
					<BitProtectedRoute
						path="/new-register/notificacao"
						component={NewNotificacao}
					/>
					<BitProtectedRoute
						path="/new-register/area-comum"
						component={NewAreaComum}
					/>
					<BitProtectedRoute
						path="/new-register/packages"
						component={NewPackage}
					/>
					<BitProtectedRoute
						path="/new-register/razao"
						component={NewRazaoDenuncia}
					/>
					<BitProtectedRoute
						path="/update/filtrados/:idRegister"
						component={UpdateFiltrado}
					/>
					<BitProtectedRoute
						path="/update/administrador/:idRegister"
						component={UpdateAdministrador}
					/>
					<BitProtectedRoute
						path="/update/reserva/:idRegister"
						component={UpdateReservations}
					/>
					<BitProtectedRoute
						path="/update/fcg/solicitations/:idRegister"
						component={UpdateSolicitations}
					/>
					<BitProtectedRoute
						path="/update/porteiro/:idRegister"
						component={UpdateConcierge}
					/>
					<BitProtectedRoute
						path="/update/gestor/:idRegister"
						component={UpdateManager}
					/>
					<BitProtectedRoute
						path="/update/morador/:idRegister"
						component={UpdateResident}
					/>
					<BitProtectedRoute
						path="/update/packages/:idRegister"
						component={UpdatePackage}
					/>
					<BitProtectedRoute
						path="/update/lojista/:idRegister"
						component={UpdateShopKeeper}
					/>
					<BitProtectedRoute
						path="/update/empreendimento/:idRegister"
						component={UpdateDevelopment}
					/>
					<BitProtectedRoute
						path="/update/desconto/categoria/:idRegister"
						component={UpdateDescontoCategoria}
					/>
					<BitProtectedRoute
						path="/update/desconto/:idRegister"
						component={UpdateDesconto}
					/>
					<BitProtectedRoute
						path="/update/categoria/:idRegister"
						component={UpdateCategoria}
					/>
					<BitProtectedRoute
						path="/update/notificacao/:idRegister"
						component={UpdateNotificacao}
					/>
					<BitProtectedRoute
						path="/update/publicacao/:idRegister"
						component={UpdatePublicacao}
					/>
					<BitProtectedRoute
						path="/update/enquete/:idRegister"
						component={UpdateEnquete}
					/>
					<BitProtectedRoute
						path="/update/razao/:idRegister"
						component={UpdateRazaoDenuncia}
					/>
					<BitProtectedRoute
						path="/update/denuncia/:idRegister"
						component={UpdateDenuncia}
					/>
					<BitProtectedRoute
						path="/update/shortcuts/:idRegister"
						component={NewShortcut}
					/>
					<BitProtectedRoute
						path="/import"
						component={ResidentsImport}
					/>
					<BitProtectedRoute
						path="/export"
						component={ResidentsExport}
					/>
					<Redirect from="/" to={redirectUserWhenEnter} />
					<Redirect from="/account" to="/account/profile" />
				</Switch>
			</BrowserRouter>
		</RecoilRoot>
	</React.StrictMode>,
	document.getElementById('root'),
);
