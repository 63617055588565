import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

const UpdatePackage: React.FC = () => {
	Moment.locale('pt-br');

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdatePackage = `${urlEndpoint}/packages/update/${idRegister}`;
	const urlEndpointGetPackage = `${urlEndpoint}/packages/${idRegister}`;

	const token = localStorage.getItem('token');

	const [nome, setNome] = useState({
		value: '',
		required: true,
	});
	const [developmentId, setDevelopmentId] = useState({
		value: '',
		required: true,
	});
	const [email, setEmail] = useState({
		value: '',
		required: true,
	});
	const [description, setDescription] = useState({
		value: '',
		required: false,
	});
	const [userId, setUserId] = useState({
		value: '',
		required: false,
	});
	const [receivedBy, setReceivedBy] = useState({
		value: '',
		required: true,
	});
	const [development, setDevelopment] = useState({
		value: '',
		required: true,
	});
	const [cpf, setCpf] = useState({
		value: '',
		required: false,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetPackage, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				setNome({
					value: response.data.data.user.name,
					required: nome.required,
				});
				setUserId({
					value: response.data.data.user.id,
					required: userId.required,
				});
				setCpf({
					value: response.data.data.user.support_user.cpf,
					required: cpf.required,
				});
				setEmail({
					value: response.data.data.user.email,
					required: email.required,
				});
				setDevelopment({
					value: response.data.data.development.name,
					required: development.required
				});
				setDevelopmentId({
					value: response.data.data.development.id,
					required: developmentId.required
				});
				setDescription({
					value: response.data.data.description,
					required: description.required
				});
				setReceivedBy({
					value: response.data.data.received_by,
					required: receivedBy.required
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);
		lockFields(currentFields);

		axios
			.put(
				urlEndpointUpdatePackage,
				{
					user_id: userId.value,
					development_id: developmentId.value,
					received_by: receivedBy.value,
					description: description.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Atualizar  / Encomenda`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ATUALIZAR ENCOMENDA</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DA ENCOMENDA
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Nome completo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNome({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nome.value}
							disabled={true}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="E-mail"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setEmail({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={email.value}
							disabled={true}
							required={email.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CPF"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCpf({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cpf.value}
							required={cpf.required}
							disabled={true}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Empreendimento"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDevelopment({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={development.value}
							required={development.required}
							disabled={true}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Descrição"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDescription({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={description.value}
							required={description.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Recebido Por"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setReceivedBy({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={receivedBy.value}
							required={receivedBy.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/packages'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdatePackage;
