import React from 'react';
import LogoWhite from '../../assets/images/logoWhite.png';
import LogoColor from '../../assets/images/logoColor.png';

type BitLogoProps = {
	styleImage: string;
	width: string;
};

const BitLogo: React.FC<BitLogoProps> = ({ styleImage, width }) => {
	return (
		<img
			className={`${width} block`}
			src={styleImage === 'white' ? LogoWhite : LogoColor}
			alt="BitPlate"
		/>
	);
};

export default BitLogo;
