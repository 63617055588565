import React from 'react';

import BitSideMenu from '../BitSideMenu/BitSideMenu';

type BitPageProps = {
	secondMenu?: any;
};

const BitPage: React.FC<BitPageProps> = ({ secondMenu, children }) => {
	return (
		<div className="w-screen h-screen flex">
			<BitSideMenu />
			{secondMenu ?? ''}
			<main className="flex-1 p-6 overflow-y-auto" id="main">
				{children}
			</main>
		</div>
	);
};

export default BitPage;
