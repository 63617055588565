import React, { useEffect, useState } from 'react';

import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

const NewLojista: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointCreateLojista = `${urlEndpoint}/users/store`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;
	const urlEndpointGetCategories = `${urlEndpoint}/shopkeepers/categories`;

	const token = localStorage.getItem('token');

	const [maskTelephone, setmaskTelephone] = useState([
		'(',
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	]);
	const [horarioTrabalho, setHorarioTrabalho] = useState({
		value: '',
		required: true,
	});
	const [nome, setNome] = useState({
		value: '',
		required: true,
	});
	const [email, setEmail] = useState({
		value: '',
		required: true,
	});
	const [pendente, setPendente] = useState({
		value: '',
		required: false,
	});
	const [categorias, setCategorias] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});
	const [cnpj, setCnpj] = useState({
		value: '',
		required: true,
	});
	const [setor, setSetor] = useState({
		value: '',
		required: false,
	});
	const [estado, setEstado] = useState({
		value: '',
		required: true,
	});
	const [cidade, setCidade] = useState({
		value: '',
		required: true,
	});
	const [logradouro, setLogradouro] = useState({
		value: '',
		required: true,
	});
	const [numero, setNumero] = useState({
		value: '',
		required: true,
	});
	const [bairro, setBairro] = useState({
		value: '',
		required: true,
	});
	const [complemento, setComplemento] = useState({
		value: '',
		required: false,
	});
	const [pontoReferencia, setPontoReferencia] = useState({
		value: '',
		required: false,
	});
	const [cep, setCep] = useState({
		value: '',
		required: true,
	});
	const [telefone, setTelefone] = useState({
		value: '',
		required: true,
	});
	const [descricao, setDescricao] = useState({
		value: '',
		required: false,
	});
	const [plano, setPlano] = useState({
		value: '',
		required: false,
	});
	const [quantidadeVouchers, setQuantidadeVouchers] = useState({
		value: '',
		required: false,
	});
	const [desconto, setDesconto] = useState({
		value: '',
		required: false,
	});
	const [arquivos, setArquivos] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});
	const [senha, setSenha] = useState({
		value: '',
		required: true,
	});
	const [senhaconfirmacao, setSenhaConfirmacao] = useState({
		value: '',
		required: true,
	});
	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const limpaCampos = () => {
		setNome({
			value: '',
			required: nome.required,
		});
		setHorarioTrabalho({
			value: '',
			required: horarioTrabalho.required,
		});
		setEmail({
			value: '',
			required: email.required,
		});
		setCnpj({
			value: '',
			required: cnpj.required,
		});
		setSetor({
			value: '',
			required: setor.required,
		});
		setPendente({
			value: '',
			required: pendente.required,
		});
		setEstado({
			value: '',
			required: estado.required,
		});
		setCidade({
			value: '',
			required: cidade.required,
		});
		setLogradouro({
			value: '',
			required: logradouro.required,
		});
		setNumero({
			value: '',
			required: numero.required,
		});
		setBairro({
			value: '',
			required: bairro.required,
		});
		setComplemento({
			value: '',
			required: complemento.required,
		});
		setPontoReferencia({
			value: '',
			required: pontoReferencia.required,
		});
		setCep({
			value: '',
			required: cep.required,
		});
		setTelefone({
			value: '',
			required: telefone.required,
		});
		setDescricao({
			value: '',
			required: descricao.required,
		});
		setPlano({
			value: '',
			required: plano.required,
		});
		setQuantidadeVouchers({
			value: '',
			required: quantidadeVouchers.required,
		});
		setDesconto({
			value: '',
			required: desconto.required,
		});
		setArquivos({
			value: '',
			required: arquivos.required,
		});
		setSenha({
			value: '',
			required: senha.required,
		});
		setSenhaConfirmacao({
			value: '',
			required: senhaconfirmacao.required,
		});
		setEmpreendimentos({
			value: [],
			required: empreendimentos.required,
		});

		setShowRequiredBallon(false);
	};

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;

				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allEmpreendimentos.map((empreendimento: any) => {
					newValueEmpreendimentos.push({
						value: empreendimento.id,
						label: empreendimento.name,
						selected: false,
					});
					return null;
				});

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});
			});

		axios
			.get(urlEndpointGetCategories, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allCategorias = response.data.data.data;

				const newValueCategorias: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allCategorias.map((empreendimento: any) => {
					newValueCategorias.push({
						value: empreendimento.id,
						label: empreendimento.title,
						selected: false,
					});
					return null;
				});

				setCategorias({
					value: newValueCategorias,
					required: categorias.required,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		if (senha.value !== senhaconfirmacao.value && senha.value.length > 0) {
			toast.error('Erro ao cadastrar. As senhas devem ser iguais.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		} else if (
			senha.value === senhaconfirmacao.value &&
			senha.value.length < 6
		) {
			toast.error(
				'Erro ao cadastrar. A senha deve ter 6 ou mais digitos.',
				{
					position: 'bottom-right',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				},
			);
		} else {
			lockFields(currentFields);

			const entriesDevelopments = empreendimentos.value;
			const valueDevelopments: {
				[key: string]: string | number;
			}[] = [];

			entriesDevelopments.map(empreendimento => {
				if (empreendimento.selected) {
					valueDevelopments.push({
						id: empreendimento.value,
						plan: plano.value,
						number_vouchers: quantidadeVouchers.value,
						discount_percentage: desconto.value,
					});
				}

				return null;
			});

			const entriesCategorias = categorias.value;
			const valueCategorias: {
				[key: string]: string | number;
			}[] = [];

			entriesCategorias.map(categoria => {
				if (categoria.selected) {
					valueCategorias.push({
						id: categoria.value,
					});
				}

				return null;
			});

			axios
				.post(
					urlEndpointCreateLojista,
					{
						type: 2,
						name: nome.value,
						email: email.value,
						phone: telefone.value,
						work_schedule: horarioTrabalho.value,
						cnpj: cnpj.value,
						sector: setor.value,
						address: {
							line_1: logradouro.value,
							line_2: complemento.value,
							number: numero.value,
							complement: pontoReferencia.value,
							neighborhood: bairro.value,
							city: cidade.value,
							state: estado.value,
							zip_code: cep.value,
						},
						categories: valueCategorias,
						development: valueDevelopments,
						description: descricao.value,
						archive: arquivos.value,
						password: senha.value,
						password_confirmation: senhaconfirmacao.value,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
							'Content-Type': 'application/json',
						},
					},
				)
				.then(function (response: typeResponseAxios) {
					if (response.status === 201) {
						toast.success('Cadastro realizado com sucesso!', {
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						});

						limpaCampos();

						unlockFields(currentFields);
					}
				})
				.catch(function (error) {
					const errosServidor = error.response.data.error;
					toast.error(
						'Erro ao cadastrar. Verifique os campos obrigatórios e tente novamente.',
						{
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						},
					);

					if (
						error.response.data.error.email !== undefined &&
						error.response.data.error.email[0] ===
							'The email has already been taken.'
					) {
						toast.error(
							'O e-mail escolhido já está sendo utilizado.',
							{
								position: 'bottom-right',
								autoClose: 3000,
								hideProgressBar: true,
								closeOnClick: false,
								pauseOnHover: true,
								draggable: false,
								progress: undefined,
							},
						);
					}
					if (
						error.response.data.error.password !== undefined &&
						error.response.data.error.password[0] ===
							'The password must be an integer.'
					) {
						toast.error('Senha deve conter somente números.', {
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						});
					}

					unlockFields(currentFields);
				});
		}
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Lojista`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR LOJISTA</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					LOJISTA
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-4">
						<BitInput
							label="Nome"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNome({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nome.value}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Telefone"
							mask={maskTelephone}
							onKeyDown={(e: any) => {
								if (e.which !== 9) {
									if (isTelefoneSP(e.currentTarget.value)) {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									} else {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									}
								}
							}}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTelefone({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={telefone.value}
							required={telefone.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Senha"
							type="password"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setSenha({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={senha.value}
							required={senha.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Confirmar Senha"
							type="password"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setSenhaConfirmacao({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={senhaconfirmacao.value}
							required={senhaconfirmacao.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="E-mail"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setEmail({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={email.value}
							required={email.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Setor"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setSetor({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={setor.value}
							required={setor.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CNPJ"
							mask={[
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'/',
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCnpj({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cnpj.value}
							required={cnpj.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CEP"
							mask={[
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCep({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cep.value}
							required={cep.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Estado"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setEstado({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={estado.value}
							required={estado.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Cidade"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCidade({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cidade.value}
							required={cidade.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Bairro"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setBairro({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={bairro.value}
							required={bairro.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Logradouro"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setLogradouro({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={logradouro.value}
							required={logradouro.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Complemento"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setComplemento({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={complemento.value}
							required={complemento.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Ponto de Referência"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setPontoReferencia({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={pontoReferencia.value}
							required={pontoReferencia.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Número"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNumero({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={numero.value}
							required={numero.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Pendente"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setPendente({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={pendente.value}
							required={pendente.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Horário de trabalho"
							mask={[
								/\d/,
								/\d/,
								':',
								/\d/,
								/\d/,
								' ',
								'-',
								' ',
								/\d/,
								/\d/,
								':',
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setHorarioTrabalho({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={horarioTrabalho.value}
							required={horarioTrabalho.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="mb-4">
						<BitInput
							label="Categoria"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={categorias.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueCategorias: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueCategorias.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setCategorias({
									value: newValueCategorias,
									required: categorias.required,
								});
							}}
						/>
					</div>
					<div className="grid grid-cols-1 gap-4">
						<BitInput
							label="Descrição"
							type="textarea"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDescricao({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={descricao.value}
							required={descricao.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					EMPREENDIMENTOS CADASTRADOS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						<BitInput
							label="Nome do empreendimento"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={empreendimentos.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueEmpreendimentos: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueEmpreendimentos.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setEmpreendimentos({
									value: newValueEmpreendimentos,
									required: empreendimentos.required,
								});
							}}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">3</span>
					PLANO DE PROMOÇÕES
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						<BitInput
							label="Plano"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setPlano({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={plano.value}
							required={plano.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="mb-4">
						<BitInput
							label="Quantidade de vouchers"
							type="number"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setQuantidadeVouchers({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={quantidadeVouchers.value}
							required={quantidadeVouchers.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="mb-4">
						<BitInput
							label="Desconto"
							type="number"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDesconto({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={desconto.value}
							required={desconto.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">4</span>
					IMAGENS DE PROMOÇÕES
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-10">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivoLojista = document.getElementById(
									'arquivoLojista',
								);
								if (inputArquivoLojista !== null) {
									inputArquivoLojista.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivoLojista`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos({
												value: e.target.result,
												required: arquivos.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos.value}
							required={arquivos.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivoLojista'}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/registrations/shopkeepers'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default NewLojista;
