import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

const UpdateDevelopment: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceCheckbox {
		value: {
			[key: string]: {
				value: string | number;
				label: string;
				name: string;
			};
		};
		required: boolean;
	}

	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}

	interface ParamTypes {
		idRegister: string;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdateUserEmpreendimento = `${urlEndpoint}/developments/update/${idRegister}`;
	const urlEndpointGetEmpreendimento = `${urlEndpoint}/developments/${idRegister}`;

	const token = localStorage.getItem('token');

	const [maskTelephone, setmaskTelephone] = useState([
		'(',
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	]);

	const [nomeEmpreendimento, setNomeEmpreendimento] = useState({
		value: '',
		required: true,
	});
	const [estado, setEstado] = useState({
		value: '',
		required: true,
	});
	const [cidade, setCidade] = useState({
		value: '',
		required: true,
	});
	const [logradouro, setLogradouro] = useState({
		value: '',
		required: true,
	});
	const [line2, setLine2] = useState({
		value: '',
		required: true,
	});
	const [numero, setNumero] = useState({
		value: '',
		required: true,
	});
	const [bairro, setBairro] = useState({
		value: '',
		required: true,
	});
	const [complemento, setComplemento] = useState({
		value: '',
		required: false,
	});
	const [cep, setCep] = useState({
		value: '',
		required: true,
	});
	const [telefone, setTelefone] = useState({
		value: '',
		required: true,
	});
	const [descricao, setDescricao] = useState({
		value: '',
		required: false,
	});
	const [arquivos, setArquivos] = useState<interfaceArquivos>({
		value: '',
		required: true,
	});
	const [funcionalidades, setFuncionalidades] = useState<interfaceCheckbox>({
		value: {
			talk_with_gestor: {
				label: 'FALE COM O GESTOR',
				value: 0,
				name: 'talk_with_gestor',
			},
			discount_club: {
				label: 'CLUBE DE DESCONTO',
				value: 0,
				name: 'discount_club',
			},
			my_groups: {
				label: 'MEUS GRUPOS (GRUPOS DE INTERESSE)',
				value: 0,
				name: 'my_groups',
			},
			i_indicate: {
				label: 'EU INDICO',
				value: 0,
				name: 'i_indicate',
			},
			bazar: {
				label: 'BAZAR',
				value: 0,
				name: 'bazar',
			},
			packages: {
				label: 'ENCOMENDAS',
				value: 0,
				name: 'packages',
			},
			common_area_reservations: {
				label: 'RESERVAS ÁREAS COMUNS',
				value: 0,
				name: 'common_area_reservations',
			},
		},
		required: true,
	});
	const [corEmpreendimento, setCorEmpreendimento] = useState({
		value: '',
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		const entriesValueFuncionalidades = Object.entries(
			funcionalidades.value,
		);
		const valueFunctionalities: {
			[key: string]: string | number;
		} = {};

		entriesValueFuncionalidades.map(funcionalidade => {
			valueFunctionalities[funcionalidade[0]] = funcionalidade[1].value;

			return null;
		});

		setShowRequiredBallon(true);

		lockFields(currentFields);

		axios
			.put(
				urlEndpointUpdateUserEmpreendimento,
				{
					name: nomeEmpreendimento.value,
					phone: telefone.value,
					color: corEmpreendimento.value,
					address: {
						line_1: logradouro.value,
						line_2: line2.value,
						number: numero.value,
						complement: complemento.value,
						neighborhood: bairro.value,
						city: cidade.value,
						state: estado.value,
						zip_code: cep.value,
					},
					description: descricao.value,
					functionalities: valueFunctionalities,
					// archive: arquivos.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					setShowRequiredBallon(false);

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;

				if (error.response.data.message.includes('Unsupported Extension.')) {
					toast.error('A imagem deve ser em extensão .JPG, .JPEG ou .PNG!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});
				} else {

					toast.error(
						'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
						{
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						},
					);
				}

				unlockFields(currentFields);
			});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimento, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				setNomeEmpreendimento({
					value: response.data.data.name,
					required: nomeEmpreendimento.required,
				});
				setTelefone({
					value: response.data.data.phone,
					required: telefone.required,
				});
				setCorEmpreendimento({
					value: response.data.data.color,
					required: corEmpreendimento.required,
				});
				setDescricao({
					value: response.data.data.description,
					required: descricao.required,
				});
				setLogradouro({
					value: response.data.data.address_line_1,
					required: logradouro.required,
				});
				setLine2({
					value: response.data.data.address_line_2,
					required: line2.required,
				});
				setNumero({
					value: response.data.data.number,
					required: numero.required,
				});
				setBairro({
					value: response.data.data.neighborhood,
					required: bairro.required,
				});
				setCidade({
					value: response.data.data.city,
					required: cidade.required,
				});
				setEstado({
					value: response.data.data.state,
					required: estado.required,
				});
				setCep({
					value: response.data.data.zip_code,
					required: cep.required,
				});
				setComplemento({
					value: response.data.data.complement,
					required: complemento.required,
				});
				setFuncionalidades({
					value: {
						talk_with_gestor: {
							label: 'FALE COM O GESTOR',
							value:
								response.data.data.functionalities
									.talk_with_gestor,
							name: 'talk_with_gestor',
						},
						discount_club: {
							label: 'CLUBE DE DESCONTO',
							value:
								response.data.data.functionalities
									.discount_club,
							name: 'discount_club',
						},
						my_groups: {
							label: 'MEUS GRUPOS (GRUPOS DE INTERESSE)',
							value: response.data.data.functionalities.my_groups,
							name: 'my_groups',
						},
						i_indicate: {
							label: 'EU INDICO',
							value:
								response.data.data.functionalities.i_indicate,
							name: 'i_indicate',
						},
						bazar: {
							label: 'BAZAR',
							value: response.data.data.functionalities.bazar,
							name: 'bazar',
						},
						packages: {
							label: 'ENCOMENDAS',
							value: response.data.data.functionalities.packages,
							name: 'packages',
						},
						common_area_reservations: {
							label: 'RESERVAS ÁREAS COMUNS',
							value:
								response.data.data.functionalities
									.common_area_reservations,
							name: 'common_area_reservations',
						},
					},
					required: funcionalidades.required,
				});
				setArquivos({
					value: response.data.data.photo,
					required: arquivos.required,
				});
			});
	}, []);

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Empreendimentos`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR EMPREENDIMENTO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					EMPREENDIMENTO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-4">
						<BitInput
							label="Nome do empreendimento"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNomeEmpreendimento({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nomeEmpreendimento.value}
							required={nomeEmpreendimento.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Telefone"
							mask={maskTelephone}
							onKeyDown={(e: any) => {
								if (e.which !== 9) {
									if (isTelefoneSP(e.currentTarget.value)) {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									} else {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									}
								}
							}}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTelefone({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={telefone.value}
							required={telefone.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CEP"
							mask={[
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCep({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cep.value}
							required={cep.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Estado"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setEstado({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={estado.value}
							required={estado.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Cidade"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCidade({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cidade.value}
							required={cidade.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Bairro"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setBairro({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={bairro.value}
							required={bairro.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Logradouro"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setLogradouro({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={logradouro.value}
							required={logradouro.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Número"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNumero({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={numero.value}
							required={numero.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Complemento"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setComplemento({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={complemento.value}
							required={complemento.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="grid grid-cols-1 gap-4">
						<BitInput
							label="Descrição"
							type="textarea"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDescricao({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={descricao.value}
							required={descricao.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					ARQUIVOS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="py-4">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivoEmpreendimento = document.getElementById(
									'arquivoEmpreendimento',
								);
								if (inputArquivoEmpreendimento !== null) {
									inputArquivoEmpreendimento.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivoEmpreendimento`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos({
												value: e.target.result,
												required: arquivos.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos.value}
							required={arquivos.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivoEmpreendimento'}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">3</span>
					FUNCIONALIDADES
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						<BitInput
							label=""
							type="checkbox"
							placeholder=""
							classField=""
							valuesCheckbox={funcionalidades.value}
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const newValueCheckbox = e.currentTarget.checked
									? 1
									: 0;
								const newValueFuncionalidades = funcionalidades;

								newValueFuncionalidades.value[
									e.currentTarget.name
								].value = newValueCheckbox;

								setFuncionalidades(newValueFuncionalidades);
								setNomeEmpreendimento({
									value: nomeEmpreendimento.value,
									required: nomeEmpreendimento.required,
								});
							}}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">4</span>
					CORES DO EMPREENDIMENTO
				</h4>

				<div className="bloco-campos-novo-cadastro" />

				<div className="mb-10" />

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-10">
						<BitInput
							label="Escolha a cor"
							type="colorpicker"
							placeholder="Digite..."
							classField=""
							onChange={(color: { hex: string }) =>
								setCorEmpreendimento({
									value: color.hex,
									required: corEmpreendimento.required,
								})
							}
							value={corEmpreendimento.value}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/registrations/enterprises'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateDevelopment;
