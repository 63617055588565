// @ts-nocheck
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitButton from '../../components/BitButton/BitButton';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';
import iconArquivos from '../../assets/images/icon-arquivos.svg';
import BitInput from '../../components/BitInput/BitInput';

const ResidentsImport: React.FC = () => {
	interface uploadFileIface {
		lastModified: number;
		lastModifiedDate: any;
		name: string;
		size: number;
		type: string;
		webkitRelativePath: any;
	}

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});
	const [retornoImportaXLSX, setRetornoImportaXLSX] = useState(<div />);
	const [uploadedFile, setUploadedFile] = useState<uploadFileIface>();
	const [selectedEmpreendimento, setSelectedEmpreendimento] = useState('0');

	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');
	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointImportaXLSX = `${urlEndpoint}/users/import`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;

	const onChangeHandler = (e: any) => {
		setUploadedFile(e.target.files[0]);
		console.log(uploadedFile);
	};

	const onUploadHandler = (e: any) => {
		setRetornoImportaXLSX(<div />);

		const data = new FormData();
		data.append('development_id', selectedEmpreendimento.toString());
		data.append('file', uploadedFile);

		axios
			.post(urlEndpointImportaXLSX, data, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(function (response: typeResponseAxios) {
				if (
					response.data.message ===
					'Spreadsheet succesfully uploaded!'
				) {
					setRetornoImportaXLSX(
						<div className="border rounded bg-green-100 text-green-700 border-green-500">
							<p>
								O sistema está processando processando a
								planilha, isto pode levar alguns minutos,
								verifique em breve na listagem.
							</p>
						</div>,
					);
				} else {
					setRetornoImportaXLSX(
						<div className="border rounded bg-red-100 text-red-700 border-red-500">
							<p>
								Ocorreu um erro ao tentar importar os
								proprietarios!
							</p>
						</div>,
					);
				}
			})
			.catch(function (error) {
				setRetornoImportaXLSX(
					<div className="border rounded bg-red-100 text-red-700 border-red-500">
						<p>
							Ocorreu um erro ao tentar importar os proprietarios!
						</p>
					</div>,
				);
			});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;

				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				if (typeUser === 'Porteiro') {
					newValueEmpreendimentos.push({
						value: allEmpreendimentos.id,
						label: allEmpreendimentos.name,
						selected: true,
					});
				} else {
					allEmpreendimentos.map((empreendimento: any) => {
						newValueEmpreendimentos.push({
							value: empreendimento.id,
							label: empreendimento.name,
							selected: false,
						});
						return null;
					});
				}

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});
			});
	}, []);

	return (
		<BitPage>
			<BitHeaderFull
				label={`Importação / Proprietários`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">
					IMPORTAÇÃO DE PROPRIETÁRIOS VIA PLANILHA
				</h4>

				<h3 className="pb-8">
					<b>ATENÇÃO: </b>
					{
						'Utilize somente a planilha no formato de exemplo, que pode ser baixada '
					}
					<b>
						<a href="https://painel.clubcombourbano.com/PlanilhaExemplo.xlsx">
							CLICANDO AQUI!
						</a>
					</b>
					, qualquer alteração que seja feita no cabeçalho da mesma
					não conseguiremos processar os dados. Realize alterações
					somente nos dados dos proprietários, inserindo um abaixo do
					outro!
				</h3>

				<BitInput
					label="Selecione o empreendimento ao qual deseja realizar a importação:"
					type="select"
					opcaoTodosSelect={false}
					multipleSelect={false}
					placeholder=""
					valuesSelect={empreendimentos.value}
					classField=""
					onChange={(e: React.FormEvent<HTMLSelectElement>) => {
						const allOptions = Object.entries(
							e.currentTarget.options,
						);

						allOptions.map(option => {
							if (option[1].selected) {
								setSelectedEmpreendimento(option[1].value);
							}
							return null;
						});
					}}
				/>

				<form>
					<div className="custom-file mb-4">
						<p>Selecione a planilha que deseja importar:</p>
						<button
							type="button"
							className="bloco-solta-arquivos flex items-center w-full"
							onClick={(e: any) => {
								const inputArquivoCategoria = document.getElementById(
									'arquivoCategoria',
								);
								if (inputArquivoCategoria !== null) {
									inputArquivoCategoria.click();
								}
							}}
						>
							<div className="icone-solta-arquivos">
								<img
									src={iconArquivos}
									alt=""
									width="26"
									className="mr-4"
								/>
							</div>
							<div className="texto-solta-arquivos text-left">
								<p className="text-lg">
									<b>ADICIONAR XLSX</b>
								</p>
								<p className="text-sm">
									Adicione arquivos até 100MB (Extensões:
									.xls, .xlsx)
								</p>
							</div>
						</button>
						<input
							type="file"
							id="arquivoCategoria"
							name="arquivoCategoria"
							className="hidden"
							onChange={onChangeHandler}
						/>
						{uploadedFile ? (
							<p>
								Arquivo Selecionado:
								{uploadedFile?.name}
							</p>
						) : (
							<></>
						)}
					</div>
				</form>

				<div className="text-center pb-8">{retornoImportaXLSX}</div>

				<div className="text-right">
					<div className="inline-block mr-4">
						<BitLinkButton
							text={'VOLTAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							link={'/registrations/residents'}
						/>
					</div>
					<BitButton
						text={'IMPORTAR XLSX'}
						buttonColor={'#3AABA2'}
						textColor={'#FFFFFF'}
						type={'fit px-10'}
						classesBlockOption={'inline-block'}
						onClick={onUploadHandler}
					/>
				</div>
			</div>
		</BitPage>
	);
};

export default ResidentsImport;
