import React, { useState } from 'react';
import axios from 'axios';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { RouteComponentProps, Link } from 'react-router-dom';

import BitButton from '../../components/BitButton/BitButton';
import BitLogo from '../../components/BitLogo/BitLogo';
import BitInput from '../../components/BitInput/BitInput';

import { accessPagesState, endpointState } from '../../recoil/atoms';
import BitBaloon from '../../components/BitBaloon/BitBaloon';

const SignIn: React.FC<RouteComponentProps> = ({ history }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const { urlEndpoint } = useRecoilValue(endpointState);
	const [accessPages, setAccessPages] = useRecoilState(accessPagesState);
	const urlEndpointLogin = `${urlEndpoint}/login`;

	localStorage.removeItem('user_id');
	localStorage.removeItem('type');
	localStorage.removeItem('token');
	localStorage.removeItem('name');
	localStorage.removeItem('user_photo');
	localStorage.removeItem('development_name');
	localStorage.removeItem('development_id');

	const respostaPermissaoNegada =
		document.getElementById('resposta-permissao-negada') !== null
			? document.getElementById('resposta-permissao-negada')
			: null;

	const respostaLoginIncorreto =
		document.getElementById('resposta-login-incorreto') !== null
			? document.getElementById('resposta-login-incorreto')
			: null;
	const carregandoRespostaLogin =
		document.getElementById('carregando-resposta-login') !== null
			? document.getElementById('carregando-resposta-login')
			: null;

	const elEmailCampoRequired = document.querySelector(
		'.grupo-campo-email .campo-required-alerta',
	);
	const elSenhaCampoRequired = document.querySelector(
		'.grupo-campo-senha .campo-required-alerta',
	);

	type typeResponseAxios = {
		[key: string]: any;
	};

	function showElement(el: HTMLElement | Element | null) {
		if (el !== null) {
			el.classList.remove('hidden');
			el.classList.add('block');
		}
	}

	function hideElement(el: HTMLElement | Element | null) {
		if (el !== null) {
			el.classList.remove('block');
			el.classList.add('hidden');
		}
	}

	const handleLogin = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		hideElement(elSenhaCampoRequired);
		hideElement(elEmailCampoRequired);
		hideElement(carregandoRespostaLogin);
		hideElement(respostaLoginIncorreto);

		if (
			email !== '' &&
			email !== undefined &&
			password !== '' &&
			password !== undefined
		) {
			showElement(carregandoRespostaLogin);

			axios
				.post(urlEndpointLogin, {
					email,
					password,
				})
				.then(function (response: typeResponseAxios) {
					if (
						response.data.data.token !== '' &&
						response.data.data.token !== undefined &&
						response.data.data.name !== '' &&
						response.data.data.name !== undefined &&
						response.data.data.permissions !== '' &&
						response.data.data.permissions !== undefined
					) {
						setPassword('');
						setEmail('');

						if (![1, 4].includes(response.data.data.type_user_id)) {
							hideElement(carregandoRespostaLogin);
							showElement(respostaPermissaoNegada);
							return;
						}
						if (response.data.data.development) {
							localStorage.setItem(
								'development_name',
								response.data.data.development.name,
							);
							localStorage.setItem(
								'development_id',
								response.data.data.development.id,
							);
						}
						localStorage.setItem('token', response.data.data.token);
						localStorage.setItem('name', response.data.data.name);
						localStorage.setItem('type', response.data.data.type);
						localStorage.setItem(
							'user_id',
							response.data.data.user_id,
						);
						localStorage.setItem(
							'user_photo',
							response.data.data.photo,
						);

						const typeUser = localStorage.getItem('type');
						const redirectUserWhenEnter =
							typeUser === 'Administrador' ||
							typeUser === 'Gestor'
								? '/dashboard'
								: '/account/profile';

						setAccessPages({
							permissions: response.data.data.permissions,
						});

						history.push(redirectUserWhenEnter);
					}
				})
				.catch(function (error) {
					console.log(error);

					hideElement(carregandoRespostaLogin);
					showElement(respostaLoginIncorreto);
				});
		} else {
			if (email === '' || email === undefined) {
				showElement(elEmailCampoRequired);
			}

			if (password === '' || password === undefined) {
				showElement(elSenhaCampoRequired);
			}
		}
	};

	return (
		<div className="w-full lg:min-h-screen flex flex-col justify-center items-center p-6 overflow-y-auto">
			<div className="flex mb-12 justify-center content-center">
				<BitLogo width={'w-40'} styleImage={'color'} />
			</div>

			<div className="w-full max-w-md bg-white p-8">
				<BitInput
					label={'E-mail'}
					type={'email'}
					placeholder={'Seu e-mail cadastrado'}
					onChange={(e: React.FormEvent<HTMLInputElement>) =>
						setEmail(e.currentTarget.value)
					}
					onKeyPress={(e: any) => {
						if (e.key === 'Enter') {
							// Get the next input field
							const elSenha: any = document.querySelector(
								`input[name=Senha]`,
							);

							// If found, focus the next field
							if (elSenha !== undefined) {
								elSenha.focus();
							}
						}
					}}
					value={email}
					required={true}
					classField="grupo-campo-email"
				/>

				<BitInput
					label={'Senha'}
					type={'password'}
					placeholder={'Sua senha segura'}
					onChange={(e: React.FormEvent<HTMLInputElement>) =>
						setPassword(e.currentTarget.value)
					}
					onKeyPress={(e: any) => {
						if (e.key === 'Enter') {
							handleLogin(e);
						}
					}}
					value={password}
					required={true}
					classField="grupo-campo-senha"
				/>

				<BitButton
					text={'ENTRAR NO SISTEMA'}
					textColor={'#FFFFFF'}
					onClick={(e: React.FormEvent<HTMLInputElement>) =>
						handleLogin(e)
					}
				/>

				<div
					id="carregando-resposta-login"
					className="w-full text-center mb-4 hidden"
				>
					<i className="fas fa-spinner fa-spin" />
				</div>

				<div
					id="resposta-login-incorreto"
					className="w-full text-center hidden"
				>
					<BitBaloon
						bgColor={'bg-red-100'}
						fontColor={'text-red-700'}
						borderColor={'border-red-500'}
					>
						<p>Credenciais incorretas</p>
					</BitBaloon>
				</div>

				<div
					id="resposta-permissao-negada"
					className="w-full text-center hidden"
				>
					<BitBaloon
						bgColor={'bg-red-100'}
						fontColor={'text-red-700'}
						borderColor={'border-red-500'}
					>
						<p>
							Você não tem permissão para acessar o painel, apenas
							Gestores e Administradores.
						</p>
					</BitBaloon>
				</div>

				<p className="text-center">
					Esqueci minha senha
					<Link className="underline ml-1" to="/esqueci-senha">
						clique aqui
					</Link>
				</p>

				{/* <BitLinkButton link={'/register'} text={'Criar minha conta'} /> */}
			</div>
		</div>
	);
};

export default SignIn;
