import React, { useEffect, useState } from 'react';
import BitInput from '../BitInput/BitInput';

type BitChatSearchTalkProps = {
	onSendSearch: any;
	onClickContato: any;
	sendSearchInput: {
		name: string;
		placeholder: string;
		value: string;
		onChange: any;
		onKeyUp: any;
	};
	contatosProcura: {
		nomeContato: string;
		thumbContato: string;
		ultimoMensagemContato: string;
		ultimoHorarioEnvio: string;
	}[];
};

const BitChatSearchTalk: React.FC<BitChatSearchTalkProps> = ({
	contatosProcura,
	onSendSearch,
	sendSearchInput,
	onClickContato,
}) => {
	const returnMessages = (
		message: BitChatSearchTalkProps['contatosProcura'][0],
		index: number,
	) => {
		return (
			<button
				type="button"
				onClick={() => {
					onClickContato(index);
				}}
				className={`my-8 bloco-procura-contato-info`}
			>
				<div className="thumbnail-procura-contato-info">
					<img src={message.thumbContato} alt="" />
				</div>
				<div className="nome-mensagem-procura-contato-info">
					<p className="nome-procura-contato-info">
						{message.nomeContato}
					</p>
					<p className="mensagem-procura-contato-info">
						{message.ultimoMensagemContato}
					</p>
				</div>
				<div className="horario-procura-contato-info">
					<p>{message.ultimoHorarioEnvio}</p>
				</div>
			</button>
		);
	};

	return (
		<div className="bloco-procura-mensagens">
			<div className="procura-mensagens">
				<BitInput
					type={'text'}
					label={''}
					name={sendSearchInput.name}
					placeholder={sendSearchInput.placeholder}
					value={sendSearchInput.value}
					onChange={sendSearchInput.onChange}
					onKeyUp={sendSearchInput.onKeyUp}
				/>
				<button
					type="button"
					className="btn-search"
					onClick={onSendSearch}
				>
					<i className="fas fa-search" />
				</button>
			</div>
			<div className="todos-contatos-procura mt-3 px-6">
				{contatosProcura.length > 0
					?
						contatosProcura.map(returnMessages)
					:
						<p>Nenhum chat foi encontrado.</p>
				}
			</div>
		</div>
	);
};

export default BitChatSearchTalk;
