import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const UpdateReservations: React.FC = () => {
	Moment.locale('pt-br');

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdate = `${urlEndpoint}/reservations/update/${idRegister}`;
	const urlEndpointGet = `${urlEndpoint}/reservations/${idRegister}`;

	const token = localStorage.getItem('token');

	const [nome, setNome] = useState({
		value: '',
		id: 0,
		required: true,
	});
	const [development, setDevelopment] = useState({
		value: '',
		id: 0,
		required: true,
	});
	const [commonArea, setCommonArea] = useState({
		value: '',
		id: 0,
		required: true,
	});
	const [scheduleDate, setScheduleDate] = useState({
		value: '',
		required: true,
	});
	const [scheduleTime, setScheduleTime] = useState({
		value: '',
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGet, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				setNome({
					value: response.data.data.user.name,
					id: response.data.data.user.id,
					required: nome.required,
				});
				setDevelopment({
					value: response.data.data.development.name,
					id: response.data.data.development.id,
					required: development.required,
				});
				setCommonArea({
					value: response.data.data.common_area.name,
					id: response.data.data.common_area.id,
					required: commonArea.required,
				});
				setScheduleDate({
					value: Moment(response.data.data.scheduling_date).format(
						'DD/MM/YYYY',
					),
					required: scheduleDate.required,
				});
				setScheduleTime({
					value: Moment(
						response.data.data.scheduling_time,
						'HH:mm:ss',
					).format('HH:mm'),
					required: scheduleTime.required,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);
		lockFields(currentFields);

		axios
			.put(
				urlEndpointUpdate,
				{
					common_area_id: commonArea.id,
					development_id: development.id,
					resident_id: nome.id,
					scheduling_date: Moment(
						scheduleDate.value,
						'DD/MM/YYYY',
					).format('YYYY-MM-DD'),
					scheduling_time: scheduleTime.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Atualizar  / Reservas`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ATUALIZAR RESERVA</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS RESERVA
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-1 gap-4 mb-5">
						<BitInput
							label="Área comum"
							type="select
							multipleSelect={true}"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							value={commonArea.value}
							// required={commonArea.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Empreendimento"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							value={development.value}
							required={development.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
						<BitInput
							label="Nome completo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							value={nome.value}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
							disabled={true}
						/>
						<BitInput
							disabled={true}
							label="Data do agendamento"
							mask={[
								/\d/,
								/\d/,
								'/',
								/\d/,
								/\d/,
								'/',
								/\d/,
								/\d/,
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setScheduleDate({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={scheduleDate.value}
							required={scheduleDate.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							disabled={true}
							label="Horário do agendamento"
							type="maskedinput"
							mask={[/\d/, /\d/, ':', /\d/, /\d/]}
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setScheduleTime({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={scheduleTime.value}
							required={scheduleTime.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/reservas'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateReservations;
