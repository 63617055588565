import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const NewReserva: React.FC = () => {
	Moment.locale('pt-br');

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;
	const urlEndpointGetAreaComum = `${urlEndpoint}/reservations/areas`;
	const urlEndpointCreateReservation = `${urlEndpoint}/reservations/store`;
	const urlEndpointGetMorador = `${urlEndpoint}/users`;
	const urlEndpointGetTimes = `${urlEndpoint}/reservations/listing`;
	const developmentName = localStorage.getItem('development_name');
	const urlEndpointGetUsers = `${urlEndpoint}/users?type=3&development=${developmentName}`;

	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');

	const [selectedUser, setSelectedUser] = useState(0);
	const [user, setUser] = useState({
		value: '',
		required: false,
	});
	const [users, setUsers] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});
	const [
		horarioAgendamentoDisabled,
		setHorarioAgendamentoDisabled,
	] = useState({
		value: true,
		required: true,
	});
	const [
		horarioAgendamento,
		setHorarioAgendamento,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});
	const [dataAgendamento, setDataAgendamento] = useState({
		value: '',
		required: true,
	});
	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});
	const [areaComum, setAreaComum] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});
	const [nomeMorador, setNomeMorador] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const limpaCampos = () => {
		setHorarioAgendamento({
			value: [],
			required: true,
		});
		setDataAgendamento({
			value: '',
			required: true,
		});

		setShowRequiredBallon(false);
	};

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;

				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				if (typeUser === 'Porteiro') {
					newValueEmpreendimentos.push({
						value: allEmpreendimentos.id,
						label: allEmpreendimentos.name,
						selected: true,
					});
				} else {
					allEmpreendimentos.map((empreendimento: any) => {
						newValueEmpreendimentos.push({
							value: empreendimento.id,
							label: empreendimento.name,
							selected: false,
						});
						return null;
					});
				}

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});
			});

		axios
			.get(urlEndpointGetAreaComum, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allAreaComum = response.data.data.data;

				const newValueAreaComum: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allAreaComum.map((areaComumvalue: any) => {
					newValueAreaComum.push({
						value: areaComumvalue.id,
						label: areaComumvalue.name,
						selected: false,
					});
					return null;
				});

				setAreaComum({
					value: newValueAreaComum,
					required: areaComum.required,
				});
			});

		axios
			.get(urlEndpointGetMorador, {
				params: {
					type: 3,
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allMorador = response.data.data.data;

				const newValueMorador: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allMorador.map((Moradorvalue: any) => {
					newValueMorador.push({
						value: Moradorvalue.id,
						label: Moradorvalue.name,
						selected: false,
					});
					return null;
				});

				setNomeMorador({
					value: newValueMorador,
					required: nomeMorador.required,
				});
			});
	}, []);

	const handleLoadTimes = () => {
		let firstSelectedAreaComum = '';

		areaComum.value.forEach(option => {
			if (option.selected && firstSelectedAreaComum === '') {
				firstSelectedAreaComum = option.label;
			}
		});

		axios
			.get(urlEndpointGetTimes, {
				params: {
					date: Moment(dataAgendamento.value, 'DD/MM/YYYY').format(
						'YYYY-MM-DD',
					),
					area: firstSelectedAreaComum,
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res: any) => {
				const responseData = res.data.data;
				const times: any[] = [];

				responseData.available_times.forEach((time: any) => {
					times.push({
						value: time,
						label: time,
						selected: false,
					});
				});

				setHorarioAgendamento({
					value: times,
					required: true,
				});
				setHorarioAgendamentoDisabled({
					value: false,
					required: true,
				});
			});
	};

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		lockFields(currentFields);

		const entriesDevelopments = empreendimentos.value;
		const valueDevelopments: {
			[key: string]: string | number;
		}[] = [];

		entriesDevelopments.map(empreendimento => {
			if (empreendimento.selected) {
				valueDevelopments.push({
					id: empreendimento.value,
				});
			}

			return null;
		});

		const entriesAreaComum = areaComum.value;
		const valueAreaComum: {
			[key: string]: string | number;
		}[] = [];

		entriesAreaComum.map(areaC => {
			if (areaC.selected) {
				valueAreaComum.push({
					id: areaC.value,
				});
			}

			return null;
		});

		const entriesNomeMorador = nomeMorador.value;
		const valueNomeMorador: {
			[key: string]: string | number;
		}[] = [];

		entriesNomeMorador.map(nomeMorad => {
			if (nomeMorad.selected) {
				valueNomeMorador.push({
					id: nomeMorad.value,
				});
			}

			return null;
		});

		const scheduleHour = horarioAgendamento.value
			.filter((hour: any) => {
				return hour.selected === true;
			})
			.map(obj => {
				return obj.value.toString();
			});

		axios
			.post(
				urlEndpointCreateReservation,
				{
					resident_id:
						valueNomeMorador[0] !== undefined
							? valueNomeMorador[0].id
							: null,
					development_id:
						valueDevelopments[0] !== undefined
							? valueDevelopments[0].id
							: null,
					common_area_id:
						valueAreaComum[0] !== undefined
							? valueAreaComum[0].id
							: null,
					scheduling_date: Moment(
						dataAgendamento.value,
						'DD/MM/YYYY',
					).format('YYYY-MM-DD'),
					scheduling_time: scheduleHour[0],
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 201) {
					toast.success('Cadastro realizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					limpaCampos();

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao cadastrar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};

	const handleGetUsers = async (name: string) => {
		axios
			.get(`${urlEndpointGetUsers}&name=${name}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;

				const newValueUsers: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allEmpreendimentos.map((empreendimento: any) => {
					const isSelected = false;

					newValueUsers.push({
						value: empreendimento.id,
						label: empreendimento.name,
						selected: isSelected,
					});
					return null;
				});

				setUsers({
					value: newValueUsers,
					required: users.required,
				});
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Reserva`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR RESERVA</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					RESERVA
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						<BitInput
							label="Nome do empreendimento"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={empreendimentos.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueEmpreendimentos: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueEmpreendimentos.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setEmpreendimentos({
									value: newValueEmpreendimentos,
									required: empreendimentos.required,
								});
							}}
						/>
					</div>
					<div className="mb-4">
						<BitInput
							label="Área comum"
							type="select"
							multipleSelect={false}
							placeholder=""
							valuesSelect={areaComum.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueAreaComum: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueAreaComum.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setAreaComum({
									value: newValueAreaComum,
									required: areaComum.required,
								});
							}}
						/>
					</div>
					<div className="grid grid-cols-1 gap-4 mb-10">
						<BitInput
							label="Busque pelo proprietário"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								setUser({
									value: e.currentTarget.value,
									required: false,
								});
								handleGetUsers(e.currentTarget.value);
							}}
							value={user.value}
							required={user.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<p>
						<b>Obs: </b>
						{
							'Assim que digitar o nome do proprietário no campo acima, o sistema fará uma busca e retornará nomes relacionados. Selecione o mesmo na lista abaixo:'
						}
					</p>
					<br />
					<div className="mb-8">
						<BitInput
							label="Selecione o proprietário"
							type="select"
							multipleSelect={false}
							placeholder=""
							valuesSelect={users.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								setSelectedUser(
									Number(
										e.currentTarget.selectedOptions[0]
											.value,
									),
								);
							}}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Data do agendamento"
							mask={[
								/\d/,
								/\d/,
								'/',
								/\d/,
								/\d/,
								'/',
								/\d/,
								/\d/,
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							maxLength={10}
							onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
								handleLoadTimes();
							}}
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDataAgendamento({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={dataAgendamento.value}
							required={dataAgendamento.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							disabled={horarioAgendamentoDisabled.value}
							label="Horário do agendamento"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={horarioAgendamento.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueNomeMorador: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueNomeMorador.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setHorarioAgendamento({
									value: newValueNomeMorador,
									required: nomeMorador.required,
								});
							}}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/reservas'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default NewReserva;
