import { randomBytes } from 'crypto';
import React from 'react';

type BitInputDescriptionProps = {
	label: string;
	type: string;
	placeholder: string;
	description: string;
	onChange?: any;
	value?: string;
	required?: boolean;
	autoComplete?: string;
};

const BitInputDescription: React.FC<BitInputDescriptionProps> = ({
	label,
	type,
	placeholder,
	description,
	onChange,
	value,
	required,
	autoComplete,
}) => {
	const defaultLabel = 'Sua Label';

	return (
		<div className="field">
			<label htmlFor={label ?? defaultLabel + randomBytes(2)}>
				{label ?? defaultLabel}
			</label>
			<span className="-mt-2 mb-1 opacity-50 text-sm block">
				{description ?? 'Sua descrição.'}
			</span>
			<input
				type={type ?? 'text'}
				name={label ?? defaultLabel + randomBytes(2)}
				className="input"
				placeholder={placeholder ?? 'Seu Placeholder'}
				onChange={onChange}
				value={value ?? ''}
				required={required ?? false}
				autoComplete={autoComplete}
			/>
		</div>
	);
};

export default BitInputDescription;
