// @ts-nocheck
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitButton from '../../components/BitButton/BitButton';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';
import BitInput from '../../components/BitInput/BitInput';

const ResidentsExport: React.FC = () => {
	interface uploadFileIface {
		lastModified: number;
		lastModifiedDate: any;
		name: string;
		size: number;
		type: string;
		webkitRelativePath: any;
	}

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});
	const [retornoExportaXLSX, setRetornoExportaXLSX] = useState(<div />);
	const [selectedEmpreendimento, setSelectedEmpreendimento] = useState(
		'all_developments',
	);

	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');
	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointExportaXLSX = `${urlEndpoint}/users/export`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;

	const onExportHandler = (e: any) => {
		setRetornoExportaXLSX(<div />);

		axios
			.post(urlEndpointExportaXLSX, {
					development_id: selectedEmpreendimento,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
				},
			})
			.then(function (response: typeResponseAxios) {
				if (
					response.data.message ===
					'Spreadsheet succesfully sended!'
				) {
					setRetornoExportaXLSX(
						<div className="border rounded bg-green-100 text-green-700 border-green-500">
							<p>
								O sistema está processando sua solicitação de
								exportação, isto pode levar alguns minutos mas,
								não se preocupe, iremos enviar a planilha para
								seu e-mail cadastrado em nossa base de dados.
							</p>
						</div>,
					);
				} else {
					setRetornoExportaXLSX(
						<div className="border rounded bg-red-100 text-red-700 border-red-500">
							<p>
								Ocorreu um erro ao tentar exportar os
								proprietarios!
							</p>
						</div>,
					);
				}
			})
			.catch(function (error) {
				setRetornoExportaXLSX(
					<div className="border rounded bg-red-100 text-red-700 border-red-500">
						<p>
							Ocorreu um erro ao tentar exportar os proprietarios!
						</p>
					</div>,
				);
			});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;
				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				if (typeUser === 'Porteiro') {
					newValueEmpreendimentos.push({
						value: allEmpreendimentos.id,
						label: allEmpreendimentos.name,
						selected: true,
					});
				} else {
					allEmpreendimentos.map((empreendimento: any) => {
						newValueEmpreendimentos.push({
							value: empreendimento.id,
							label: empreendimento.name,
							selected: false,
						});
						return null;
					});
				}

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});
			});
	}, []);

	return (
		<BitPage>
			<BitHeaderFull
				label={`Exportação / Proprietários`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">
					EXPORTAÇÃO DE PROPRIETÁRIOS PARA PLANILHA
				</h4>

				<h3 className="pb-8">
					<b>ATENÇÃO: </b>
					{
						'Para exportar para uma planilha com todos os usuários do sistema, deixe selecionada a opção todos, caso contrário selecione o empreendimento ao qual deseja exportar uma planilha. '
					}
					<b>Lembre-se:</b>
					{
						' Iremos enviar a planilha com os dados da exportação ao e-mail cadastrado no seu perfil.'
					}
				</h3>

				<BitInput
					label="Selecione o empreendimento ao qual deseja realizar a exportação:"
					type="select"
					opcaoTodosSelect={true}
					multipleSelect={false}
					placeholder=""
					valuesSelect={empreendimentos.value}
					classField=""
					onChange={(e: React.FormEvent<HTMLSelectElement>) => {
						const allOptions = Object.entries(
							e.currentTarget.options,
						);

						allOptions.map(option => {
							if (option[1].selected) {
								setSelectedEmpreendimento(option[1].value);
							}
							return null;
						});
					}}
				/>

				<div className="text-center pb-8">{retornoExportaXLSX}</div>

				<div className="text-right">
					<div className="inline-block mr-4">
						<BitLinkButton
							text={'VOLTAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							link={'/registrations/residents'}
						/>
					</div>
					<BitButton
						text={'EXPORTAR XLSX'}
						buttonColor={'#3AABA2'}
						textColor={'#FFFFFF'}
						type={'fit px-10'}
						classesBlockOption={'inline-block'}
						onClick={onExportHandler}
					/>
				</div>
			</div>
		</BitPage>
	);
};

export default ResidentsExport;
