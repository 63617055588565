import React from 'react';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

type BitMenuIconProps = {
	label: string;
	link: string;
	icon: string;
	isSVG?: boolean;
	pathName: string;
};

const BitMenuIcon: React.FC<BitMenuIconProps> = ({
	label,
	link,
	icon,
	isSVG = false,
	pathName,
}) => {
	let htmlIcone = <i className={icon ?? 'fas fa-home'} />;

	if (isSVG) {
		htmlIcone = <img src={icon} width="30" alt="" />;
	}

	return (
		<Tippy content={label ?? 'Icone'}>
			<Link
				className={
					pathName && pathName.includes(link)
						? 'menu__item menu__item--actived'
						: 'menu__item'
				}
				to={link ?? '/'}
			>
				{htmlIcone}
			</Link>
		</Tippy>
	);
};

export default BitMenuIcon;
