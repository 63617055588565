import React, { useState } from 'react';

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

const NewEmpreendimento: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceCheckbox {
		value: {
			[key: string]: {
				value: string | number;
				label: string;
				name: string;
			};
		};
		required: boolean;
	}

	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointCreateEmpreendimento = `${urlEndpoint}/developments/store`;

	const token = localStorage.getItem('token');

	const [maskTelephone, setmaskTelephone] = useState([
		'(',
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	]);
	const [nomeEmpreendimento, setNomeEmpreendimento] = useState({
		value: '',
		required: true,
	});
	const [pendente, setPendente] = useState({
		value: '',
		required: false,
	});
	const [estado, setEstado] = useState({
		value: '',
		required: true,
	});
	const [cidade, setCidade] = useState({
		value: '',
		required: true,
	});
	const [logradouro, setLogradouro] = useState({
		value: '',
		required: true,
	});
	const [numero, setNumero] = useState({
		value: '',
		required: true,
	});
	const [bairro, setBairro] = useState({
		value: '',
		required: true,
	});
	const [complemento, setComplemento] = useState({
		value: '',
		required: false,
	});
	const [cep, setCep] = useState({
		value: '',
		required: true,
	});
	const [telefone, setTelefone] = useState({
		value: '',
		required: true,
	});
	const [descricao, setDescricao] = useState({
		value: '',
		required: false,
	});
	const [arquivos, setArquivos] = useState<interfaceArquivos>({
		value: '',
		required: true,
	});
	const [funcionalidades, setFuncionalidades] = useState<interfaceCheckbox>({
		value: {
			talk_with_gestor: {
				label: 'FALE COM O GESTOR',
				value: 0,
				name: 'talk_with_gestor',
			},
			discount_club: {
				label: 'CLUBE DE DESCONTO',
				value: 0,
				name: 'discount_club',
			},
			my_groups: {
				label: 'MEUS GRUPOS (GRUPOS DE INTERESSE)',
				value: 0,
				name: 'my_groups',
			},
			i_indicate: {
				label: 'EU INDICO',
				value: 0,
				name: 'i_indicate',
			},
			bazar: {
				label: 'BAZAR',
				value: 0,
				name: 'bazar',
			},
			packages: {
				label: 'ENCOMENDAS',
				value: 0,
				name: 'packages',
			},
			common_area_reservations: {
				label: 'RESERVAS ÁREAS COMUNS',
				value: 0,
				name: 'common_area_reservations',
			},
		},
		required: true,
	});
	const [corEmpreendimento, setCorEmpreendimento] = useState({
		value: '',
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const limpaCampos = () => {
		setNomeEmpreendimento({
			value: '',
			required: nomeEmpreendimento.required,
		});
		setPendente({
			value: '',
			required: pendente.required,
		});
		setEstado({
			value: '',
			required: estado.required,
		});
		setCidade({
			value: '',
			required: cidade.required,
		});
		setLogradouro({
			value: '',
			required: logradouro.required,
		});
		setNumero({
			value: '',
			required: numero.required,
		});
		setBairro({
			value: '',
			required: bairro.required,
		});
		setComplemento({
			value: '',
			required: complemento.required,
		});
		setCep({
			value: '',
			required: cep.required,
		});
		setTelefone({
			value: '',
			required: telefone.required,
		});
		setDescricao({
			value: '',
			required: descricao.required,
		});
		setArquivos({
			value: '',
			required: arquivos.required,
		});
		setFuncionalidades({
			value: {
				talk_with_gestor: {
					label: 'FALE COM O GESTOR',
					value: 0,
					name: 'talk_with_gestor',
				},
				discount_club: {
					label: 'CLUBE DE DESCONTO',
					value: 0,
					name: 'discount_club',
				},
				my_groups: {
					label: 'MEUS GRUPOS (GRUPOS DE INTERESSE)',
					value: 0,
					name: 'my_groups',
				},
				i_indicate: {
					label: 'EU INDICO',
					value: 0,
					name: 'i_indicate',
				},
				bazar: {
					label: 'BAZAR',
					value: 0,
					name: 'bazar',
				},
				packages: {
					label: 'ENCOMENDAS',
					value: 0,
					name: 'packages',
				},
				common_area_reservations: {
					label: 'RESERVAS ÁREAS COMUNS',
					value: 0,
					name: 'common_area_reservations',
				},
			},
			required: funcionalidades.required,
		});
		setCorEmpreendimento({
			value: '',
			required: corEmpreendimento.required,
		});

		setShowRequiredBallon(false);
	};

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		const entriesValueFuncionalidades = Object.entries(
			funcionalidades.value,
		);
		const valueFunctionalities: {
			[key: string]: string | number;
		} = {};

		entriesValueFuncionalidades.map(funcionalidade => {
			valueFunctionalities[funcionalidade[0]] = funcionalidade[1].value;

			return null;
		});

		setShowRequiredBallon(true);

		lockFields(currentFields);

		axios
			.post(
				urlEndpointCreateEmpreendimento,
				{
					name: nomeEmpreendimento.value,
					phone: telefone.value,
					color: corEmpreendimento.value,
					address: {
						line_1: logradouro.value,
						line_2: '',
						number: numero.value,
						complement: complemento.value,
						neighborhood: bairro.value,
						city: cidade.value,
						state: estado.value,
						zip_code: cep.value,
					},
					description: descricao.value,
					functionalities: valueFunctionalities,
					archive: arquivos.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 201) {
					toast.success('Cadastro realizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					limpaCampos();

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;

				if (error.response.data.message.includes('Unsupported Extension.')) {
					toast.error('A imagem deve ser em extensão .JPG, .JPEG ou .PNG!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});
				} else {


					toast.error(
						'Erro ao cadastrar. Verifique os campos obrigatórios e tente novamente.',
						{
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						},
					);
				}

				unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Empreendimentos`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR EMPREENDIMENTO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					EMPREENDIMENTO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-4">
						<BitInput
							label="Nome do empreendimento"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNomeEmpreendimento({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nomeEmpreendimento.value}
							required={nomeEmpreendimento.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Telefone"
							mask={maskTelephone}
							onKeyDown={(e: any) => {
								if (e.which !== 9) {
									if (isTelefoneSP(e.currentTarget.value)) {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									} else {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									}
								}
							}}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTelefone({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={telefone.value}
							required={telefone.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CEP"
							mask={[
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCep({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cep.value}
							required={cep.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Estado"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setEstado({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={estado.value}
							required={estado.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Cidade"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCidade({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cidade.value}
							required={cidade.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Bairro"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setBairro({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={bairro.value}
							required={bairro.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Logradouro"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setLogradouro({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={logradouro.value}
							required={logradouro.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Número"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNumero({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={numero.value}
							required={numero.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Complemento"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setComplemento({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={complemento.value}
							required={complemento.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Pendente"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setPendente({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={pendente.value}
							required={pendente.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="grid grid-cols-1 gap-4">
						<BitInput
							label="Descrição"
							type="textarea"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDescricao({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={descricao.value}
							required={descricao.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					LOGO DO EMPREENDIMENTO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="py-4">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivoEmpreendimento = document.getElementById(
									'arquivoEmpreendimento',
								);
								if (inputArquivoEmpreendimento !== null) {
									inputArquivoEmpreendimento.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivoEmpreendimento`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos({
												value: e.target.result,
												required: arquivos.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos.value}
							required={arquivos.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivoEmpreendimento'}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">3</span>
					FUNCIONALIDADES
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						<BitInput
							label=""
							type="checkbox"
							placeholder=""
							classField=""
							valuesCheckbox={funcionalidades.value}
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const newValueCheckbox = e.currentTarget.checked
									? 1
									: 0;
								const newValueFuncionalidades = funcionalidades;

								newValueFuncionalidades.value[
									e.currentTarget.name
								].value = newValueCheckbox;

								setFuncionalidades(newValueFuncionalidades);
								setNomeEmpreendimento({
									value: nomeEmpreendimento.value,
									required: nomeEmpreendimento.required,
								});
							}}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">4</span>
					CORES DO EMPREENDIMENTO
				</h4>

				<div className="bloco-campos-novo-cadastro" />

				<div className="mb-10" />

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-10">
						<BitInput
							label="Escolha a cor"
							type="colorpicker"
							placeholder="Digite..."
							classField=""
							onChange={(color: { hex: string }) =>
								setCorEmpreendimento({
									value: color.hex,
									required: corEmpreendimento.required,
								})
							}
							value={corEmpreendimento.value}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/registrations/enterprises'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default NewEmpreendimento;
