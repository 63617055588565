import React from 'react';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

const BitLogout: React.FC = () => {

	const handleLogout = async () => {
		await localStorage.removeItem('name');
		await localStorage.removeItem('token');
	}

	return (
		<Tippy content="Sair do Painel" placement="right">
			<Link className="text-white text-xl" to={'/login'} onClick={() => handleLogout()}>
				<i className="fas fa-sign-out-alt" />
			</Link>
		</Tippy>
	);
};

export default BitLogout;
