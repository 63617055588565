import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';

import { endpointState } from '../../recoil/atoms';
import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

const NewShortcut: React.FC = () => {
	interface ParamTypes {
		idRegister: string;
	}

	const { idRegister } = useParams<ParamTypes>();

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	interface interfaceNewTags {
		inputValue: string;
		value: any[];
		required: boolean;
	}

	const createOption = (label: string) => ({
		label,
		value: label,
	});

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointCreateOrUpdateFacilidade = idRegister
		? `${urlEndpoint}/shortcuts/update/${idRegister}`
		: `${urlEndpoint}/shortcuts/store`;

	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;

	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');

	const [maskTelephone, setmaskTelephone] = useState([
		'(',
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	]);

	const [name, setName] = useState({
		value: '',
		required: true,
	});
	const [link, setLink] = useState({
		value: '',
		required: false,
	});

	const [phone, setPhone] = useState({
		value: '',
		required: false,
	});
	const [archive, setArquivos] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});

	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});

	const [shotcuts, setShortcuts] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const limpaCampos = () => {
		setName({
			value: '',
			required: name.required,
		});
		setPhone({
			value: '',
			required: phone.required,
		});
		setArquivos({
			value: '',
			required: archive.required,
		});
		setLink({
			value: '',
			required: archive.required,
		});

		setShowRequiredBallon(false);
	};

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	const getToUpdateShortcut = () =>
		new Promise((resolve, reject) => {
			if (!idRegister) resolve([]);
			else
				axios
					.get(`${urlEndpoint}/shortcuts/${idRegister}`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then(function (response: typeResponseAxios) {
						setName({
							value: response.data.data.name,
							required: name.required,
						});
						setLink({
							value: response.data.data.link,
							required: link.required,
						});
						setPhone({
							value: response.data.data.phone,
							required: phone.required,
						});
						setArquivos({
							value: response.data.data.photo,
							required: archive.required,
						});

						resolve(response.data.data.developments);
					})
					.catch(_ => resolve([]));
		});

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(async function (response: typeResponseAxios) {
				const allEmpreendimentos = Array.isArray(
					response.data.data.data,
				)
					? response.data.data.data
					: [response.data.data.data];

				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				if (typeUser === 'Porteiro') {
					newValueEmpreendimentos.push({
						value: allEmpreendimentos[0].id,
						label: allEmpreendimentos[0].name,
						selected: true,
					});
				} else {
					allEmpreendimentos.map((empreendimento: any) => {
						const isSelected = false;

						newValueEmpreendimentos.push({
							value: empreendimento.id,
							label: empreendimento.name,
							selected: isSelected,
						});
						return null;
					});
				}

				type NewType = [];

				const developmentsSelected = (await getToUpdateShortcut()) as NewType;

				if (developmentsSelected && developmentsSelected.length) {
					// iterate in developmentsSelected and set selected true in newValueEmpreendimentos to same occurency
					newValueEmpreendimentos.map((el: any) => {
						const existDevelopment = developmentsSelected.find(
							(development: any) => {
								return development.id === el.value;
							},
						);

						if (existDevelopment) {
							// eslint-disable-next-line no-param-reassign
							el.selected = true;
						}

						return el;
					});
				}

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		lockFields(currentFields);

		const entriesDevelopments = empreendimentos.value;
		const valueDevelopments: {
			[key: string]: string | number;
		}[] = [];

		entriesDevelopments.map(empreendimento => {
			if (empreendimento.selected) {
				valueDevelopments.push({
					id: empreendimento.value,
				});
			}

			return null;
		});

		const restMethod = idRegister ? axios.put : axios.post;

		restMethod(
				urlEndpointCreateOrUpdateFacilidade,
				{
					name: name.value,
					phone: phone.value,
					archive: archive.value,
					developments: valueDevelopments,
					link: link.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 201) {
					toast.success('Cadastro realizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					limpaCampos();

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao cadastrar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Facilidade`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR ATALHO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DA ATALHO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-4">
						<BitInput
							label="NOME"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setName({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={name.value}
							required={name.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Telefone"
							type="text"
							placeholder="Apenas números..."
							// mask={maskTelephone}
							// onKeyDown={(e: any) => {
							// 	if (e.which !== 9) {
							// 		if (isTelefoneSP(e.currentTarget.value)) {
							// 			setmaskTelephone([
							// 				'(',
							// 				/\d/,
							// 				/\d/,
							// 				')',
							// 				' ',
							// 				/\d/,
							// 				' ',
							// 				/\d/,
							// 				/\d/,
							// 				/\d/,
							// 				/\d/,
							// 				'-',
							// 				/\d/,
							// 				/\d/,
							// 				/\d/,
							// 				/\d/,
							// 			]);
							// 		} else {
							// 			setmaskTelephone([
							// 				'(',
							// 				/\d/,
							// 				/\d/,
							// 				')',
							// 				' ',
							// 				/\d/,
							// 				/\d/,
							// 				/\d/,
							// 				/\d/,
							// 				'-',
							// 				/\d/,
							// 				/\d/,
							// 				/\d/,
							// 				/\d/,
							// 			]);
							// 		}
							// 	}
							// }}
							// type="maskedinput"
							// placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setPhone({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={phone.value}
							required={phone.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					LINK
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-4">
						<BitInput
							label="Link"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setLink({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={link.value}
							required={link.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">3</span>
					EMPREENDIMENTOS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label="Nome do empreendimento"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={empreendimentos.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueEmpreendimentos: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueEmpreendimentos.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setEmpreendimentos({
									value: newValueEmpreendimentos,
									required: empreendimentos.required,
								});
							}}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">4</span>
					INFORMAÇÕES EXTRAS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-10">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivoFacilidade = document.getElementById(
									'arquivoFacilidade',
								);
								if (inputArquivoFacilidade !== null) {
									inputArquivoFacilidade.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivoFacilidade`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos({
												value: e.target.result,
												required: archive.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={archive.value}
							required={archive.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivoFacilidade'}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/shortcuts'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default NewShortcut;
