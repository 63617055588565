import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const UpdateDesconto: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	interface interfaceAnunciantes {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdateDesconto = `${urlEndpoint}/vouchers/update/${idRegister}`;
	const urlEndpointGetUsuarios = `${urlEndpoint}/users`;
	const urlEndpointGetVouchers = `${urlEndpoint}/vouchers/${idRegister}`;
	const urlEndpointGetCategories = `${urlEndpoint}/shopkeepers/categories`;

	const token = localStorage.getItem('token');

	const [titulo, setTitulo] = useState({
		value: '',
		required: true,
	});
	const [categorias, setCategorias] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});
	const [cupomDesconto, setCupomDesconto] = useState({
		value: '',
		required: true,
	});
	const [anunciantes, setAnunciantes] = useState<interfaceAnunciantes>({
		value: [],
		required: true,
	});
	const [arquivos, setArquivos] = useState<interfaceArquivos>({
		value: '',
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetVouchers, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				setTitulo({
					value: response.data.data.title,
					required: titulo.required,
				});
				setCupomDesconto({
					value: response.data.data.voucher,
					required: cupomDesconto.required,
				});
				setArquivos({
					value: response.data.data.photo,
					required: arquivos.required,
				});

				axios
					.get(urlEndpointGetUsuarios, {
						params: {
							type: 2,
						},
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then(function (responseGetUsuarios: typeResponseAxios) {
						const allAnunciantes =
							responseGetUsuarios.data.data.data;

						const newValueAnunciantes: {
							value: string | number;
							label: string;
							selected: boolean;
						}[] = [];

						allAnunciantes.map((anunciante: any) => {
							newValueAnunciantes.push({
								value: anunciante.id,
								label: anunciante.name,
								selected:
									response.data.data.shopkeeper_id ===
									anunciante.id,
							});
							return null;
						});

						setAnunciantes({
							value: newValueAnunciantes,
							required: anunciantes.required,
						});
					});
			});

		axios
			.get(urlEndpointGetCategories, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allCategorias = response.data.data.data;

				const newValueCategorias: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allCategorias.map((empreendimento: any) => {
					newValueCategorias.push({
						value: empreendimento.id,
						label: empreendimento.title,
						selected: false,
					});
					return null;
				});

				setCategorias({
					value: newValueCategorias,
					required: categorias.required,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		lockFields(currentFields);

		const entriesAnunciantes = anunciantes.value;
		const valueAnunciantes: {
			[key: string]: string | number;
		}[] = [];

		entriesAnunciantes.map(anunciante => {
			if (anunciante.selected) {
				valueAnunciantes.push({
					id: anunciante.value,
				});
			}

			return null;
		});

		const entriesCategorias = categorias.value;
		const valueCategorias: {
			[key: string]: string | number;
		}[] = [];

		entriesCategorias.map(categoria => {
			if (categoria.selected) {
				valueCategorias.push({
					id: categoria.value,
				});
			}

			return null;
		});

		axios
			.put(
				urlEndpointUpdateDesconto,
				{
					title: titulo.value,
					voucher: cupomDesconto.value,
					shopkeeper_id:
						valueAnunciantes[0] !== undefined
							? valueAnunciantes[0].id
							: null,
					archive: arquivos.value,
					categories: valueCategorias,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};
	return (
		<BitPage>
			<BitHeaderFull
				label={`Atualizar  / Desconto`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ATUALIZAR DESCONTO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DO DESCONTO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						<BitInput
							label="Anunciante"
							type="select"
							multipleSelect={false}
							placeholder=""
							valuesSelect={anunciantes.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueAnunciantes: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueAnunciantes.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setAnunciantes({
									value: newValueAnunciantes,
									required: anunciantes.required,
								});
							}}
						/>
					</div>
					<div className="mb-4">
						<BitInput
							label="Categoria"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={categorias.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueCategorias: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueCategorias.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setCategorias({
									value: newValueCategorias,
									required: categorias.required,
								});
							}}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4 mb-4">
						<BitInput
							label="Título"
							type="text"
							maxLength={50}
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTitulo({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={titulo.value}
							required={titulo.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Cupom de desconto"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCupomDesconto({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cupomDesconto.value}
							required={cupomDesconto.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					INFORMAÇÕES EXTRAS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-10">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivoDesconto = document.getElementById(
									'arquivoDesconto',
								);
								if (inputArquivoDesconto !== null) {
									inputArquivoDesconto.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivoDesconto`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos({
												value: e.target.result,
												required: arquivos.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos.value}
							required={arquivos.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivoDesconto'}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/descontos/vouchers'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateDesconto;
