import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const UpdateRazaoDenuncia: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdateReason = `${urlEndpoint}/reasons/update/${idRegister}`;
	const urlEndpointGetReason = `${urlEndpoint}/reasons/${idRegister}`;

	const token = localStorage.getItem('token');

	const [titulo, setTitulo] = useState({
		value: '',
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const limpaCampos = () => {
		setTitulo({
			value: '',
			required: titulo.required,
		});

		setShowRequiredBallon(false);
	};

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetReason, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				setTitulo({
					value: response.data.data.reason,
					required: true,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		lockFields(currentFields);

		axios
			.put(
				urlEndpointUpdateReason,
				{
					reason: titulo.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Razão`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR RAZÃO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DA RAZÃO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-4">
						<BitInput
							label="Descrição da Razão"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTitulo({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={titulo.value}
							required={titulo.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<div className="mb-10">
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/denuncias/razoes'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateRazaoDenuncia;
