import React, { useState } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitLogo from '../../components/BitLogo/BitLogo';
import BitInput from '../../components/BitInput/BitInput';

const ForgotPass: React.FC<RouteComponentProps> = ({ history }) => {
	const [email, setEmail] = useState('');

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointResetPassword = `${urlEndpoint}/password/send`;

	const handleResetPassword = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();


		axios.post(urlEndpointResetPassword, {
				email,
			},
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((res: any) => {
			toast.success('E-mail enviado com sucesso!', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		}).catch(err => {
			toast.error('Erro ao enviar e-mail :(', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
	};

	return (
		<div className="w-full lg:min-h-screen flex flex-col justify-center items-center p-6 overflow-y-auto">
			<div className="flex mb-12 justify-center content-center">
				<BitLogo width={'w-40'} styleImage={'color'} />
			</div>

			<div className="w-full max-w-md bg-white p-8">
				<p className="mb-4">
					<b>Recuperar senha</b>
				</p>

				<p className="mb-4">
					Insira seu endereço de e-mail e clique no botão abaixo, sua
					senha será redefinida e enviada para você.
				</p>

				<BitInput
					label={'E-mail'}
					type={'email'}
					placeholder={'Seu e-mail cadastrado'}
					onChange={(e: React.FormEvent<HTMLInputElement>) =>
						setEmail(e.currentTarget.value)
					}
					value={email}
					required={true}
				/>

				<BitButton
					text={'ENVIAR E-MAIL DE RECUPERÇÃO'}
					textColor={'#FFFFFF'}
					onClick={(e: React.FormEvent<HTMLInputElement>) =>
						handleResetPassword(e)
					}
				/>

				<p className="text-center">
					Para voltar para o login
					<Link className="underline ml-1" to="/login">
						clique aqui
					</Link>
				</p>
			</div>
		</div>
	);
};

export default ForgotPass;
