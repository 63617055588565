import React from 'react';
import Moment from 'moment';
import 'moment/locale/pt-br';
import BitInput from '../BitInput/BitInput';

type BitChatTalkProps = {
	fotoContato: string;
	nomeContato: string;
	onSendMessage: any;
	sendMessageInput: {
		name: string;
		placeholder: string;
		value: string;
		onChange: any;
		onKeyUp: any;
	};
	messagesList: {
		nomeContato: string;
		mensagemContato: string;
		tipoContato: 'minha_mensagem' | 'contato_mensagem';
		horarioEnvio: string;
	}[];
};

const BitChatTalk: React.FC<BitChatTalkProps> = ({
	fotoContato,
	nomeContato,
	messagesList,
	onSendMessage,
	sendMessageInput,
}) => {
	Moment.locale('pt-br');

	const returnMessages = (message: BitChatTalkProps['messagesList'][0]) => {
		const classMensagem =
			message.tipoContato === 'minha_mensagem'
				? 'bloco-minha-mensagem-usuario'
				: 'bloco-mensagem-usuario';

		return (
			<div className={`flex items-end ${classMensagem} my-4`}>
				<div className="mensagem-usuario">
					{message.mensagemContato}
					<p
						style={{
							fontSize: '10px',
							display: 'flex',
							justifyContent: 'flex-end'
						}}
					>
						{Moment(
							message.horarioEnvio, 'YYYY-MM-DD HH:mm:ss'
						).format('HH:mm:ss DD/MM/YYYY')}
					</p>
				</div>
			</div>
		);
	};

	return (
		<div className="bg-white rounded relative">
			<div className="cabecalho-chat px-6 py-4 rounded-tl rounded-tr">
				<img
					src={fotoContato}
					className="w-10 h-10 bg-gray-500 rounded-full object-cover object-center inline-block mr-3"
					alt=""
				/>
				<span>{nomeContato}</span>
			</div>
			<div className="px-14 pb-20">
				<div
					className="todas-mensagens-chat mt-3 px-6"
					id="todas-mensagens-chat"
				>
					{messagesList.map(returnMessages)}
				</div>
				<div className="enviar-mensagem-fixo-bottom rounded">
					<BitInput
						type={'text'}
						label={''}
						name={sendMessageInput.name}
						placeholder={sendMessageInput.placeholder}
						value={sendMessageInput.value}
						onChange={sendMessageInput.onChange}
						onKeyUp={sendMessageInput.onKeyUp}
					/>
					<button
						type="button"
						className="btn-envia-mensagem"
						onClick={onSendMessage}
					>
						<i className="fas fa-paper-plane" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default BitChatTalk;
