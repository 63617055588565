import React from 'react';

type BitBaloonProps = {
	bgColor: string;
	fontColor: string;
	borderColor: string;
};

const BitBaloon: React.FC<BitBaloonProps> = ({
	bgColor,
	fontColor,
	borderColor,
	children,
}) => {
	const baloonDefaultClass = 'border rounded mt-6 mb-6 px-3 py-2 ';

	return (
		<div
			className={
				bgColor && fontColor && borderColor
					? `${baloonDefaultClass} ${bgColor} ${fontColor} ${borderColor}`
					: 'bg-blue-100 border border-blue-500 text-blue-700 rounded mb-6 mt-6 px-3 py-2'
			}
		>
			{children}
		</div>
	);
};

export default BitBaloon;
