import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
// eslint-disable-next-line import/extensions
import cogoToast from './components/cogo-toast';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const NewPublicacao: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}
	interface interfaceArquivosFile {
		value: null | File;
		required: boolean;
	}

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	interface interfaceCheckbox {
		value: {
			[key: string]: {
				value: string | number;
				label: string;
				name: string;
			};
		};
		required: boolean;
	}

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointCreatePublicacao = `${urlEndpoint}/publications/store`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;
	const urlEndpointGetCategories = `${urlEndpoint}/categories`;

	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');

	const [isLoading, setLoadingSave] = useState(false);

	const [titulo, setTitulo] = useState({
		value: '',
		required: true,
	});
	const [descricao, setDescricao] = useState({
		value: '',
		required: true,
	});
	const [linkYoutube, setLinkYoutube] = useState({
		value: '',
		required: false,
	});
	const [dataPublicacao, setDataPublicacao] = useState({
		value: '',
		required: false,
	});
	const [horario, setHorario] = useState({
		value: '',
		required: false,
	});
	const [arquivos1, setArquivos1] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});
	const [arquivos2, setArquivos2] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});
	const [arquivos3, setArquivos3] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});
	const [arquivos4, setArquivos4] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});
	const [arquivosFile1, setArquivosFile1] = useState<interfaceArquivosFile>({
		value: null,
		required: false,
	});
	const [arquivosFile2, setArquivosFile2] = useState<interfaceArquivosFile>({
		value: null,
		required: false,
	});
	const [arquivosFile3, setArquivosFile3] = useState<interfaceArquivosFile>({
		value: null,
		required: false,
	});
	const [arquivosFile4, setArquivosFile4] = useState<interfaceArquivosFile>({
		value: null,
		required: false,
	});
	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});
	const [categorias, setCategorias] = useState<interfaceEmpreendimentos>({
		value: [],
		required: false,
	});

	const [images, setImages] = useState([]);

	console.log({ images });

	const [funcionalidades, setFuncionalidades] = useState<interfaceCheckbox>({
		value: {
			home: {
				label: 'FIXAR NA HOME',
				value: 0,
				name: 'home',
			},
			push: {
				label: 'PUSH',
				value: 0,
				name: 'push',
			},
			fixed_on_top: {
				label: 'FIXAR NO TOPO',
				value: 0,
				name: 'fixed_on_top',
			},
		},
		required: false,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const limpaCampos = () => {
		setTitulo({
			value: '',
			required: titulo.required,
		});
		setDescricao({
			value: '',
			required: descricao.required,
		});
		setLinkYoutube({
			value: '',
			required: linkYoutube.required,
		});
		setDataPublicacao({
			value: '',
			required: dataPublicacao.required,
		});
		setHorario({
			value: '',
			required: horario.required,
		});
		setArquivos1({
			value: '',
			required: arquivos1.required,
		});
		setArquivos2({
			value: '',
			required: arquivos2.required,
		});
		setArquivos3({
			value: '',
			required: arquivos3.required,
		});
		setArquivos4({
			value: '',
			required: arquivos4.required,
		});
		setArquivosFile1({
			value: null,
			required: false,
		});
		setArquivosFile2({
			value: null,
			required: false,
		});
		setArquivosFile3({
			value: null,
			required: false,
		});
		setArquivosFile4({
			value: null,
			required: false,
		});
		setFuncionalidades({
			value: {
				home: {
					label: 'FIXAR NA HOME',
					value: 0,
					name: 'home',
				},
				push: {
					label: 'PUSH',
					value: 0,
					name: 'push',
				},
				fixed_on_top: {
					label: 'FIXAR NO TOPO',
					value: 0,
					name: 'fixed_on_top',
				},
			},
			required: funcionalidades.required,
		});

		setShowRequiredBallon(false);
	};

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = Array.isArray(
					response.data.data.data,
				)
					? response.data.data.data
					: [response.data.data.data];

				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				if (typeUser === 'Porteiro') {
					newValueEmpreendimentos.push({
						value: allEmpreendimentos[0].id,
						label: allEmpreendimentos[0].name,
						selected: true,
					});
				} else {
					allEmpreendimentos.map((empreendimento: any) => {
						const isSelected = false;

						newValueEmpreendimentos.push({
							value: empreendimento.id,
							label: empreendimento.name,
							selected: isSelected,
						});
						return null;
					});
				}

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});
			});

		axios
			.get(urlEndpointGetCategories, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allCategorias = response.data.data.data;

				const newValueCategorias: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allCategorias.map((empreendimento: any) => {
					newValueCategorias.push({
						value: empreendimento.id,
						label: empreendimento.title,
						selected: false,
					});
					return null;
				});

				setCategorias({
					value: newValueCategorias,
					required: categorias.required,
				});
			});
	}, []);

	let cogo: any = null;

	const uploadAllImagesCarrossel = async (): Promise<string[]> => {
		const imageUploaded = [];
		try {
			// eslint-disable-next-line
			for (const [idx, image] of images.entries()) {
				if (cogo) {
					(cogo as any).hide();
				}
				cogo = cogoToast.loading(
					`Enviando imagem ${idx + 1} de ${images.length}`,
				);
				const formData = new FormData();

				formData.append('image', image);
				formData.append('order', `${idx}`);

				// eslint-disable-next-line no-await-in-loop
				const { data } = await axios.post(
					`${urlEndpoint}/publications/media/store`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${token}`,
							// 'Content-Type': 'application/json',
							'Content-type': 'multipart/form-data',
						},
					},
				);

				imageUploaded.push(data.data.id);
			}

			return imageUploaded;
		} catch (error) {
			toast.error(
				`Tivemos um erro ao tentar enviar as imagens. \n Error: ${error}`,
				{
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				},
			);

			unlockFields(currentFields);
			throw error;
		}
	};

	const handleCadastrar = async (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		lockFields(currentFields);

		const entriesDevelopments = empreendimentos.value;
		const valueDevelopments: {
			[key: string]: string | number;
		}[] = [];

		entriesDevelopments.map(empreendimento => {
			if (empreendimento.selected) {
				valueDevelopments.push({
					id: empreendimento.value,
				});
			}

			return null;
		});

		const entriesCategorias = categorias.value;
		const valueCategorias: {
			[key: string]: string | number;
		}[] = [];

		entriesCategorias.map(categoria => {
			if (categoria.selected) {
				valueCategorias.push({
					id: categoria.value,
				});
			}

			return null;
		});

		const formData = new FormData();

		formData.append('title', titulo.value);
		formData.append('body', descricao.value);
		formData.append(
			'home',
			funcionalidades.value.home.value === 1 ? '1' : '0',
		);
		formData.append(
			'push',
			funcionalidades.value.push.value === 1 ? '1' : '0',
		);
		formData.append(
			'fixed_on_top',
			funcionalidades.value.fixed_on_top.value === 1 ? '1' : '0',
		);

		valueCategorias?.forEach((item, index) => {
			formData.append(`categories[${index}][id]`, `${item.id}`);
		});
		valueDevelopments?.forEach((item, index) => {
			formData.append(`developments[${index}][id]`, `${item.id}`);
		});

		if (dataPublicacao.value)
			formData.append('publication_date', dataPublicacao.value);

		if (horario.value) formData.append('publication_hour', horario.value);

		if (linkYoutube.value) {
			let youtubeLinkHandle = linkYoutube.value || '';

			if (youtubeLinkHandle?.includes('shorts')) {
				// eslint-disable-next-line prefer-destructuring
				youtubeLinkHandle = youtubeLinkHandle.split('?')[0];

				const splitedShortLink = youtubeLinkHandle.split('shorts/');

				youtubeLinkHandle =
					splitedShortLink[splitedShortLink.length - 1];

				youtubeLinkHandle = `https://youtu.be/${youtubeLinkHandle}`;
			}

			formData.append('youtube_link', youtubeLinkHandle);
		}

		if (arquivosFile4.value)
			formData.append('pdf', arquivosFile4.value as File);

		const imagesToUpload = await uploadAllImagesCarrossel();

		if (imagesToUpload && imagesToUpload.length)
			imagesToUpload.forEach((itemId, index) =>
				formData.append(`upImages[]`, itemId),
			);

		cogo = cogoToast.loading('Criando sua publicação, aguarde...');

		axios
			.post(
				`${urlEndpointCreatePublicacao}/pdf`,
				// {
				// 	title: titulo.value,
				// 	body: descricao.value,
				// 	youtube_link: linkYoutube.value,
				// 	home: funcionalidades.value.home.value === 1,
				// 	push: funcionalidades.value.push.value === 1,
				// 	fixed_on_top:
				// 		funcionalidades.value.fixed_on_top.value === 1,
				// 	publication_date: dataPublicacao.value,
				// 	publication_hour: horario.value,
				// 	categories: valueCategorias,
				// 	developments: valueDevelopments,
				// 	photo_1: arquivos1.value,
				// 	photo_2: arquivos2.value,
				// 	photo_3: arquivos3.value,
				// 	pdf: arquivos4.value,
				// },
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						// 'Content-Type': 'application/json',
						'Content-type': 'multipart/form-data',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 201) {
					toast.success('Cadastro realizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					limpaCampos();

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				toast.error(
					`Erro ao cadastrar. Verifique os campos obrigatórios e tente novamente. \n Error: ${error} ${JSON.stringify(
						error?.response?.data?.error,
					)}`,
					{
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			})
			.finally(() => {
				(cogo as any).hide();
			});
	};

	const onRemoveImage = (index: number) => {
		setImages(images.filter((_, i) => i !== index));
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Publicação`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR PUBLICAÇÃO</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DA PUBLICAÇÃO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-1 gap-4 mb-4">
						<BitInput
							label="Título"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTitulo({
									value: e.currentTarget.value,
									required: titulo.required,
								})
							}
							value={titulo.value}
							required={titulo.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Categoria"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={categorias.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueCategorias: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueCategorias.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setCategorias({
									value: newValueCategorias,
									required: categorias.required,
								});
							}}
						/>
					</div>
					<div className="mb-10">
						<BitInput
							label="Conteúdo"
							type="textarea"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDescricao({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={descricao.value}
							required={descricao.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					EMPREENDIMENTOS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label="Nome do empreendimento"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={empreendimentos.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueEmpreendimentos: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueEmpreendimentos.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setEmpreendimentos({
									value: newValueEmpreendimentos,
									required: empreendimentos.required,
								});
							}}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">3</span>
					Carrossel
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							multipleSelect
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivo1 = document.getElementById(
									'arquivo1',
								);
								if (inputArquivo1 !== null) {
									inputArquivo1.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget
									.files as any;
								if (currentFile !== null) {
									setImages([
										...[...currentFile].reverse(),
										...images,
									] as any);
								}
							}}
							imgPreview={''}
							required={arquivos1.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivo1'}
						/>
						{images.map((imgItem, index) => (
							<div>
								<img
									className="img-preview-thumbnail img-preview-300px"
									src={URL.createObjectURL(imgItem)}
									alt=""
									style={{
										maxWidth: '500px',
										padding: '5px',
									}}
								/>
								<button
									type="button"
									style={{ bottom: 10, right: 10 }}
									onClick={e => onRemoveImage(index)}
								>
									<i className="far fa-minus-square" />
								</button>
							</div>
						))}
					</div>
				</div>

				<div className="mb-10" />

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">6</span>
					ARQUIVO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label=""
							type="file"
							isPdf={true}
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivo4 = document.getElementById(
									'arquivo4',
								);
								if (inputArquivo4 !== null) {
									inputArquivo4.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivo4`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									setArquivosFile4({
										value: currentFile[0],
										required: false,
									});
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos4({
												value: e.target.result,
												required: arquivos4.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos4.value}
							required={arquivos4.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivo4'}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">7</span>
					INFORMAÇÕES EXTRAS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-8">
						<BitInput
							label="Link do youtube"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setLinkYoutube({
									value: e.currentTarget.value,
									required: linkYoutube.required,
								})
							}
							value={linkYoutube.value}
							required={linkYoutube.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="mb-4">
						<BitInput
							label=""
							type="checkbox"
							placeholder="Digite..."
							classField=""
							valuesCheckbox={funcionalidades.value}
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const newValueCheckbox = e.currentTarget.checked
									? 1
									: 0;
								const newValueFuncionalidades = funcionalidades;

								newValueFuncionalidades.value[
									e.currentTarget.name
								].value = newValueCheckbox;

								setFuncionalidades(newValueFuncionalidades);
								setTitulo({
									value: titulo.value,
									required: titulo.required,
								});
							}}
						/>
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">8</span>
					PROGRAMAR PUBLICAÇÃO
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Data da Publicação"
							type="date"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDataPublicacao({
									value: e.currentTarget.value,
									required: dataPublicacao.required,
								})
							}
							value={dataPublicacao.value}
							required={dataPublicacao.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Horário"
							type="maskedinput"
							mask={[
								/\d/,
								/\d/,
								':',
								/\d/,
								/\d/,
								':',
								/\d/,
								/\d/,
							]}
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setHorario({
									value: e.currentTarget.value,
									required: horario.required,
								})
							}
							value={horario.value}
							required={horario.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/publicacoes'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>

				<div className="mb-10" />
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default NewPublicacao;
