import React from 'react';
import { Link } from 'react-router-dom';

type BitLinkButtonProps = {
	link: string;
	text: string;
	textColor?: string;
	buttonColor?: string;
	external?: boolean;
};

const BitLinkButton: React.FC<BitLinkButtonProps> = ({
	link,
	text,
	textColor,
	buttonColor,
	external,
}) => {
	const defaultLink = '/';
	const defaultText = 'Seu texto';
	const defaultTextColor = 'rgba(255, 255, 255, var(--text-opacity))';
	const defaultButtonColor = '#91b820';

	if (external) {
		return (
			<a
				className="button button--outlined"
				style={{
					color: textColor ?? defaultTextColor,
					backgroundColor: buttonColor ?? defaultButtonColor,
				}}
				href={link ?? defaultLink}
			>
				{text ?? defaultText}
			</a>
		);
	}

	return (
		<Link
			className="button button--outlined"
			style={{
				color: textColor ?? defaultTextColor,
				backgroundColor: buttonColor ?? defaultButtonColor,
			}}
			to={link ?? defaultLink}
		>
			{text ?? defaultText}
		</Link>
	);
};

export default BitLinkButton;
