import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const UpdateDescontoCategoria: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceArquivos {
		value: string | ArrayBuffer | null;
		required: boolean;
	}

	interface interfaceNewTags {
		inputValue: string;
		value: any[];
		required: boolean;
	}

	interface ParamTypes {
		idRegister: string;
	}

	const createOption = (label: string) => ({
		label,
		value: label,
	});

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdateDescontoCategoria = `${urlEndpoint}/shopkeepers/categories/update/${idRegister}`;
	const urlEndpointGetCategoria = `${urlEndpoint}/shopkeepers/categories/${idRegister}`;

	const token = localStorage.getItem('token');

	const [titulo, setTitulo] = useState({
		value: '',
		required: true,
	});
	const [newTags, setNewTags] = useState<interfaceNewTags>({
		inputValue: '',
		value: [],
		required: false,
	});
	const [arquivos, setArquivos] = useState<interfaceArquivos>({
		value: '',
		required: false,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	useEffect(() => {
		axios
			.get(urlEndpointGetCategoria, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				// const newTagsValues: any[] = [];

				// response.data.data.tags
				// 	.split(';')
				// 	.forEach((element: string) => {
				// 		newTagsValues.push(createOption(element));
				// 	});

				setTitulo({
					value: response.data.data.title,
					required: true,
				});
				// setNewTags({
				// 	inputValue: '',
				// 	value: newTagsValues,
				// 	required: true,
				// });
				setArquivos({
					value: response.data.data.photo,
					required: true,
				});
			});
	}, []);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	const handleAtualizar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		lockFields(currentFields);

		const tagsEnvia =
			newTags.value !== null && newTags.value.length > 0
				? newTags.value.map(newTag => newTag.value).join(';')
				: '';

		axios
			.put(
				urlEndpointUpdateDescontoCategoria,
				{
					title: titulo.value,
					tags: tagsEnvia,
					archive: arquivos.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status === 202) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Categoria`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ATUALIZAR CATEGORIA</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DA CATEGORIA
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-4">
						<BitInput
							label="Título"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTitulo({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={titulo.value}
							required={titulo.required}
							showRequiredBallon={showRequiredBallon}
						/>
						{/* <BitInput
							label="Tags"
							type="reactselect"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(value: any, actionMeta: any) => {
								setNewTags({
									inputValue: newTags.inputValue,
									value,
									required: newTags.required,
								});
							}}
							inputChange={(inputValue: string) => {
								setNewTags({
									inputValue,
									value: newTags.value,
									required: newTags.required,
								});
							}}
							onKeyDown={(event: any) => {
								const { inputValue, value } = newTags;
								if (!inputValue) return;
								switch (event.key) {
									case 'Enter':
									case 'Tab':
										setNewTags({
											inputValue: '',
											value: [
												...value,
												createOption(inputValue),
											],
											required: newTags.required,
										});
										console.log(newTags);
										event.preventDefault();
										break;
									default:
									// console.log('default');
								}
							}}
							valueSelect={newTags.value}
							inputValueSelect={newTags.inputValue}
							required={newTags.required}
							showRequiredBallon={showRequiredBallon}
						/> */}
					</div>
				</div>

				<div className="mb-10" />

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					INFORMAÇÕES EXTRAS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-10">
						<BitInput
							label=""
							type="file"
							placeholder="Digite..."
							classField=""
							onChange={(
								e: React.FormEvent<HTMLInputElement>,
							) => {
								const inputArquivoCategoria = document.getElementById(
									'arquivoCategoria',
								);
								if (inputArquivoCategoria !== null) {
									inputArquivoCategoria.click();
								}
							}}
							onChangeFile={(
								eInput: React.FormEvent<HTMLInputElement>,
							) => {
								const currentFile = eInput.currentTarget.files;
								const currentElemImg: any = document.getElementById(
									`img-arquivoCategoria`,
								);
								const FR = new FileReader();

								if (currentFile !== null) {
									FR.addEventListener('load', function (e) {
										if (e.target !== null) {
											setArquivos({
												value: e.target.result,
												required: arquivos.required,
											});

											if (currentElemImg !== null) {
												currentElemImg.src =
													e.target.result;
											}
										}
									});

									FR.readAsDataURL(currentFile[0]);
								}
							}}
							imgPreview={arquivos.value}
							required={arquivos.required}
							showRequiredBallon={showRequiredBallon}
							name={'arquivoCategoria'}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/descontos/categorias'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleAtualizar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateDescontoCategoria;
