import React from 'react';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

const BitAccount: React.FC = () => {
	const defaultUserPhoto = `https://www.gravatar.com/avatar/1376dc0d9c58716c52dc2d685e0a643a.jpg?s=80&d=mp&r=g`;
	const userPhoto =
		localStorage.getItem('user_photo') !== undefined &&
		localStorage.getItem('user_photo') !== null &&
		localStorage.getItem('user_photo') !== 'undefined' &&
		localStorage.getItem('user_photo') !== ''
			? localStorage.getItem('user_photo')
			: defaultUserPhoto;

	return (
		<Tippy content="Minha conta">
			<Link className="mx-auto mb-6" to={'/account/profile'}>
				<img
					className="w-10 h-10 bg-gray-500 rounded-full border border-purple-700 object-cover object-center"
					src={userPhoto ?? defaultUserPhoto}
					alt=""
				/>
			</Link>
		</Tippy>
	);
};

export default BitAccount;
