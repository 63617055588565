/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, createElement } from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { appendFile } from 'fs';
import { endpointState } from '../../recoil/atoms';

import BitBaloon from '../../components/BitBaloon/BitBaloon';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import BitCardList from '../../components/BitCard/BitCardList';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';

const Dashboard: React.FC = () => {
	const typeUser = localStorage.getItem('type');

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}
	const [url, setUrl] = useState('');

	type typeResponseAxios = {
		[key: string]: any;
	};

	const userName = localStorage.getItem('name');
	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointGetDashboard = `${urlEndpoint}/dashboard/metrics`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;
	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});
	const [data, setData] = useState<any[]>([]);
	const [dashboardIframe, setDashboardIframe] = useState<string>(String);
	
	const idGestor = [
		'', // 0
		'', // 1
		'', // 2
		'', // 3
		'https://datastudio.google.com/embed/reporting/03934c39-93f7-450d-a809-f716fce32bad/page/MfM4C', // 4
		'', // 5
		'https://datastudio.google.com/embed/reporting/15688abd-595d-4bde-836f-311f6304e7bb/page/MfM4C', // 6
		'https://datastudio.google.com/embed/reporting/06504fa6-bc61-48f6-885b-cecc6e4ad099/page/MfM4C', // 7
		'https://datastudio.google.com/embed/reporting/4b4e2ae4-c835-4ef9-ac92-329249f6c845/page/MfM4C', // 8
		'https://datastudio.google.com/embed/reporting/2c64a763-b8d5-4d78-87f6-125b0c0170e9/page/MfM4C', // 9
		'https://datastudio.google.com/embed/reporting/3faf44cf-cacb-4909-a80b-4e273ce7f8d1/page/MfM4C', // 10
		'https://datastudio.google.com/embed/reporting/c987549f-d43a-43e0-82a6-ade33a86b26e/page/MfM4C', // 11
		'https://datastudio.google.com/embed/reporting/e539a092-2e66-45a9-8ff2-61b390d70eee/page/MfM4C', // 12
		'https://datastudio.google.com/embed/reporting/f627a8fc-7ec1-4536-9f96-628a7ec42f2c/page/MfM4C', // 13
		'', // 14
		'', // 15
		'', // 16
		'', // 17
		'https://datastudio.google.com/embed/reporting/55cefb73-7b72-48af-88a5-4d8db1a86286/page/MfM4C', // 18
		'https://datastudio.google.com/embed/reporting/eb394cec-8278-4f30-80c2-00ff5037b696/page/MfM4C', // 19
		'https://datastudio.google.com/embed/reporting/79bbd58e-28c6-4d52-b461-a780f626ef1f/page/MfM4C', // 20
		'https://datastudio.google.com/embed/reporting/04081de6-50f6-4a8f-992c-39449e2a80d6/page/MfM4C', // 21
		'', // 22
		'https://datastudio.google.com/embed/reporting/8dbb1c1a-7b9f-4d95-9072-3e36c81ef4eb/page/MfM4C', // 23
		'', // 24
		'', // 25
		'', // 26
		'', // 27
		'https://lookerstudio.google.com/embed/reporting/782aae97-dc76-45b0-9b6d-7e4a1808b4a6/page/MfM4C', // 28
		'', // 29
		'https://lookerstudio.google.com/embed/reporting/353e8085-d343-4b45-8e70-b9122c1fc0b1/page/MfM4C', // 30
	];

	const iAdmin =
		'https://datastudio.google.com/embed/reporting/a30f2663-1155-4f68-8e49-1b7ccd87d53b/page/MfM4C';

	useEffect(() => {
		const token = localStorage.getItem('token');
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;
				setData(allEmpreendimentos);
			})
			.catch((error: any) => {
				console.log(error);
			});
	}, []);

	return (
		<BitPage>
			<BitHeaderFull label={'Dashboard'} icon={'fas fa-home'}>
				<div className="text-right flex flex-col items-end -my-3">
					<div className="w-max text-sm text-gray-600">
						Olá,&nbsp;
						<strong>{userName}</strong>
					</div>
					<div className="w-max">Seja bem-vindo novamente!</div>
				</div>
			</BitHeaderFull>

			<div className="footPrint w-full p-5 bg-white rounded">
				<div className="inline-block w-full">
					<iframe
						src={data?.length > 1 ? iAdmin : idGestor[data[0]?.id]}
						title="Dashboard"
						width="80%"
						height="1200px"
						frameBorder="0"
					/>
				</div>

				<BitBaloon
					bgColor={'bg-blue-100'}
					fontColor={'text-blue-700'}
					borderColor={'border-blue-500'}
				>
					<p>
						Atenção! estes dados não são atualizados em tempo real!
						Pressione F5 para atualizar
					</p>
				</BitBaloon>
			</div>
		</BitPage>
	);
};

export default Dashboard;
function componentDidMount() {
	throw new Error('Function not implemented.');
}
