import { randomBytes } from 'crypto';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { Editor } from '@tinymce/tinymce-react';
import ColorPicker from 'react-pick-color';

import CreatableSelect from 'react-select/creatable';

import BitButton from '../BitButton/BitButton';

import iconArquivos from '../../assets/images/icon-arquivos.svg';
import BitBaloon from '../BitBaloon/BitBaloon';

type BitInputProps = {
	label: string;
	type: string;
	placeholder: string;
	onChange?: any;
	inputChange?: any;
	onChangeFile?: any;
	onKeyPress?: any;
	onKeyUp?: any;
	onKeyDown?: any;
	value?: string;
	valuesCheckbox?: {
		[key: string]: {
			label: string;
			value: number | string;
			name: string;
		};
	};
	valuesSelect?: {
		label: string;
		value: number | string;
		selected: boolean;
	}[];
	valuesRepetidor?: {
		label: string;
		value: string;
		name: string;
		placeholder: string;
	}[];
	required?: boolean;
	classField?: string;
	showRequiredBallon?: boolean;
	disabled?: boolean;
	mask?: any;
	name?: string;
	imgPreview?: string | ArrayBuffer | null;
	multipleSelect?: boolean;
	inputValueSelect?: string;
	valueSelect?: any;
	maxLength?: number;
	opcaoTodosSelect?: boolean;
	isPdf?: boolean;
	autoComplete?: string;
};

const BitInput: React.FC<BitInputProps> = ({
	label,
	type,
	placeholder,
	onChange,
	inputChange,
	onChangeFile,
	onKeyPress,
	onKeyUp,
	onKeyDown,
	value,
	valuesCheckbox,
	valuesSelect,
	valuesRepetidor,
	required,
	classField,
	showRequiredBallon,
	disabled,
	mask,
	name,
	imgPreview,
	multipleSelect,
	inputValueSelect,
	valueSelect,
	maxLength,
	opcaoTodosSelect,
	isPdf,
	autoComplete,
}) => {
	const defaultLabel = 'Sua Label';

	let inputEl = (
		<input
			type={type ?? 'text'}
			maxLength={maxLength}
			name={label ?? defaultLabel + randomBytes(2)}
			className="input"
			placeholder={placeholder ?? 'Seu Placeholder'}
			onChange={onChange}
			onKeyPress={onKeyPress}
			onKeyUp={onKeyUp}
			onKeyDown={onKeyDown}
			value={value ?? ''}
			required={required ?? false}
			disabled={disabled ?? false}
			autoComplete={autoComplete}
		/>
	);

	if (type === 'tinymce') {
		inputEl = (
			<Editor
				disabled={disabled}
				initialValue={value ?? ''}
				init={{
					height: 200,
					menubar: false,
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount',
					],
					toolbar:
						'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
				}}
				onEditorChange={onChange}
			/>
		);
	} else if (type === 'colorpicker') {
		inputEl = <ColorPicker color={value ?? ''} onChange={onChange} />;
	} else if (type === 'checkbox' && valuesCheckbox !== undefined) {
		const entriesValuesCheckbox = Object.entries(valuesCheckbox);
		inputEl = (
			<div className="grid grid-cols-3 gap-4">
				{entriesValuesCheckbox.map((checkbox, index) => {
					return (
						<div className="inline-flex items-center">
							<input
								type="checkbox"
								name={checkbox[1].name}
								id={checkbox[1].name}
								className="mr-3"
								onChange={onChange}
								checked={checkbox[1].value === 1 ?? false}
							/>
							<label
								htmlFor={checkbox[1].name}
								style={{ marginBottom: '0px' }}
							>
								{checkbox[1].label}
							</label>
						</div>
					);
				})}
			</div>
		);
	} else if (type === 'repetidor' && valuesRepetidor !== undefined) {
		inputEl = (
			<div className="grid grid-cols-1 gap-4">
				{valuesRepetidor.map((checkbox, index) => {
					return (
						<BitInput
							label={checkbox.label}
							type="text"
							placeholder={checkbox.placeholder}
							value={checkbox.value}
							classField="grupo-campo-novos-registros"
						/>
					);
				})}
				<BitButton
					text={'ADICIONAR EMPREENDIMENTO'}
					buttonColor={'#89B30F'}
					textColor={'#FFFFFF'}
					type={'fit px-10'}
					classesBlockOption={'inline-block'}
				/>
			</div>
		);
	} else if (type === 'file') {
		const nullVariable = <></>;
		const imgHTML =
			imgPreview?.toString() !== '' ? (
				<div className="bloco-img-preview-thumbnail">
					{isPdf ? (
						<>
							{imgPreview
								?.toString()
								.includes('application/pdf') ? (
								nullVariable
							) : (
								<p>{`Arquivo: ${imgPreview?.toString()}`}</p>
							)}
						</>
					) : (
						<img
							className="img-preview-thumbnail"
							src={imgPreview?.toString()}
							alt=""
							id={`img-${name}`}
						/>
					)}
				</div>
			) : null;
		inputEl = (
			<div className="w-1/2">
				<button
					type="button"
					className="bloco-solta-arquivos flex items-center w-full"
					onClick={onChange}
				>
					<div className="icone-solta-arquivos">
						<img
							src={iconArquivos}
							alt=""
							width="26"
							className="mr-4"
						/>
					</div>
					<div className="texto-solta-arquivos text-left">
						<p className="text-lg">
							<b>ADICIONAR ARQUIVOS</b>
						</p>
						<p className="text-sm">
							{isPdf
								? 'Adicione arquivos de até 100MB (PDF)'
								: 'Adicione arquivos até 15MB (Dimensões imagem: 874x390px)'}
						</p>
					</div>
				</button>
				<input
					type="file"
					className={'hidden'}
					name={name}
					id={name}
					onChange={onChangeFile}
					accept={isPdf ? 'application/pdf' : 'image/*'}
					multiple={multipleSelect}
				/>
				{imgHTML}
			</div>
		);
	} else if (type === 'select') {
		if (valuesSelect !== undefined) {
			const entriesValuesSelect = Object.entries(valuesSelect);
			const classIsMultipleSelect = multipleSelect
				? 'multiple-select'
				: null;
			inputEl = (
				<div className="grid grid-cols-1 gap-4">
					<select
						disabled={disabled}
						name={label ?? defaultLabel}
						id={label ?? defaultLabel}
						className={`input ${classIsMultipleSelect}`}
						multiple={multipleSelect}
						onChange={onChange}
					>
						{opcaoTodosSelect ? (
							<option value="all_developments">Todos</option>
						) : (
							<option value="" disabled={multipleSelect}>
								Selecione...
							</option>
						)}
						{entriesValuesSelect.map((checkbox, index) => {
							return (
								<option
									value={checkbox[1].value}
									selected={checkbox[1].selected}
								>
									{checkbox[1].label}
								</option>
							);
						})}
					</select>
				</div>
			);
		}
	} else if (type === 'maskedinput') {
		inputEl = (
			<MaskedInput
				disabled={disabled}
				mask={mask}
				type={type ?? 'text'}
				name={label ?? defaultLabel + randomBytes(2)}
				className="input"
				placeholder={placeholder ?? 'Seu Placeholder'}
				onChange={onChange}
				onKeyPress={onKeyPress}
				onKeyUp={onKeyUp}
				onKeyDown={onKeyDown}
				value={value ?? ''}
				required={required ?? false}
				autoComplete={autoComplete}
			/>
		);
	} else if (type === 'reactselect') {
		inputEl = (
			<CreatableSelect
				components={{
					DropdownIndicator: null,
				}}
				inputValue={inputValueSelect}
				isClearable
				isMulti
				menuIsOpen={false}
				onChange={onChange}
				onInputChange={inputChange}
				onKeyDown={onKeyDown}
				placeholder={placeholder}
				value={valueSelect}
			/>
		);
	} else if (type === 'textarea') {
		inputEl = (
			<textarea
				name={label ?? defaultLabel + randomBytes(2)}
				rows={4}
				className="textarea"
				placeholder={placeholder ?? 'Seu Placeholder'}
				onChange={onChange}
				onKeyPress={onKeyPress}
				onKeyUp={onKeyUp}
				onKeyDown={onKeyDown}
				value={value ?? ''}
				required={required ?? false}
				disabled={disabled ?? false}
				autoComplete={autoComplete}
			/>
		);
	} else if (type === 'spreadsheetUpload') {
		inputEl = (
			<div className="w-1/2">
				<button
					type="button"
					className="bloco-solta-arquivos flex items-center w-full"
					onClick={onChange}
				>
					<div className="icone-solta-arquivos">
						<img
							src={iconArquivos}
							alt=""
							width="26"
							className="mr-4"
						/>
					</div>
					<div className="texto-solta-arquivos text-left">
						<p className="text-lg">
							<b>ADICIONAR XLSX</b>
						</p>
						<p className="text-sm">
							Adicione arquivos até 100MB (Extensões: .xls, .xlsx)
						</p>
					</div>
				</button>
				<input
					type="file"
					className={'hidden'}
					name={name}
					id={name}
					onChange={onChangeFile}
				/>
			</div>
		);
	}

	const isHidden =
		!showRequiredBallon || showRequiredBallon === undefined ? 'hidden' : '';

	const requiredEl =
		required && (value === '' || imgPreview === '') ? (
			<div
				className={`w-full text-center campo-required-alerta ${isHidden}`}
			>
				<BitBaloon
					bgColor={'bg-red-100'}
					fontColor={'text-red-700'}
					borderColor={'border-red-500'}
				>
					<p>Este campo é obrigatório</p>
				</BitBaloon>
			</div>
		) : null;

	return (
		<div className={`field ${classField}`}>
			<label htmlFor={label ?? defaultLabel + randomBytes(2)}>
				{label ?? defaultLabel}
			</label>
			{inputEl}
			{requiredEl}
		</div>
	);
};

export default BitInput;
