import React from 'react';
import { Link } from 'react-router-dom';

type BitHeaderSubMenuProps = {
	label: string;
	link: string;
	pathName: string;
};

const BitHeaderSubMenu: React.FC<BitHeaderSubMenuProps> = ({
	label,
	link,
	pathName,
}) => {
	return (
		<Link
			className={
				(pathName && pathName === link) ||
				(pathName === '/registrations' &&
					link === '/registrations/administrators') ||
				(pathName === '/settings' && link === '/settings/general') ||
				(pathName === '/account' && link === '/account/profile')
					? 'sub-menu__item sub-menu__item--actived'
					: 'sub-menu__item'
			}
			to={link ?? '/'}
		>
			{label ?? 'Sua Label'}
		</Link>
	);
};

export default BitHeaderSubMenu;
