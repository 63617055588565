import { atom } from 'recoil';

type typePermissions = {
	[key: string]: {
		[key: string]: any;
	};
};

console.log(process.env)
export const endpointState = atom({
	key: 'endpointState',
	default: {
		urlEndpoint:process.env.REACT_APP_API_URL,
	},
});

export const loginState = atom({
	key: 'loginState',
	default: {
		email: '',
		password: '',
	},
});

export const accessPagesState = atom<typePermissions>({
	key: 'accessPagesState',
	default: {
		permissions: {
			users: {
				administrator: [],
				shopkeeper: [],
				resident: [],
				manager: [],
				porter: [],
			},
			publication: [],
			development: [],
			voucher: [],
			reservation: [],
			common_area: [],
			packages: [],
			bazar: [],
			talk_with_gestor: [],
			push: [],
		},
	},
});

export const routesAccessPagesState = atom<typePermissions>({
	key: 'routesAccessPagesState',
	default: {
		permissionsRoutes: {
			users: {
				administrator: {
					'user-list': [
						'/registrations',
						'/registrations/administrators',
					],
					'user-create': ['/new-register/administrador'],
					'user-edit': ['/update/administrador'],
					'user-delete': [],
				},
				shopkeeper: {
					'user-list': ['/registrations/shopkeepers', '/chat'],
					'user-create': ['/new-register/lojista'],
					'user-edit': ['/update/lojista'],
					'user-delete': [],
				},
				resident: {
					'user-list': ['/registrations/residents'],
					'user-create': ['/new-register/morador'],
					'user-edit': ['/update/morador'],
					'user-delete': [],
				},
				manager: {
					'user-list': ['/registrations/managers'],
					'user-create': ['/new-register/gestor'],
					'user-edit': ['/update/gestor'],
					'user-delete': [],
				},
				porter: {
					'user-list': ['/registrations/porters'],
					'user-create': ['/new-register/porteiro'],
					'user-edit': ['/update/porteiro'],
					'user-delete': [],
				},
			},
			publication: {
				'publication-list': [
					'/publicacoes',
					'/publicacoes/categorias',
					'/enquetes',
					'/shortcuts',
					'/denuncias',
					'/denuncias/razoes',
					'/denuncias/filtros',
					'/update/filtrados',
				],
				'publication-create': [
					'/new-register/publicacao',
					'/new-register/categoria',
					'/new-register/shortcuts',
					'/new-register/enquete',
					
					'/new-register/razao',
				],
				'publication-edit': [
					'/update/publicacao',
					'/update/categoria',
					'/update/enquete',
					'/update/shortcuts',
					
					'/update/denuncia',
					'/update/razao',
				],
				'publication-delete': [],
			},
			development: {
				'development-list': ['/registrations/enterprises'],
				'development-create': ['/new-register/empreendimento'],
				'development-edit': ['/update/empreendimento'],
				'development-delete': [],
			},
			voucher: {
				'voucher-list': [
					'/descontos/vouchers',
					'/descontos/categorias',
				],
				'voucher-create': [
					'/new-register/desconto',
					'/new-register/desconto/categoria',
				],
				'voucher-edit': [
					'/update/desconto',
					'/update/desconto/categoria/',
				],
				'voucher-delete': [],
			},
			reservation: {
				'reservation-list': ['/reservas'],
				'reservation-create': [
					'/new-register/reserva',
					'/new-register/area-comum',
				],
				'reservation-edit': ['/update/reserva'],
				'reservation-delete': [],
			},
			common_area: {
				'commonArea-list': [],
				'commonArea-create': ['/update/area-comum'],
				'commonArea-edit': [],
				'commonArea-delete': [],
			},
			packages: {},
			bazar: {},
			talk_with_gestor: {
				'talkWithGestor-list': ['/chat'],
				'talkWithGestor-create': [],
				'talkWithGestor-edit': [],
				'talkWithGestor-delete': [],
			},
			push: {
				'push-list': ['/notificacao/lista-de-push'],
				'push-create': ['/new-register/notificacao'],
				'push-edit': ['/update/notificacao'],
				'push-delete': [],
			},
		},
	},
});

export const filterState = atom({
	key: 'filterState',
	default: {
		cpf: '',
		cnpj: '',
		nome: '',
		titulo: '',
		cidade: '',
		telefone: '',
		empreendimento: '',
		areaComum: '',
		tags: '',
		dataCadastro: '',
		cupom: '',
		statusSolicitacoes: '',
	},
});

export const dataRepetidorState = atom({
	key: 'dataRepetidorState',
	default: [
		{
			label: '',
			value: '',
			name: '',
			placeholder: '',
		},
	],
});

export const packagesDataState = atom({
	key: 'packagesDataState',
	default: {
		headers: [''],
		data: [['']],
	},
});

export const creditModalState = atom({
	key: 'creditModalState',
	default: {
		open: false,
	},
});
