import React, { useState, useEffect, createElement } from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import mocaHashtag from '../../assets/images/moca-hashtag.png';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';

import BitBaloon from '../../components/BitBaloon/BitBaloon';
import BitCardList from '../../components/BitCard/BitCardList';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';
import BitInput from '../../components/BitInput/BitInput';


const DashboardLojista: React.FC = () => {
	type typeCardsMostAccessProm = {
		thumb: string;
		title: string;
		description: string;
	}[];

	type typeResponseAxios = {
		[key: string]: any;
	};

	const userName = localStorage.getItem('name');

	const [
		cardsMostAccessProm,
		setCardsMostAccessProm,
	] = useState<typeCardsMostAccessProm>([]);

	const [activeDiscountsNumber, setActiveDiscountsNumber] = useState(0);

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointGetDashboard = `${urlEndpoint}/dashboard/metrics/shopkeeper`;

	useEffect(() => {
		const handleGetMetrics = async () => {
			const token = localStorage.getItem('token');

			await axios
				.get(urlEndpointGetDashboard, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response: typeResponseAxios) {
					const newCardsMostAccessProm: typeCardsMostAccessProm = [];
					console.log(response);

					if (
						typeof response.data !== typeof undefined &&
						typeof response.data.data !== typeof undefined
					) {
						if (
							typeof response.data.data.lastVouchers !==
								typeof undefined &&
							Array.isArray(response.data.data.lastVouchers) &&
							response.data.data.lastVouchers.length > 0
						) {
							response.data.data.lastVouchers.forEach(function (
								elVoucher: any,
							) {
								newCardsMostAccessProm.push({
									thumb: elVoucher.photo,
									title: elVoucher.title,
									description: elVoucher.description,
								});
							});
						}

						if (
							typeof response.data.data.vouchersQuantity !==
								typeof undefined &&
							response.data.data.vouchersQuantity >= 0
						) {
							setActiveDiscountsNumber(
								response.data.data.vouchersQuantity,
							);
						}
					}

					setCardsMostAccessProm(newCardsMostAccessProm);
				})
				.catch((error: any) => {
					console.log(error);
				});
		};

		handleGetMetrics();
	}, []);

	return (
		<BitPage>
			<BitHeaderFull label={'Dashboard Gestor'} icon={'fas fa-home'}>
				<div className="text-right flex flex-col items-end -my-3">
					<div className="w-max text-sm text-gray-600">
						Olá,&nbsp;
						<strong>{userName}</strong>
					</div>
					<div className="w-max">Seja bem-vindo novamente!</div>
				</div>
			</BitHeaderFull>

			<div className="footPrint w-full p-5 bg-white rounded">
				<div className="mt-20 mb-10 header-description-dashboard-lojista">
					<div className="grid grid-cols-2 gap-2">
						<div className="conteudo-textual">
							<h2>
								<b>Bom dia, </b>
								{userName}
							</h2>
							<p>
								<span>Você tem </span>
								<b>{`${activeDiscountsNumber} descontos `}</b>
								<span>ativos</span>
							</p>
						</div>
						<div className="thumb-hashtag-moca">
							<img src={mocaHashtag} alt="" />
						</div>
					</div>
				</div>

				<div className="bloco-promocoes-mais-acessadas">
					<h3 className="mb-8">
						Confira suas promoções mais acessadas
					</h3>
					<div className="grid grid-cols-3 gap-2">
						{cardsMostAccessProm.map(card => {
							return (
								<div className="bloco-promocao-mais-acessada">
									<div className="thumbnail-promocao-mais-acessada">
										<img src={card.thumb} alt="" />
									</div>
									<div className="bloco-descricao-promocao-mais-acessada">
										<div className="titulo-promocao-mais-acessada">
											<h4>{card.title}</h4>
										</div>
										<div className="descricao-promocao-mais-acessaada">
											<p>{card.description}</p>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</BitPage>
	);
};

export default DashboardLojista;
