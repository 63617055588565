import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';
import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

const NewGestor: React.FC = () => {
	type typeResponseAxios = {
		[key: string]: any;
	};

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointCreateUser = `${urlEndpoint}/users/store`;
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;

	const token = localStorage.getItem('token');

	const [maskTelephone, setmaskTelephone] = useState([
		'(',
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	]);
	const [nome, setNome] = useState({
		value: '',
		required: true,
	});
	const [cpf, setCpf] = useState({
		value: '',
		required: false,
	});
	const [dataNascimento, setDataNascimento] = useState({
		value: '',
		required: false,
	});
	const [email, setEmail] = useState({
		value: '',
		required: true,
	});
	const [telefone, setTelefone] = useState({
		value: '',
		required: true,
	});
	const [cargo, setCargo] = useState({
		value: '',
		required: true,
	});
	const [senha, setSenha] = useState({
		value: '',
		required: true,
	});
	const [senhaconfirmacao, setSenhaConfirmacao] = useState({
		value: '',
		required: true,
	});
	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const limpaCampos = () => {
		setNome({
			value: '',
			required: nome.required,
		});
		setCpf({
			value: '',
			required: cpf.required,
		});
		setDataNascimento({
			value: '',
			required: false,
		});
		setEmail({
			value: '',
			required: email.required,
		});
		setTelefone({
			value: '',
			required: telefone.required,
		});
		setCargo({
			value: '',
			required: cargo.required,
		});
		setSenha({
			value: '',
			required: senha.required,
		});
		setSenhaConfirmacao({
			value: '',
			required: senhaconfirmacao.required,
		});

		setShowRequiredBallon(false);
	};

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;

				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				allEmpreendimentos.map((empreendimento: any) => {
					newValueEmpreendimentos.push({
						value: empreendimento.id,
						label: empreendimento.name,
						selected: false,
					});
					return null;
				});

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);

		if (senha.value !== senhaconfirmacao.value) {
			toast.error('Erro ao cadastrar. As senhas devem ser iguais.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		} else if (
			senha.value === senhaconfirmacao.value &&
			senha.value.length < 6
		) {
			toast.error(
				'Erro ao cadastrar. A senha deve ter 6 ou mais digitos.',
				{
					position: 'bottom-right',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				},
			);
		} else {
			lockFields(currentFields);

			const entriesDevelopments = empreendimentos.value;
			const valueDevelopments: {
				[key: string]: string | number;
			}[] = [];

			entriesDevelopments.map(empreendimento => {
				if (empreendimento.selected) {
					valueDevelopments.push({
						id: empreendimento.value,
					});
				}

				return null;
			});

			axios
				.post(
					urlEndpointCreateUser,
					{
						type: 4,
						name: nome.value,
						cpf: cpf.value,
						birth_date: dataNascimento.value,
						email: email.value,
						phone: telefone.value,
						development: valueDevelopments,
						password: senha.value,
						password_confirmation: senhaconfirmacao.value,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
							'Content-Type': 'application/json',
						},
					},
				)
				.then(function (response: typeResponseAxios) {
					if (response.status === 201) {
						toast.success('Cadastro realizado com sucesso!', {
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						});

						limpaCampos();

						unlockFields(currentFields);
					}
				})
				.catch(function (error) {
					const errosServidor = error.response.data.error;
					toast.error(
						'Erro ao cadastrar. Verifique os campos obrigatórios e tente novamente.',
						{
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						},
					);

					if (
						error.response.data.error.email !== undefined &&
						error.response.data.error.email[0] ===
							'The email has already been taken.'
					) {
						toast.error(
							'O e-mail escolhido já está sendo utilizado.',
							{
								position: 'bottom-right',
								autoClose: 3000,
								hideProgressBar: true,
								closeOnClick: false,
								pauseOnHover: true,
								draggable: false,
								progress: undefined,
							},
						);
					}
					if (
						error.response.data.error.password !== undefined &&
						error.response.data.error.password[0] ===
							'The password must be an integer.'
					) {
						toast.error('Senha deve conter somente números.', {
							position: 'bottom-right',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						});
					}

					unlockFields(currentFields);
				});
		}
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Cadastro  / Gestor`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ADICIONAR GESTOR</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS DO GESTOR
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Nome completo"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNome({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nome.value}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="CPF"
							mask={[
								/\d/,
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'.',
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
							]}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setCpf({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={cpf.value}
							required={cpf.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Data de Nascimento"
							// mask={[
							// 	/\d/,
							// 	/\d/,
							// 	'/',
							// 	/\d/,
							// 	/\d/,
							// 	'/',
							// 	/\d/,
							// 	/\d/,
							// 	/\d/,
							// 	/\d/,
							// ]}
							type="date"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDataNascimento({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={dataNascimento.value}
							required={dataNascimento.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="E-mail"
							type="email"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setEmail({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={email.value}
							required={email.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Telefone"
							mask={maskTelephone}
							onKeyDown={(e: any) => {
								if (e.which !== 9) {
									if (isTelefoneSP(e.currentTarget.value)) {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									} else {
										setmaskTelephone([
											'(',
											/\d/,
											/\d/,
											')',
											' ',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]);
									}
								}
							}}
							type="maskedinput"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTelefone({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={telefone.value}
							required={telefone.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Senha"
							type="password"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setSenha({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={senha.value}
							required={senha.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Confirmar Senha"
							type="password"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setSenhaConfirmacao({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={senhaconfirmacao.value}
							required={senhaconfirmacao.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					EMPREENDIMENTOS
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mbfa-rotate-10">
						<BitInput
							label="Nome do empreendimento"
							type="select"
							multipleSelect={true}
							placeholder=""
							valuesSelect={empreendimentos.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValueEmpreendimentos: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValueEmpreendimentos.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setEmpreendimentos({
									value: newValueEmpreendimentos,
									required: empreendimentos.required,
								});
							}}
						/>
					</div>
					<div className="text-right mt-5">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/registrations/managers'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default NewGestor;
